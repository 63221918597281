import { Categories, Country, Logo, Type } from "@core/logic/common/common";

export class AdvertiserOffer {
  id?: string;
  intId?: number;
  isLaunched?: boolean;
  type?: Type;
  categories?: Categories[];
  countries?: Country[];
  createdAt?: Date;
  landing?: {
    cost: null;
    preCost: null;
  };
  logo?: Logo;
  previewLogo?: Logo;

  constructor (offer: any) {
    this.id = offer.id;
    this.intId = offer.intId;
    this.isLaunched = offer.isLaunched;
    this.type = offer.type;
    this.categories = offer.categories;
    this.countries = offer.countries;
    this.createdAt = offer.createdAt;
    this.landing = offer.landing;
    this.logo = offer.logo;
    this.previewLogo = offer.previewLogo;
  }
}
