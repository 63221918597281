










































































































































































































import ContactOutput from "@/components/Common/Output/ContactOutput.vue";
import { GET_ADVERTISER_PERSONAL_MANAGER, GET_WEBMASTER_PERSONAL_MANAGER, GET_WEBMASTER_PERSONAL_AGENT } from "@core/store/action-constants";
import { computed, onBeforeMount, onUnmounted, ref, watch } from "@vue/composition-api";
import { usePersonalManagers } from "@/stores/common/profile/personalManagersStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import { i18n } from "@core/plugins";
import { Manager, User } from "@core/store/types/common/User";
import { useToChangeManagerModal } from "@/stores/webmaster/profile/profileToChangeManagerModalStore";
import { defineProps, withDefaults } from "@vue/runtime-dom";
const __sfc_main = {};
__sfc_main.props = {
  showAgent: {
    key: "showAgent",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = usePersonalManagers(props.showAgent);
  const {
    data: personalManagers,
    role,
    subType,
    isAgent
  } = storeToRefs(store);
  onBeforeMount(async () => {
    await store.getPersonalManager();
  });
  const managerChangeStore = useToChangeManagerModal();
  const {
    isActive,
    input
  } = storeToRefs(managerChangeStore);
  const selectedManager = ref<Partial<User> & Manager>({});
  const clonePersonalManagers = ref<Manager[]>([]);
  const managers = computed(() => i18n.t(`webmaster.aside.personalManager.${props.showAgent ? "titleAgent" : "titleManager"}`));
  const isLoading = useWait(store, GET_WEBMASTER_PERSONAL_MANAGER) || useWait(store, GET_WEBMASTER_PERSONAL_AGENT) || useWait(store, GET_ADVERTISER_PERSONAL_MANAGER);
  function avatar(item: Manager) {
    return item?.avatar?.url;
  }
  function setSelectedManager(item: User | Manager, idx: number) {
    if (role.value === "webmaster") {
      clonePersonalManagers.value.splice(idx, 1);
      clonePersonalManagers.value = [...clonePersonalManagers.value, item];
      selectedManager.value = item;
    }
  }
  function openModalToChangeManager() {
    isActive.value = true;
    input.value.managerId = selectedManager.value.id!;
  }
  onUnmounted(() => {
    store.$reset();
  });
  watch(() => personalManagers.value, () => {
    clonePersonalManagers.value = [...personalManagers.value];
    selectedManager.value = personalManagers.value[personalManagers.value.length - 1];
  }, {
    deep: true
  });
  return {
    role,
    subType,
    isAgent,
    selectedManager,
    clonePersonalManagers,
    managers,
    isLoading,
    avatar,
    setSelectedManager,
    openModalToChangeManager
  };
};
__sfc_main.components = Object.assign({
  ContactOutput
}, __sfc_main.components);
export default __sfc_main;
