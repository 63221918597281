import { OfferEditWebmastersAvailableService } from "@core/services/admin/common/offer/OfferEditWebmastersAvailableService";
import { GET_AVAILABLE_WEBMASTERS_BY_ID, GET_OFFER_WEBMASTERS_AVAILABLE } from "@core/store/action-constants";
import {
  SET_OFFER_WEBMASTERS_AVAILABLE,
  UPDATE_OFFER_AVAILABLE_FILTERS,
  UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  OfferWebmasterAvailable, PartialOfferWebmastersAvailableFilters,
  PartialOfferWebmastersAvailableState
} from "@core/store/types/admin/offer/WebmastersAvailable.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialOfferWebmastersAvailableState => {
  return {
    data: {},
    filters: {
      loginOrIntId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialOfferWebmastersAvailableState = initialState;

const getters: GetterTree<PartialOfferWebmastersAvailableState, RootState> = {
  [GET_AVAILABLE_WEBMASTERS_BY_ID]: state => function (ids: string[]): OfferWebmasterAvailable[] {
    // @ts-ignore
    return ids.map(id => state.data?.items?.find((item: OfferWebmasterAvailable) => item.id === id));
  }
};

const mutations: MutationTree<PartialOfferWebmastersAvailableState> = {
  SET_EMPTY: (state: PartialOfferWebmastersAvailableState) => Object.assign(state, initialState()),

  [SET_OFFER_WEBMASTERS_AVAILABLE] (state: PartialOfferWebmastersAvailableState, webmasters: PaginationOutput<OfferWebmasterAvailable[]>): void {
    if (state.pagination.page !== 1 && state?.data?.items && webmasters) {
      state.data.items = Array.from(new Set([...state.data.items, ...webmasters.items as []]));
    } else {
      state.data = webmasters;
    }
  },

  [UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION] (state: PartialOfferWebmastersAvailableState): void {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_OFFER_AVAILABLE_FILTERS] (state: PartialOfferWebmastersAvailableState, filters: PartialOfferWebmastersAvailableFilters): void {
    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<PartialOfferWebmastersAvailableState, RootState> = {
  async [GET_OFFER_WEBMASTERS_AVAILABLE] ({ commit, state }, { offerId }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const availableWebmasters = await OfferEditWebmastersAvailableService.getAvailableWebmasters(limit, offset, state.filters, offerId);

    commit(SET_OFFER_WEBMASTERS_AVAILABLE, availableWebmasters);
    return availableWebmasters;
  }
};

export const offerEditWebmastersAvailableList: Module<PartialOfferWebmastersAvailableState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
