import { FlowService } from "@core/services/webmaster/FlowService";
import {
  CREATE_FLOW,
  GET_FLOW,
  GET_LANDINGS, GET_OFFER,
  SET_EMPTY,
  UPDATE_FLOW,
  UPDATE_OPTIONS
} from "@core/store/action-constants";
import { OfferLandings } from "@core/store/modules/webmaster/offers/modules/offerLandings";
import {
  SET_CHECKED_BUNCHES,
  SET_CHECKED_LANDINGS,
  SET_CHECKED_PRELANDINGS,
  SET_OPTION_DOMAIN,
  SET_NAME,
  SET_OFFER,
  UPDATE_ANALYTICS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { domainFilters } from "@core/store/modules/common/lists/domainList";
import { FlowEditAnalytic, FlowEditLandingFlow, Offer } from "@core/store/types/webmaster/flow";
import Vue from "vue";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { availableDomonetizations } from "@core/store/modules/webmaster/flows/modules/availableDomonetizations";
import { DomonetizationTypes } from "@core/store/types/webmaster/offer/domonetizationTypes";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

interface FlowEditState {
  name: string | null;
  flowId: string | null;
  offer: Offer | null;
  options: any;
  bunches: Array<never>;
  analytics: FlowEditAnalytic | null;
  checkedLandings: Array<FlowEditLandingFlow>;
  checkedTransits: Array<never>;
  checkedBunches: Array<never>;
}

function initOptions (): Record<string, unknown> {
  return {
    facebookPixel: null,
    facebookPixelFieldName: null,
    googleAnalytic: null,
    googleAnalyticFieldName: null,
    yandexMetrica: null,
    yandexMetricaFieldName: null
  };
}

function getPreparedCheckedBunches (checkedBunches: Array<any>): Array<any> {
  return checkedBunches.map(({ transit, landing }) => {
    return {
      transitId: transit ? transit.id : null,
      landingId: landing.id,
      options: {},
      ratio: 1
    };
  });
}

function prepareFlow ({ state, commit }: ActionContext<FlowEditState, RootState>): void {
  const options = initOptions();
  const analytics = state.analytics ?? {};
  
  if (!analytics.facebookPixel.value) {
    analytics.facebookPixel.value = null;
    options.facebookPixelCustomLeadEvent = null;
    options.facebookPixelCustomPageViewEvent = null;
  }

  if (state.offer?.vertical === VerticalsEnum.GAMBLING) {
    options.domonetization = null;
  }

  for (const [key, value] of Object.entries(analytics)) {
    Vue.set(options, value.GET ? `${ key }FieldName` : key, value.value);
  }

  commit("UPDATE_OPTIONS", options);
}

function prepareAnalytics (options: { [key: string]: string }): Record<string, unknown> {
  const initAnalytic: () => { GET: boolean; value: null | string } = () => {
    return {
      GET: false,
      value: null
    };
  };
  
  return Object
    .keys(options)
    .filter(key => !key.includes("FieldName"))
    .reduce((obj: Record<string, unknown>, field: string) => {
      const analytic = initAnalytic();
      const fieldName = field + "FieldName";
      
      if (options[fieldName]) {
        analytic.GET = true;
        analytic.value = options[fieldName];
      } else {
        analytic.GET = false;
        analytic.value = options[field];
      }
      
      return Object.assign(obj, { [field]: analytic });
    }, {});
}

const isEqualLandings = (landing1: any, landing2: any) => landing1?.id === landing2?.id;

const isEqualBunches = (bunch1: any, bunch2: any) =>
  isEqualLandings(bunch1.landing, bunch2.landing)
  && isEqualLandings(bunch1.transit, bunch2.transit);

function prepareLandings ({ state, commit, getters }: ActionContext<any, RootState> , bunches: Array<any>): void {
  const landings = state.landings.landings.items.filter((landing: any) => {
    return bunches.find(bunch => landing.id === bunch.landing?.id);
  });

  const preLandings = state.preLandings.landings.items.filter((preLanding: any) => {
    return bunches.find(bunch => preLanding.id === bunch.transit?.id);
  });

  commit(SET_CHECKED_LANDINGS, landings);
  commit(SET_CHECKED_PRELANDINGS, preLandings);

  setTimeout(() => {
    const computedBunches = getters.bunches.filter((stateBunch: any) => {
      return bunches.find(bunch => isEqualBunches(bunch, stateBunch));
    });

    commit(SET_CHECKED_BUNCHES, computedBunches);
  }, 0);
}

const initState = (): FlowEditState => {
  return {
    name: null,
    flowId: null,
    offer: {
      id: null,
      vertical: null
    },
    options: {
      facebookPixelCustomLeadEvent: "Lead",
      facebookPixelCustomPageViewEvent: "PageView",
      domonetizationType: DomonetizationTypes.WHITE,
      webmasterDomain: "adsssite.com"
    },
    bunches: [],
    analytics: {
      facebookPixel: {
        GET: false,
        value: null
      },
      googleAnalytic: {
        GET: false,
        value: null
      },
      yandexMetrica: {
        GET: false,
        value: null
      },
      tiktokPixel: {
        GET: false,
        value: null
      }
    },
    checkedLandings: [],
    checkedTransits: [],
    checkedBunches: []
  };
};

const state: FlowEditState = initState();

const mutations: MutationTree<FlowEditState> = {
  [SET_EMPTY]: state => Object.assign(state, initState()),

  SET_NAME (state, payload: string): void {
    state.name = payload;
  },

  SET_FLOW_ID (state, payload: string): void {
    state.flowId = payload;
  },

  SET_OFFER (state, payload: Offer): void {
    state.offer = payload;
  },

  UPDATE_ANALYTICS (state, payload: FlowEditState["analytics"]): void {
    state.analytics = { ...state.analytics, ...payload };
  },

  SET_OPTIONS (state, payload: FlowEditState): void {
    state.options = payload;
  },

  [UPDATE_OPTIONS] (state, payload: FlowEditState): void {
    state.options = { ...state.options, ...payload };
  },

  SET_BUNCHES (state, payload: FlowEditState["bunches"]): void {
    state.bunches = payload;
  },

  SET_CHECKED_LANDINGS (state, payload: FlowEditState["checkedLandings"]): void {
    state.checkedLandings = payload;
  },

  SET_CHECKED_PRELANDINGS (state, payload: any): void {
    state.checkedTransits = payload;
  },

  SET_CHECKED_BUNCHES (state, payload: any): void {
    state.checkedBunches = payload;
  }
};

const actions: ActionTree<FlowEditState, RootState> = {
  async [GET_FLOW] (vuex) {
    const { state, commit, dispatch } = vuex;
    const { data: { landingFlow: {
      name,
      offer,
      bunches,
      options: {
        webmasterDomain,
        domonetization,
        facebookPixelCustomLeadEvent,
        facebookPixelCustomPageViewEvent,
        domonetizationType,
        ...fields
      }
    } } } = await FlowService.getFlow(state.flowId as string);

    await Promise.all([
      dispatch(`landings/${ GET_LANDINGS }`, offer.id),
      dispatch(`preLandings/${ GET_LANDINGS }`, offer.id)
    ]);

    prepareLandings(vuex, bunches);
    commit(SET_NAME, name);
    commit(SET_OFFER, offer);
    commit(UPDATE_ANALYTICS, prepareAnalytics(fields));
    commit(UPDATE_OPTIONS, {
      webmasterDomain,
      domonetization,
      domonetizationType: domonetizationType ?? DomonetizationTypes.WHITE,
      facebookPixelCustomLeadEvent,
      facebookPixelCustomPageViewEvent
    });
  },

  async [GET_OFFER] ({ commit }, offerId) {
    const { data: { offer } } = await FlowService.getOffer(
      offerId
    );
    commit(SET_OFFER, offer);
  },

  async [CREATE_FLOW] (vuex) {
    const { state } = vuex;

    prepareFlow(vuex);

    await FlowService.createFlow({
      offerId: state.offer?.id as string,
      name: state.name as string,
      options: { ...state.options, domonetizationType: state.options.domonetization ? state.options.domonetizationType : null },
      bunches: getPreparedCheckedBunches(state.checkedBunches)
    });
  },

  async [UPDATE_FLOW] (vuex) {
    const { state } = vuex;

    prepareFlow(vuex);
    await FlowService.updateFlow(state.flowId as string, {
      name: state.name as string,
      options: { ...state.options, domonetizationType: state.options.domonetization ? state.options.domonetizationType : null },
      bunches: getPreparedCheckedBunches(state.checkedBunches)
    });
  },

  [UPDATE_ANALYTICS] ({ commit }, payload) {
    commit(UPDATE_ANALYTICS, payload);
  },
  
  [SET_OPTION_DOMAIN] ({ commit }, payload) {
    commit(UPDATE_OPTIONS, payload);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit(`landings/${ SET_EMPTY }`);
    commit(`preLandings/${ SET_EMPTY }`);
  }
};

export const getters: GetterTree<FlowEditState, RootState> = {
  bunches (state): Array<any> {
    const bunches = [];

    for (const landing of state.checkedLandings) {
      for (const transit of [null, ...state.checkedTransits]) {
        bunches.push({ transit, landing });
      }
    }

    return bunches;
  }
};

export const flowEdit: Module<FlowEditState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    landings: new OfferLandings("landing").toModule(),
    preLandings: new OfferLandings("transit").toModule(),
    domainFilters,
    availableDomonetizations
  }
};
