import offerAvailableAdvertisers from "@core/services/admin/common/offer/graphql/offerAvailableAdvertisers.graphql";
import availableAdvertisers from "@core/services/admin/common/offer/graphql/availableAdvertisers.graphql";
import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class OfferEditAdvertisersAvailableService extends Service {
  public static getOfferAdvertisersByOffer (
    offset = 0,
    limit = 25,
    id?: Nullable<string[]>,
    filters?: UserFiltersInput
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: availableAdvertisers,
          variables: {
            limit,
            offset,
            id,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getOfferAdvertisers (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: offerAvailableAdvertisers,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
