import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function FinanceTransactionsGenerator (
  limit: number,
  offset: number,
  filters: any
): ASTGeneratedOutput {
  // const exclude = [];
  //
  //
  const params = { };

  const gqlObject = {
    operation: "financeTransactions",

    fields: [
      {
        items: [
          {
            creator: [
              "login",
              "intId",
              "id"
            ]
          },
          { expenseItem: ["id", "from", "to", "name"] },
          {
            from: [
              "status",
              {
                user: [
                  "login",
                  "id",
                  "intId",
                  "type",
                  { "...on Webmaster": ["subType"] }
                ]
              },
              {
                "...on FinanceRecordOther": [
                  {
                    amount: [
                      "value",
                      "currency"
                    ]
                  }
                ]
              }
            ]
          },
          {
            to: [
              "status",
              {
                user: [
                  "login",
                  "id",
                  "intId",
                  { "...on Webmaster": ["subType"] },
                  "type"
                ]
              },
              {
                "...on FinanceRecordOther": [
                  {
                    amount: [
                      "value",
                      "currency"
                    ]
                  }
                ]
              }
            ]
          },
          "entityType",
          "id",
          "comment",
          "createdAt",
          "intId"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      filters: {
        type: "FinanceTransactionListCriteriaFilters",
        value: filters
      }
    }
  };

  return Generator.query(gqlObject, params);
}