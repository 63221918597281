






























import ConfirmationTooltip from "@/components/Common/Tooltip/ConfirmationTooltip.vue";
import { CommissionsService } from "@core/services/webmaster/commissions/CommissionsService";
import { SetCommissionToSubAccountInput } from "@core/store/types/admin/commissions/commissions";
import { useSubAccountOffers } from "@/stores/webmaster/commissions/commissionsSubStore";
import { useCommissions } from "@/stores/webmaster/commissions/comissionsStore";
import { CurrencySymbol } from "@core/helpers/currencySymbolMap";
import { actionWithToast } from "@/helpers/actionWithToast";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { computed, Ref, ref } from "@vue/composition-api";
import { Money } from "@core/store/types/common";
import { defineProps } from "@vue/runtime-dom";
import { ToastProgrammatic } from "buefy";
import i18n from "@core/plugins/i18n";
interface Props {
  item: any;
}
const __sfc_main = {};
__sfc_main.props = {
  item: {
    key: "item",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  // TODO: Во vue 3 переписать на defineModel
  const currentCommission = computed({
    get: () => props.item.currentCommission.value,
    set: value => updateCommissions(value)
  });
  const commissionsStore = useCommissions();
  const numberInput = ref(null);
  const localLoading = ref(false);
  const confirmation = ref(null) as Ref<{
    cancel: () => void;
  } | null>;
  const parentUniqueKey = props.item.parentUniqueKey;
  async function updateCommissions(value: number) {
    try {
      const {
        subAccount: {
          id: subAccountId
        },
        currentCommission: {
          currency
        },
        commissions
      } = props.item;
      if (value < 0) {
        ToastProgrammatic.open({
          message: i18n.t("webmaster.commissions.tooltip.commissions") as string,
          type: "is-danger"
        });
        // @ts-ignore
        numberInput.value?.confirmation.cancel();
      }
      const offerId = props.item.offer?.id;
      const handelCommissions = (item: Money) => item.currency === currency && item.value !== value ? {
        ...item,
        value
      } : item;
      const amount = Array.isArray(commissions) && commissions[0].value === null || offerId ? [{
        value,
        currency
      }] : commissions.map(handelCommissions);
      const input = {
        subAccountId,
        amount,
        offerId
      };
      const uniqueKey = props.item.uniqueKey;
      localLoading.value = true;
      await actionWithToast(setCommissionToSubAccount(input, uniqueKey, parentUniqueKey), "webmaster.commissions.message.commission");
    } catch {
      confirmation.value?.cancel();
    } finally {
      localLoading.value = false;
    }
  }
  async function setCommissionToSubAccount(input: SetCommissionToSubAccountInput, uniqueKey: string, parentUniqueKey: string) {
    const {
      commission
    } = await CommissionsService.setCommissionToSubAccount(input);
    // @ts-ignore
    const store = useStateUpdater(input.offerId ? useSubAccountOffers(parentUniqueKey) : commissionsStore);
    const item = {
      uniqueKey,
      commission: input.offerId ? input.amount[0] : commission.filter((el: Money) => props.item.currencies.includes(el.currency))
    };
    store.LOCAL_UPDATE({
      items: [item],
      param: "uniqueKey"
    });
  }
  return {
    CurrencySymbol,
    currentCommission,
    numberInput,
    localLoading,
    confirmation
  };
};
__sfc_main.components = Object.assign({
  ConfirmationTooltip
}, __sfc_main.components);
export default __sfc_main;
