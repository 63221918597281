import { Module } from "@core/store/logic/Module";
import { auth } from "@core/store/modules/common/auth";
import { adblock } from "@core/store/modules/common/adblock";
import { balance } from "@core/store/modules/common/balance-widget";
import { dictionaries } from "@core/store/modules/common/dictionaries";
import { error } from "@core/store/modules/common/error";
import { loading } from "@core/store/modules/common/loading";
import { locale } from "@core/store/modules/common/locale";
import { password } from "@core/store/modules/common/password";
import { success } from "@core/store/modules/common/success";
import { trafficTypes } from "@core/store/modules/common/trafficTypes";
import { uploads } from "@core/store/modules/common/uploads/uploads";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import { ExpandFullscreen } from "@core/store/modules/common/chart/ExpandFullscreen";
import { globalSourceFilters } from "@core/store/modules/common/globalSource";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";
import { userAvatarModal } from "@core/store/modules/common/userAvatarModal";
import { webmasterDataById } from "@core/store/modules/common/webmasterDataById";
import { languagesList } from "@core/store/modules/common/lists/languagesList";
import { countriesList } from "@core/store/modules/common/lists/countriesList";
import { verticals } from "./modules/common/verticals";
import { telegram } from "@core/store/modules/common/telegram";
import { ManagersFilters } from "@core/store/modules/common/lists/managersFilters";
import { AdvertisersFilters } from "@core/store/modules/common/lists/advertisersList";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";
import { filtersConfirm } from "@core/store/modules/common/filtersConfirm";
import { snackbarActions } from "@core/store/modules/common/snackbarActions";
import { userLogout } from "@core/store/modules/common/userLogout";
import { LandingFilters } from "@core/store/modules/common/lists/landingList";
import { designersList } from "@core/store/modules/admin/lists/designersList";
import { userContextStorage } from "@core/store/modules/common/userContextStorage";
import { reasonsList } from "@core/store/modules/common/lists/reasonsList";
import {
  detailingDepositHistoryModal
} from "@core/store/modules/common/statistic/detailing/detailingDepositHistoryModal";
import { editLinkModal } from "@core/store/modules/common/editLinkModal";
import { trafficAnalysis } from "@core/store/modules/common/statistic/trafficAnalysis/trafficAnalysis";
import { utmCampaignFilters } from "@core/store/modules/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/utmTermFilters";
import { CitiesFilters } from "@core/store/modules/common/lists/citiesList";
import { competition } from "@core/store/modules/common/competition";
import { AnalyticTargetAudience } from "@core/store/modules/admin/analytic/modules/analyticTargetAudience";
import { theme } from "@core/store/modules/common/theme";
import { categoriesFilters } from "@core/store/modules/common/categories/categoriesFilters";
import { externalWebmasterFilters } from "@core/store/modules/admin/statistic/modules/common/externalWebmasterFilters";
import { IntegrationCRMList } from "@core/store/modules/common/lists/integrationCRMList";
import { CRMList } from "@core/store/modules/common/lists/CRMList";
import { ExpenseItems } from "@core/store/modules/admin/finances/modules/financeHistory/expenseItems";

export class CoreStore<S, R> extends Module<S, R> {
  constructor () {
    super();

    this.modules({
      // * List
      utmCampaignFilters,
      utmContentFilters,
      utmSourceFilters,
      utmMediumFilters,
      advertisersList,
      utmTermFilters,
      webmastersList,
      languagesList,
      countriesList,
      designersList,
      trafficTypes,
      currencyList,
      reasonsList,
      adminsList,
      offersList,
      verticals,
      CRMFilters: new CRMList(),
      expenseItems: new ExpenseItems(),
      offersFilters: new OfferFilters(),
      citiesFilters: new CitiesFilters(),
      landingFilters: new LandingFilters(),
      managersFilters: new ManagersFilters(),
      webmastersFilters: new WebmastersFilters(),
      advertisersFilters: new AdvertisersFilters(),
      integrationCRMFilters: new IntegrationCRMList(),
      externalWebmastersList: externalWebmasterFilters,
      // * Statistic
      trafficAnalysis,
      detailingDepositHistoryModal,
      // * Other
      auth,
      userLogout,
      theme,
      editLinkModal,
      competition,
      telegram,
      adblock,
      locale,
      success,
      categories: categoriesFilters,
      globalSourceFilters,
      error,
      loading,
      balance,
      dictionaries,
      password,
      uploads,
      userAvatarModal,
      filtersConfirm,
      webmasterDataById,
      snackbarActions,
      analyticTargetAudience: new AnalyticTargetAudience().toModule(),
      expandFullscreen: new ExpandFullscreen().toModule(),
      userContextStorage
    });
  }
}