import OfferRequestMinimalPayout from "@core/services/admin/offers/graphql/OfferRequestMinimalPayout.graphql";
import Service from "@core/services/common/Service";
import { BampsAndCapsState } from "@core/store/types/admin/offers/bampsAndCaps";
import { Money } from "@core/store/types/common";
import createPayoutRequestQuery from "@core/services/admin/offers/graphql/createPayoutRequest.generator";
import editPayoutRequestGenerator from "@core/services/admin/offers/graphql/editPayoutRequest.generator";
import deletePayoutRequestQuery from "@core/services/admin/offers/graphql/deletePayoutRequest.graphql";
import approvePayoutRequestGenerator from "@core/services/admin/offers/graphql/approvePayoutRequest.generator";
import rejectPayoutRequestGenerator from "@core/services/admin/offers/graphql/rejectPayoutRequest";
import actualizePayoutRequestGenerator from "@core/services/admin/offers/graphql/actualizePayoutRequest";
import {
  ApproveCapacityRequestInput,
  CreatePayoutRequestInput,
  WebmasterPayoutRequest,
  EditPayoutRequestInput
} from "@core/store/types/admin/offers/capacity";

export class PayoutRequestService extends Service {
  public static async getMinimalPayout (
    filters: BampsAndCapsState["filters"]
  ): Promise<Money> {
    try {
      const { data: { offerParameterRequests: { items } } } = await this.api.get("", {
        params: {
          query: OfferRequestMinimalPayout,
          variables: {
            filters
          }
        }
      });
      return items[0]?.minimalPayout;
    } catch (e) {
      throw e;
    }
  }
  
  public static async createPayoutRequest (
    input: CreatePayoutRequestInput,
    isShowCommission: boolean
  ): Promise<WebmasterPayoutRequest[]> {
    try {
      const data = createPayoutRequestQuery(
        input,
        isShowCommission
      );
      const { data: { createPayoutRequest } } = await this.api.post("", data);
      return createPayoutRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async editPayoutRequest (
    input: EditPayoutRequestInput,
    id: string,
    isShowCommission: boolean
  ): Promise<WebmasterPayoutRequest> {
    try {
      const data = editPayoutRequestGenerator(
        input,
        id,
        isShowCommission
      );
      const { data: { editPayoutRequest } } = await this.api.post("", data);
      return editPayoutRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deletePayoutRequest (
    id: string
  ): Promise<WebmasterPayoutRequest> {
    try {
      return this.api.post("", {
        query: deletePayoutRequestQuery,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async approvePayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string,
    isShowCommission: boolean
  ): Promise<WebmasterPayoutRequest> {
    try {
      const data = approvePayoutRequestGenerator(
        input,
        id,
        isShowCommission
      );
      const { data: { approvePayoutRequest } } = await this.api.post("", data);

      return approvePayoutRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async rejectPayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): Promise<WebmasterPayoutRequest> {
    try {
      const { data: { rejectPayoutRequest } } = await this.api.get("", {
        params: rejectPayoutRequestGenerator(input, id)
      });
      return rejectPayoutRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async actualizePayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): Promise<WebmasterPayoutRequest> {
    try {
      const { data: { actualizePayoutRequest } } = await this.api.get("", {
        params: actualizePayoutRequestGenerator(input, id)
      });
      return actualizePayoutRequest;
    }
    catch (e) {
      throw e;
    }
  }
}
