
























































































































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import Select from "@/components/Common/Select/Select.vue";
import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { storeToRefs } from "pinia";
import TrafficRequestHistoryTable from "@/components/Admin/Traffic/Traffic/modal/Create/TrafficRequestHistoryTable.vue";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { i18n } from "@core/plugins";
import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
import { useWait } from "@/stores/common/waitStore";
import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
import { onBeforeUnmount } from "@vue/composition-api";
import TrafficRequestContextTemplate from "@/components/Admin/Traffic/Traffic/modal/Create/TrafficRequestContextTemplate.vue";
import TrafficRequestRecommendationTemplate from "./TrafficRequestRecommendationTemplate.vue";
import TrafficRequestNewTermsTemplate from "./TrafficRequestNewTermsTemplate.vue";
// import TrafficRequestGrowthTermsTemplate from "./TrafficRequestGrowthTermsTemplate.vue";
import iconChart from "@/assets/Offer/gridicons_stats-down.svg";
import InlineSvg from "vue-inline-svg";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { actionWithToast } from "@/helpers/actionWithToast";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useFiltersConfirm } from "@/stores/common/filtersConfirmStore";
import { DialogProgrammatic as Dialog } from "buefy";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const {
    isActive,
    isEdit,
    type,
    webmasterId,
    offerId,
    externalWebmaster,
    hasShowHistory
  } = storeToRefs(store);
  const storeAnalyse = useTrafficRequestAnalyseModal();
  const confirmStore = useFiltersConfirm();
  const {
    confirmFilters
  } = storeToRefs(confirmStore);
  const isLoading = useWait(store, store.CREATE_TRAFFIC_REQUEST);
  const isLoadingEdit = useWait(store, store.EDIT_TRAFFIC_REQUEST);
  if (!isEdit.value) {
    type.value = confirmFilters.value.type[0];
  }
  function getTrafficTypes() {
    const items = Object.values(TrafficRequestTypeEnum).map(value => ({
      label: i18n.t(`admin.traffic.modal.body.type.${value}`),
      value
    }));
    return {
      items,
      count: items.length
    };
  }
  function closeModalConfirm() {
    Dialog.confirm({
      title: i18n.t("common.entity.modal.confirmationToCancel.title"),
      message: i18n.t("common.entity.modal.confirmationToCancel.message"),
      confirmText: i18n.t("common.entity.modal.confirmationToCancel.confirmText"),
      cancelText: i18n.t("common.entity.modal.confirmationToCancel.cancelText"),
      type: "is-danger is-light",
      onConfirm: () => {
        isActive.value = false;
      }
    } as any);
  }
  async function createTrafficRequest() {
    await actionWithToast(store.createTrafficRequest(), "admin.traffic.modal.body.errors");
    isActive.value = false;
  }
  async function editTrafficRequest() {
    await actionWithToast(store.editTrafficRequest(), "admin.traffic.modal.body.errors");
    isActive.value = false;
  }
  onBeforeUnmount(() => {
    store.$reset();
  });
  return {
    TrafficRequestTypeEnum,
    iconChart,
    VerticalsEnum,
    store,
    isActive,
    isEdit,
    type,
    webmasterId,
    offerId,
    externalWebmaster,
    hasShowHistory,
    storeAnalyse,
    isLoading,
    isLoadingEdit,
    getTrafficTypes,
    closeModalConfirm,
    createTrafficRequest,
    editTrafficRequest
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  Select,
  InlineSvg,
  AccessControlFilter,
  OffersSelect,
  TrafficRequestContextTemplate,
  TrafficRequestRecommendationTemplate,
  TrafficRequestNewTermsTemplate,
  TrafficRequestHistoryTable
}, __sfc_main.components);
export default __sfc_main;
