<template>
    <div>
        <ModalBox
            :is-active="isModalActive"
            :is-loading="isLoading.getWebmaster"
            @close="close"
            @submit="saveEditedProfile">
            <template #header>
                <h5 class="title is-5 mb-1">
                    {{ $t(`${ nameSpace }.header.title`) }}
                    <template v-if="login">
                        {{ login }}
                    </template>
                </h5>
                <template v-if="canBeEdit">
                    <span
                        class="is-clickable"
                        @click="canBeEdit = false">
                        <b-icon
                            icon="angle-left"
                            size="is-small">
                        </b-icon>
                        {{ $t(`common.entity.webmasterCard.fullCard.header.goBack`) }}
                    </span>
                </template>
            </template>

            <div class="card-profile">
                <header class="card-profile_header">
                    <img
                        :src="avatar(data)"
                        class="card-profile_header-img"
                        alt="Avatar">
                    <div>
                        <h6 class="card-profile_header-name">
                            {{ login }}
                        </h6>
                        <div
                            v-if="!canBeEdit && isApprovedWithPermissions"
                            class="is-align-items-center is-clickable mt-2"
                            @click.prevent="editProfile">
                            <b-icon
                                icon="setting"
                                class="mr-2"
                                size="is-small"
                                pack="uil">
                            </b-icon>
                            <span style="font-size: 14px">
                                {{ $t(`${ nameSpace }.header.editProfile`) }}
                            </span>
                        </div>
                    </div>
                </header>
            </div>

            <template
                #footer
                v-if="canBeEdit">
                <div class="flex is-justify-content-space-between is-flex-wrap-wrap">
                    <b-button
                        class="is-info is-light"
                        @click="openPasswordModal">
                        {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                    </b-button>
                    <b-button
                        :loading="isLoading.editProfile"
                        type="is-info"
                        native-type="submit">
                        {{ $t(`common.buttons.save`) }}
                    </b-button>
                </div>
            </template>

            <WebmastersProfileEditCard
                v-if="canBeEdit"
                :data="data">
            </WebmastersProfileEditCard>
            <WebmastersProfileViewCard
                v-else
                :data="data">
            </WebmastersProfileViewCard>
        </ModalBox>

        <PasswordModal
            :is-active.sync="isPasswordModalActive"
            :is-loading="isLoading.editPassword"
            :password.sync="password"
            @submit="changePassword"
            @closed="emptyPasswordState">
        </PasswordModal>
    </div>
</template>

<script>
  import WebmastersProfileViewCard from "@/components/Common/Admin/modal/WebmastersProfileViewCard";
  import WebmastersProfileEditCard from "@/components/Common/Admin/modal/WebmastersProfileEditCard";
  import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import {
    GET_WEBMASTER_PROFILE_BY_ID,
    UPDATE_WEBMASTER_BY_ID,
    UPDATE_MODAL_ACTIVE,
    EDIT_WEBMASTER,
    UPDATE_OPTIONS,
    EDIT_PASSWORD,
    SET_EMPTY,
    SET_FORM
  } from "@core/store/action-constants";
  import { formatEmptyString } from "@core/filters";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { WebmasterMiniCard } from "@core/store/logic/admin/WebmasterMiniCard";
  import { hasPermissions } from "@core/mixins/permissions";

  export default {
    name: "WebmastersProfileViewModal",

    components: {
      WebmastersProfileViewCard,
      WebmastersProfileEditCard,
      PasswordModal,
      ModalBox
    },

    computed: {
      ...mapState({
        hasEditOwn: ({ auth }) => hasPermissions(["WEBMASTERS.EDIT.OWN"], auth.userPermissions),
        hasEditAll: ({ auth }) => hasPermissions(["WEBMASTERS.EDIT.ALL"], auth.userPermissions)
      }),

      ...mapState("webmasterDataById/webmasterProfileViewModal", {
        localDataParams: state => state.localDataParams,
        isModalActive: state => state.isModalActive,
        data: state => state.data
      }),

      ...mapFields("webmasterDataById/webmasterProfileViewModal", {
        fields: ["canBeEdit"],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      ...mapFields("admin/webmasterPassword", {
        fields: ["isPasswordModalActive", "password"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapGetters(["user"]),

      isApprovedWithPermissions () {
        if (this.data) {
          const { user, data: { admins } } = this;
          const hasCurrentAdmin = admins.some(item => item.id === user.id);
          return hasCurrentAdmin && this.hasEditOwn || this.hasEditAll;
        } return false;
      },

      nameSpace () {
        return "common.entity.webmasterCard.fullCard";
      },

      login () {
        return this.data?.login;
      },

      isTeam () {
        return this.data.detail.isTeam;
      },

      isLoading () {
        const namespace = "webmasterDataById/webmasterProfileViewModal";
        return {
          getWebmaster: this.$wait(`${ namespace }/${ GET_WEBMASTER_PROFILE_BY_ID }`),
          editProfile: this.$wait(`${ namespace }/${ EDIT_WEBMASTER }`),
          editPassword: this.$wait(`admin/webmasterPassword/${ EDIT_PASSWORD }`)
        };
      }
    },

    methods: {
      formatEmptyString,

      ...mapActions("webmasterDataById/webmasterProfileViewModal", {
        GET_WEBMASTER_PROFILE_BY_ID,
        UPDATE_MODAL_ACTIVE,
        EDIT_WEBMASTER,
        UPDATE_OPTIONS,
        SET_EMPTY,
        SET_FORM
      }),

      ...mapActions("admin/webmasterPassword", {
        EDIT_PASSWORD,
        UPDATE_MODAL
      }),

      ...mapMutations("admin/webmasterPassword", {
        emptyPasswordState: SET_EMPTY
      }),

      avatar (item) {
        return item?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      openPasswordModal () {
        this.UPDATE_MODAL({ isPasswordModalActive: true });
      },

      close () {
        this.UPDATE_MODAL_ACTIVE(false);
        setTimeout(() => {
          this.SET_EMPTY();
        }, 200);
      },

      async changePassword () {
        await actionWithToast(this.EDIT_PASSWORD("webmaster"), "admin.users.common.modal.password.messages");
        this.emptyPasswordState();
      },

      editProfile () {
        this.SET_FORM();
        this.canBeEdit = true;
      },

      UpdateMiniCard () {
        const { id } = this.data;
        this.$store.commit(
          `webmasterDataById/${ UPDATE_WEBMASTER_BY_ID }`,
          { [id]: new WebmasterMiniCard(this.data) }
        );
      },

      UpdateDataByNamespace () {
        if (this.localDataParams) {
          const { namespace, target } = this.localDataParams;
          this.$store.dispatch(`${ namespace }/LOCAL_UPDATE`, { items: this.data, target });
        }
      },

      async saveEditedProfile () {
        await actionWithToast(this.EDIT_WEBMASTER(), "common.profile.edit");
        this.canBeEdit = false;

        this.UpdateDataByNamespace();
        this.UpdateMiniCard();
      }
    },

    watch: {
      isPasswordModalActive (value) {
        this.UPDATE_MODAL_ACTIVE(!value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        * {
            text-align: left !important;
        }

        .modal-card-foot {
            padding: 20px 30px 30px;
        }

        .pointer-events-none {
            pointer-events: none;
        }

        .animation-content {
            width: 100%;
            max-width: 700px !important;

            .modal-card {
                width: 100%;
                max-width: none;
            }
        }

        .card-profile {
            &_body {
                &-wrapper {
                    flex-wrap: wrap;
                }

                &-team {
                    display: flex;
                    align-items: center;

                    width: 100%;
                    margin: .5rem 0;
                    padding: 16px 20px;
                    background: $info;
                    border-radius: 8px;

                    &_title, &_item {
                        color: #FFFFFF;
                    }

                    &_title {
                        font-size: 0.813rem;
                    }

                    &_item {
                        font-weight: 600;
                        font-size: .850rem;
                        white-space: normal;
                        letter-spacing: .8px;
                    }
                }

                &-item {
                    width: 100%;
                    margin-top: .9rem;

                    &_title {
                        font-size: 0.875rem;
                        color: #8D8A8A;
                    }

                    &_content {
                        font-weight: 600;
                        font-size: .813rem;
                        white-space: normal;
                        overflow: hidden;
                    }
                }

                &_item-info {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    margin: .5rem .625rem 0 0;
                    padding: .438rem .75rem ;

                    font-size: .87rem;
                    color: #FFFFFF;
                    background: $info;
                    border-radius: 4px;
                }

                @media screen and (min-width: 600px) {
                    &-wrapper {
                        display: flex;
                        justify-content: space-between;
                    }

                    &-team, &-item {
                        width: 48.5%;
                    }
                }
            }
        }

        .card-title {
            margin-top: 1rem;

            font-size: 1.25rem;
            font-weight: 600;
        }
    }

    .card-profile {
        &_header {
            $border-color: #E1E1E1;

            display: flex;
            align-items: center;

            &-img {
                $size: 6.5rem;

                width: $size;
                height: $size;

                margin-right: 1.25rem;
                border-radius: 50%;
                border: 1px solid  $border-color;
            }

            &-name {
                font-size: 1.063rem;
                font-weight: 600;
                color: #212121;
            }
        }
    }

    .card-title {
        margin-top: 1rem;

        font-size: 1.25rem;
        font-weight: 600;
    }
</style>
