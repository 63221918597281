import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";
import { WebmastersRatesFilters } from "@core/store/types/admin/users/webmasters/Detail";

export default function summaryRateByWebmasterGenerator (
  limit: number,
  offset: number,
  webmasterId: string[],
  filters: WebmastersRatesFilters,
  sort: string,
  order: string
): ASTGeneratedOutput {
  const money = ["value", "currency"];
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("items/individualRateReward/value", "items/individualRateReward/currency");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "summaryRateByWebmaster",

    fields: [
      {
        items: [
          {
            offer: [
              "intId",
              {
                detail: [
                  "name"
                ]
              },
              {
                countries: [
                  "name",
                  "alpha2",
                  "alpha3",
                  "id"
                ]
              }
            ]
          },
          { advertiserMinimalCommission: money },
          { offerAdvertiserWriteOff: money },
          { offerReward: money },
          { individualRateReward: money },
          { advertiserIndividualRateWriteOff: money }
        ]
      },
      "count"
    ],

    variables: {
      filters: {
        type: "SummaryRateByWebmasterCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      },
      sort: {
        type: "SummaryRateByWebmasterCriteriaSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }
  };

  return Generator.query(gqlObject, params);
}
