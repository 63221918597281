import { CapacityAnalyseFilters } from "@core/store/types/admin/offers/capacity";
import { datepickerFormat } from "@core/helpers/datepicker";

export class AgentAnalyseFiltersDTO {
  public dateStart: string;
  public dateEnd: string;
  public webmasterId: Nullable<string>;
    
  constructor (filters: CapacityAnalyseFilters) {
    const { dateStart, dateEnd } = datepickerFormat(filters.datepicker, "date");
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.webmasterId = filters.webmasterId;
  }
}