<template>
    <span class="nowrap has-text-left user-wrapper">
        <MiniCardTooltip
            v-if="activeDropdown && webmasterId"
            ref="webmasterOutput"
            :is-loading="isLoading"
            :current-item="item"
            @onShow="onShow"
            @imageClick="webmasterProfileModalActive">
            <template
                v-if="role === 'admin'"
                #image>
                <template v-if="!avatar && !webmasterSubType">
                    <!--{{ formatEmptyString(null) }}-->
                    <img
                        alt="webmaster"
                        :class="{ 'avatar-external': avatar , 'user-img': !avatar }"
                        src="@/assets/icons/webmaster.svg">
                </template>
                <!-- * Не будет выводиться если нет id -->
                <img
                    v-else-if="!isCallCentersManager"
                    alt="webmaster"
                    :class="{ 'avatar-external': avatar , 'user-img': !avatar }"
                    :src="webmasterIcon">
            </template>

            <template #content>
                <div class="card-profile">
                    <div class="card-profile_header">
                        <div class="is-align-items-center">
                            <img
                                :src="avatarMiniCard"
                                alt=""
                                class="card-profile_header_avatar">
                            <span class="card-profile_header_name">
                                {{ item.intId }}
                            </span>
                        </div>
                        <span
                            v-if="isShowCommissions"
                            class="show-more ml-3"
                            @click="webmasterProfileModalActive">
                            {{ $t("common.buttons.more") }}
                        </span>
                    </div>

                    <div class="divider"></div>

                    <div class="card-profile_body">
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.webmasterCard.miniCard.managers') }:` }}
                            </span>
                            <div>
                                <template v-if="hasManagers">
                                    <div
                                        :key="idx"
                                        v-for="(item, idx) in managers">
                                        <UserOutput :user="item"></UserOutput>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ formatEmptyString(null) }}
                                </template>
                            </div>
                        </div>
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.webmasterCard.miniCard.country') }:` }}
                            </span>
                            <Countries
                                :items="item.country ? [item.country] : []"
                                compact
                                tooltip-position="is-top">
                            </Countries>
                        </div>
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.webmasterCard.miniCard.team') }:` }}
                            </span>
                            <template v-if="item.isTeam">
                                {{ item.teamName }}
                            </template>
                            <template v-else>
                                {{ formatEmptyString(null) }}
                            </template>
                        </div>
                        <Permissions
                            :permissions="['WEBMASTERS.LIST.ALL', 'WEBMASTERS.LIST.OWN']"
                            :validators="webmasterOwnValidators(item)">
                            <div class="card-profile_body_content">
                                <span class="card-profile_body_content_name">
                                    {{ `${ $t('common.entity.contacts') }:` }}
                                </span>
                                <UserContacts :contacts="item.contacts">
                                </UserContacts>
                            </div>
                        </Permissions>
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name is-align-items-flex-start">
                                {{ `${ $t('common.entity.webmasterCard.miniCard.registeredAt') }:` }}
                            </span>
                            <div class="flex is-flex-direction-column">
                                <span>
                                    {{ momentWithEmpty( item.registeredAt, "DD.MM.YYYY") }}
                                </span>

                                <span
                                    class="has-text-grey has-text-centered"
                                    style="font-size: 12px">
                                    {{ momentFrom(item.registeredAt) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </MiniCardTooltip>

        <CanCopy
            :value="canCopy"
            :prevent-copy="!copy">
            <UserOutput
                :user="webmaster"
                :login="($saas.visibleLoginWebmaster && role === 'admin') || login"
                :int-id="($saas.visibleLoginWebmaster && role === 'admin') || intId">
            </UserOutput>
        </CanCopy>

        <!--WebmastersProfileViewModal-->
    </span>
</template>

<script>
  import MiniCardTooltip from "@/components/Common/Tooltip/MiniCardTooltip.vue";
  import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
  import Countries from "@/components/Common/Lists/Countries.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import Permissions from "@/components/Common/Permissions.vue";
  import { momentFrom } from "@core/filters";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { formatEmptyString } from "@core/filters";
  import { momentWithEmpty } from "@core/flowMethods";
  import {
    GET_WEBMASTER_PROFILE_BY_ID,
    UPDATE_LOCAL_DATA_PARAMS,
    UPDATE_MODAL_ACTIVE,
    GET_WEBMASTER_BY_ID
  } from "@core/store/action-constants";
  import { hasPermissions } from "@core/mixins/permissions";
  import UserContacts from "@/components/Common/Output/UserContacts.vue";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "WebmasterOutput",
    components: {
      UserContacts,
      MiniCardTooltip,
      Permissions,
      UserOutput,
      Countries,
      CanCopy
    },
    props: {
      position: {
        type: String,
        default: "is-bottom"
      },
      webmaster: {
        type: Object,
        default: null
      },
      /**
       * Параметры для изменения локальных данных в необходимых местах.
       * @typedef {Object} localDataParams
       * @property {string} namespace Путь до необходимого модуля в store
       * @property {string} target Свойство по которому будут изменены данные в state
       */
      /** @return {localDataParams} */
      localDataParams: {
        type: Object,
        default: null
      },
      activeDropdown: {
        type: Boolean,
        default: true
      },
      copy: {
        type: Boolean,
        default: true
      },
      login: {
        type: Boolean,
        default: false
      },
      intId: {
        type: Boolean,
        default: true
      },
      avatar: {
        type: Boolean,
        default: false
      }
    },

    created () {
      this.UPDATE_LOCAL_DATA_PARAMS(this.localDataParams);
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapState("webmasterDataById", {
        data: ({ data }) => data
      }),

      ...mapState({
        isCallCentersManager: ({ auth }) => hasPermissions(["TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"], auth.userPermissions)
      }),

      ...mapGetters(["role"]),

      item () {
        const { webmaster, data } = this;
        return data?.[webmaster?.id];
      },

      managers () {
        return this.item?.admins;
      },

      avatarMiniCard () {
        return this.item?.avatarUrl ?? require("@/assets/Avatar.svg");
      },

      hasManagers () {
        return this.item?.admins?.length > 0;
      },

      isLoading () {
        return this.$wait(`webmasterDataById/${ GET_WEBMASTER_BY_ID }`);
      },

      webmasterId () {
        return this.webmaster?.id;
      },

      webmasterSubType () {
        return this.webmaster?.subType;
      },

      avatarExternal () {
        return this.webmaster?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      webmasterIcon () {
        return this.avatar ? this.avatarExternal : require(`@/assets/icons/${ this.webmasterSubType?.toLowerCase() }.svg`);
      },

      canCopy () {
        const web = this.webmaster;
        if (web && web.login && web.intId && !this.intId) {
          return `${ web.login } ${ web.intId }`;
        } else if (web && web.intId && this.intId) {
          return web.intId;
        } return null;
      }
    },

    methods: {
      momentFrom,
      momentWithEmpty,
      formatEmptyString,

      ...mapActions("webmasterDataById", {
        GET_WEBMASTER_BY_ID
      }),

      ...mapActions("webmasterDataById/webmasterProfileViewModal", {
        GET_WEBMASTER_PROFILE_BY_ID,
        UPDATE_LOCAL_DATA_PARAMS,
        UPDATE_MODAL_ACTIVE
      }),

      webmasterOwnValidators (webmaster) {
        const func = () => webmaster.isBindedToCurrentAdmin;

        return {
          "WEBMASTERS.LIST.OWN": func
        };
      },

      webmasterProfileModalActive () {
        if (this.isShowCommissions) {
          this.$refs.webmasterOutput.$refs.userOutput.$refs.tippy.tip.hide();

          this.$emit("openProfile");
          this.GET_WEBMASTER_PROFILE_BY_ID(this.webmasterId);
          // Component: WebmastersProfileViewModal
          this.UPDATE_MODAL_ACTIVE(true);
        }
      },

      onShow () {
        const { webmasterId, activeDropdown, item, isLoading } = this;

        if (!item && webmasterId && !isLoading && activeDropdown) {
          this.GET_WEBMASTER_BY_ID(webmasterId);
        }
      }
    }
  };
</script>

<style lang="scss">
    .tippy-arrow {
        &::after {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>
    @import "~@/scss/user-mini-card";

    .show-more {
        color: $info;
        cursor: pointer;
    }

    .user-img {
      width: 16px;
      height: 16px;
    }
</style>
