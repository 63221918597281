import { momentToDate } from "@core/helpers/momentToDate";
import momentjs from "moment";
import moment from "moment";
import i18n from "@core/plugins/i18n";

export const dateTypes = {
  date: "YYYY-MM-DD",
  dateTime: "YYYY-MM-DDTHH:mm:ss"
};

// eslint-disable-next-line default-param-last
export const datepicker = (subtract?: datepickerSubtract, dateType: DateType = "dateTime", unitOfTime?: UnitOfTime): OutputDate => {
  const { unit, amount, unitEnd, amountEnd, units, amounts } = subtract ?? {};
  const { startOf = "day", endOf = "day" } = unitOfTime ?? {};

  const datepicker = {
    dateStart: momentToDate(momentjs().subtract(amounts || amount, units || unit).startOf(startOf)),
    dateEnd: momentToDate(momentjs().subtract(amounts || amountEnd, units || unitEnd).endOf(endOf))
  };

  return datepickerFormat(datepicker, dateType);
};

export function uniqDatepicker (keys: string[], subtract?: datepickerSubtract, dateType: DateType = "dateTime") {
  const { dateStart, dateEnd } = datepicker(subtract, dateType);
  const [ startKey, endKey ] = keys;

  return {
    [startKey]: dateStart,
    [endKey]: dateEnd
  };
}

const offset = localStorage.getItem("timeZone") || "Z";

export const datepickerFormat = (date: InputDate = {}, dateType: DateType = "dateTime", utc= false): OutputDate => {

  const { dateStart, dateEnd } = date;
  const momentFormat = utc ? momentUTC : moment;

  return {
    dateStart: momentFormat(dateStart).format(`${ dateTypes[dateType] }${ offset }`),
    dateEnd: momentFormat(dateEnd).format(`${ dateTypes[dateType] }${ offset }`)
  };
};

export function momentUTC (value?: Date | string, locale = i18n.locale) {
  return momentjs
    .utc(value)
    .utcOffset(offset)
    .locale(locale);
}

interface InputDate {
  dateStart?: string | Date,
  dateEnd?: string | Date
}

interface OutputDate {
  dateStart: string,
  dateEnd: string
}

interface datepickerSubtract {
  unit?: Base,
  amount?: number,
  unitEnd?: Base,
  amountEnd?: number,
  units?: Base,
  amounts?: number
}

export type DateType = "dateTime" | "date"

export type UnitOfTime = { startOf?: Base | _isoWeek, endOf?: Base | _isoWeek }

type _isoWeek = "isoWeek" | "isoWeeks" | "W";

type Base = (
  "year" | "years" | "y" |
  "month" | "months" | "M" |
  "week" | "weeks" | "w" |
  "day" | "days" | "d" |
  "hour" | "hours" | "h" |
  "minute" | "minutes" | "m" |
  "second" | "seconds" | "s" |
  "millisecond" | "milliseconds" | "ms"
);