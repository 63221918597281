import { CommissionsState } from "@core/store/types/admin/commissions/commissions";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";
import { CommissionsService } from "@core/services/webmaster/commissions/CommissionsService";
import { useSubAccountOffers } from "@/stores/webmaster/commissions/commissionsSubStore";
import { pagination } from "@core/helpers/pagination";
import { defineStore } from "pinia";

export const useCommissions = defineStore("webmaster/commissions", {
  state: (): CommissionsState => ({
    data: null,
    filters: {
      groups: [CommissionsGroupsEnum.SUB_ACCOUNT],
      loginOrIntId: null,
      email: null,
      offerOfSubAccount: null
    }
  }),

  getters: {
    groups: ({ filters: { groups } }) => Array.isArray(groups) ? groups : [groups],

    onlyFilters: ({ filters: { groups, ...filters } }) => filters,

    childGroup: () => (uniqueKey: string) => {
      const store = useSubAccountOffers(uniqueKey);
      return store.groups?.[0];
    }
  },

  actions: {
    async GET_COMMISSIONS () {
      const { limit, offset } = pagination();
      const { groups, ...filters } = this.filters;

      this.data = await CommissionsService.getCommissions(limit, offset, filters, groups);
    },

    async CHANGE_AGENT_TYPE (type: string) {
      await CommissionsService.changeAgentType(type);
    },

    UPDATE_FILTERS (payload: CommissionsState["filters"]) {
      this.filters = { ...this.filters, ...payload };
    }
  }
});