<template functional>
    <span
        v-if="props.offer && props.offer.name && props.offer.intId"
        class="output">
        <span
            class="is-truncate is-inline-block is-centered name mr-1"
            :style="`max-width: ${ props.maxWidth }`">{{ $options.methods.formatEmptyString(props.offer.name) }}</span>
        [{{ $options.methods.formatEmptyString(props.offer.intId) }}]
        <template v-if="props.offer.offerType && props.isIconEnabled && $options.iconType(props.offer.offerType)">
            <LTooltip
                type="is-dark"
                position="is-top"
                :label="parent.$t(`common.entity.offer.offerTypes.${ props.offer.offerType }`)">
                <b-icon
                    :icon="$options.iconType(props.offer.offerType)"
                    pack="fas"
                    size="is-small"></b-icon>
            </LTooltip>
        </template>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";

  export default {
    name: "OfferOutput",
    props: {
      offer: {
        type: Object,
        default: null
      },
      isIconEnabled: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: String,
        default: ""
      }
    },
    iconType (offerType) {
      const types = {
        "PUBLIC": "globe",
        "PRIVATE": "lock",
        "HIDDEN": "user-lock"
      };

      return types[offerType];
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
//.output {
//    display: flex;
//    align-items: center;
//    .name {
//        overflow: hidden;
//        text-overflow: ellipsis;
//        max-width: 240px;
//        display: inline-block;
//    }
//}
.is-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.is-centered {
  vertical-align: middle;
}
</style>
