<template>
    <div>
        <TablePlaceholder :data="data">
            <template>
                <CustomizableTable
                    ref="customizableTable"
                    :css="styling.table"
                    :data-manager="dataManager"
                    :fields="fields"
                    :sort-order="sortOrder"
                    :is-loading="isLoading">
                    <template #[`individualRate.advertiserIndividualRateWriteOff`]="{ data: { rowData: { individualRate } } }">
                        <span class="nowrap">
                            {{ formatAdvertiserCost(advertiserCost(
                                individualRate.advertiserIndividualRateWriteOff,
                                individualRate.offerAdvertiserWriteOff
                            )) }}
                        </span>
                    </template>
                    <template #[`individualRate.commission`]="{ data: { rowData: { individualRate } } }">
                        <span class="nowrap">{{
                            formatCommission(
                                individualRate.individualRateReward,
                                individualRate.offerReward,
                                advertiserCost(
                                    individualRate.advertiserIndividualRateWriteOff,
                                    individualRate.offerAdvertiserWriteOff
                                )
                            )
                        }}</span>
                    </template>
                    <template #tableHeader>
                        <StatisticTableHeader :styling="headerStyling">
                            <template #default="{ fieldName: name }">
                                <div class="table-header">
                                    <label>
                                        {{ $t(`admin.users.webmasters.modal.details.body.individualRates.labels.${ name }`) }}
                                    </label>
                                </div>
                            </template>
                        </StatisticTableHeader>
                        <VuetableRowHeader></VuetableRowHeader>
                    </template>
                    <template #pagination>
                        <LPagination
                            page-input
                            limit="small"
                            :count="data.count"
                            :page.sync="page"
                            :per-page.sync="perPage">
                        </LPagination>
                    </template>
                </CustomizableTable>
            </template>
        </TablePlaceholder>
    </div>
</template>

<script>
  import CustomizableTable from "@/components/Common/Table/CustomizableTable";
  import { formatCurrency, defaultFormatter } from "@core/filters";
  import {
    GET_STATISTIC,
    GET_WEBMASTER_RATES,
    UPDATE_WEBMASTER_RATES_PAGINATION
  } from "@core/store/action-constants";
  import { UPDATE_SORTINGS } from "@core/store/mutation-constants";
  import _orderBy from "lodash/orderBy";
  import { mapActions, mapMutations, mapState } from "vuex";
  import StatisticTableHeader from "@/components/Common/Table/StatisticTableHeader";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import StatisticTableViewCountry from "@/components/Common/Table/View/StatisticTableViewCountry";
  import StatisticTableViewGroup from "@/components/Common/Table/View/StatisticTableViewGroup";
  import StatisticTableViewNumber from "@/components/Common/Table/View/StatisticTableViewNumber";
  import StatisticTableViewOfferOutput from "@/components/Common/Table/View/StatisticTableViewOfferOutput";
  import VuetableMixin from "@/components/Common/Table/VuetableMixin";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import LPagination from "@/components/Common/LPagination";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "IndividualRateTable",
    mixins: [VuetableMixin],
    components: {
      StatisticTableHeader,
      TablePlaceholder,
      CustomizableTable,
      LPagination
    },

    data () {
      return {
        sortOrder: [],
        headers: [
          {
            name: "webmasterRate",
            colspan: 4
          }
        ]
      };
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapFields("admin/users/webmasters/detail/rates", {
        fields: ["page", "perPage"],
        base: "pagination",
        action: UPDATE_WEBMASTER_RATES_PAGINATION
      }),

      ...mapState("admin/users/webmasters/detail/rates", {
        data: state => state.data
      }),

      isLoading () {
        return this.$wait(`admin/users/webmasters/detail/rates/${ GET_WEBMASTER_RATES }`);
      },

      fields () {
        if (!this.data) return [];
        const common = {
          name: StatisticTableViewGroup,
          titleClass: "has-text-left has-text-grey-light",
          label: "individualRate.groups",
          dataClass: "has-text-left"
        };

        return [
          {
            ...common,
            name: StatisticTableViewCountry,
            headerName: "individualRate",
            label: "individualRate.countries",
            width: "15%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.country")
          },
          {
            ...common,
            name: StatisticTableViewOfferOutput,
            headerName: "individualRate",
            label: "individualRate.offer",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.offer")
          },
          {
            ...common,
            name: StatisticTableViewNumber,
            headerName: "individualRate",
            label: "individualRate.offerReward",
            sortField: "offerRate",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.offerRate"),
            formatter: value => formatCurrency(value?.value, value?.currency)
          },
          this.isShowCommissions && {
            ...common,
            name: StatisticTableViewNumber,
            headerName: "individualRate",
            label: "individualRate.individualRateReward",
            sortField: "individualRate",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.webmasterRate"),
            formatter: value => formatCurrency(value?.value, value?.currency)
          },
          {
            ...common,
            dataClass: "has-text-right",
            headerName: "individualRate",
            name: "individualRate.advertiserIndividualRateWriteOff",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.advertiserPayment")
          },
          this.isShowCommissions && {
            ...common,
            headerName: "individualRate",
            name: "individualRate.commission",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.commission")
          },
          {
            ...common,
            headerName: "individualRate",
            name: "individualRate.advertiserMinimalCommission",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.advertiserCommission"),
            formatter: value => formatCurrency(value?.value, value?.currency)
          }
        ].filter(Boolean);
      }
    },
    methods: {
      ...mapActions("admin/statistic", [
        GET_STATISTIC
      ]),

      ...mapMutations("admin/users/webmasters/detail/rates", {
        UPDATE_SORTINGS
      }),

      formatCurrency,
      defaultFormatter,

      dataManager (sortOrder) {
        const items = this.data.items.map(item => {
          const {
            individualRateReward, offerReward, offerAdvertiserWriteOff,
            advertiserMinimalCommission, advertiserIndividualRateWriteOff,
            offer: { detail:{ name }, intId, countries }
          } = item;

          return {
            individualRate: {
              offer: { name, intId },
              countries,
              offerReward,
              individualRateReward,
              offerAdvertiserWriteOff,
              advertiserMinimalCommission,
              advertiserIndividualRateWriteOff,
              // FixMe: Проверка нуля - это ограничение бэка, когда этот вопрос решится нужно будет что то предпринять
              commission: individualRateReward === 0 ? null : offerAdvertiserWriteOff - individualRateReward
            }
          };
        });

        return _orderBy(items, sortOrder.map(el => el.sortField), sortOrder.map(el => el.direction));
      },

      headerStyling () {
        return {
          "color": "black",
          "background-color": "#F7F7F7"
        };
      },

      advertiserCost (advertiserIndividualRateWriteOff, offerAdvertiserWriteOff){
        return advertiserIndividualRateWriteOff ?? offerAdvertiserWriteOff;
      },

      formatAdvertiserCost (advertisers) {

        advertisers = Array.isArray(advertisers) ? advertisers: [advertisers];
        switch (advertisers.length) {
          case 0:
            return "-";
          case 1:
            return this.formatCurrency(advertisers[0].value, advertisers[0].currency);
          default: {
            const writeOffs = advertisers.map(advertiser => parseFloat(advertiser?.value));
            const currency = advertisers.map(advertiser => advertiser?.currency)[0]?.toUpperCase();
            const min = Math.min.apply(Math, writeOffs);
            const max = Math.max.apply(Math, writeOffs);

            if (min === max) {
              return this.formatCurrency(min, currency);
            }
            return `${ this.formatCurrency(min, currency) } – ${ this.formatCurrency(max, currency) }`;
          }
        }
      },

      formatCommission (individualRate, commonRate, advertisers) {
        advertisers = Array.isArray(advertisers) ? advertisers: [advertisers];

        const rate = individualRate ? individualRate?.value : commonRate?.value;
        switch (advertisers.length) {
          case 0:
            return "-";

          case 1:
            return this.formatCurrency(advertisers[0].value - rate, advertisers[0].currency);

          default: {
            const writeOffs = advertisers.map(advertiser => parseFloat(advertiser?.value));
            const currency = advertisers.map(advertiser => advertiser?.currency)[0]?.toUpperCase();
            const min = Math.min.apply(Math, writeOffs);
            const max = Math.max.apply(Math, writeOffs);

            if (min === max) {
              return this.formatCurrency(min - rate, currency);
            }

            return `${ this.formatCurrency(min - rate, currency) } –
             ${ this.formatCurrency(max - rate, currency) }`;
          }
        }
      },

      updateSorting () {
        this.UPDATE_SORTINGS(this.sortOrder[0]);
        this.page = 1;
        this.$emit("sort");
      }
    },

    watch: {
      sortOrder () {
        this.updateSorting();
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .sort-icon {
            line-height: 1.5;
        }

        .v-popover {
            & + span {
                .sort-icon {
                    line-height: 2.4;
                }
            }
        }

        ul {
            li {
                &:not(:first-child) {
                    padding-top: 0.2em;
                }

                div {
                    .flag-icon {
                        margin-right: 0.4em;
                    }
                }
            }
        }

        table {
            thead, tbody {
                background-color: white;
            }

            thead {
                top: 0;

                tr {
                    &:first-child {
                        th {
                            padding: 0;

                            > div {
                                display: flex;
                                padding: 20px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
        .card {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
</style>
