import individualPayouts from "@core/services/admin/offer/edit/graphql/individualPayouts.graphql";
import getOfferWebmastersReward from "@core/services/admin/offer/edit/graphql/getOfferWebmastersReward.graphql";
import Service from "@core/services/common/Service";
import {
  IndividualRateStatePaginationOutput, OfferAdvertiserIndividualRate,
  OfferAdvertiserIndividualRateState, PartialOfferAdvertiserIndividualRateFilters,
  RequestIndividualRate
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";
import { AxiosPromise } from "axios";
import addIndividualPayouts from "./graphql/addIndividualPayouts.graphql";
import makeIndividualPayout from "./graphql/makeIndividualPayout.graphql";
import minimalAllowedPayout from "./graphql/minimalAllowedPayout.graphql";
import { RequestRates } from "@core/store/types/admin/offer/webmasters/OfferExternalWebmastersModalState";
import { formatIndividualPayouts } from "@core/services/admin/offer/edit/dataMapper/offerMapper";

export class OfferEditAdvertisersIndividualRateService extends Service {
  public static async getOfferAdvertisersIndividualRates (
    limit: number,
    offset: number,
    offerId: string,
    filters: OfferAdvertiserIndividualRateState["filters"]
  ): Promise<IndividualRateStatePaginationOutput<OfferAdvertiserIndividualRate[]>> {
    try {
      const { data: { individualPayouts: data } } = await this.api.get("", {
        params: {
          query: individualPayouts,
          variables: {
            limit,
            offset,
            filters,
            offerId
          }
        }
      });

      return { items: formatIndividualPayouts(data.items), count: data.count };
    } catch (e) {
      throw e;
    }
  }

  public static minimalAllowedPayout (offerId: string, webmasterId: string): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: minimalAllowedPayout,
          variables: {
            offerId,
            webmasterId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getOfferWebmastersReward (
    limit: number,
    offset: number,
    offerId: string,
    filters: PartialOfferAdvertiserIndividualRateFilters
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: getOfferWebmastersReward,
          variables: {
            limit,
            offset,
            offerId,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async addIndividualPayouts (
    offerId: string,
    payouts: RequestRates[]
  ): Promise<OfferAdvertiserIndividualRate[]> {
    try {
      const { data: { addIndividualPayouts: data } } = await this.api.post("", {
        query: addIndividualPayouts,
        variables: { offerId, input: { payouts } }
      });

      return formatIndividualPayouts(data);
    } catch (e) {
      throw e;
    }
  }

  public static async makeIndividualPayout (
    offerId: string,
    webmasterId: string,
    input: RequestIndividualRate
  ): Promise<OfferAdvertiserIndividualRate> {
    try {
      const { data: { makeIndividualPayout: data } } = await this.api.post("", {
        query: makeIndividualPayout,
        variables: { offerId, webmasterId, input }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
