


































import LLoading from "@/components/Common/LLoading.vue";
import { defineProps } from "@vue/runtime-dom";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
interface Data extends PaginationOutput<any[]> {
  count?: number;
  aggregates?: {
    [key: string]: unknown;
  };
}
interface Props {
  data: Data | null;
}
const __sfc_main = {};
__sfc_main.props = {
  data: {
    key: "data",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  LLoading
}, __sfc_main.components);
export default __sfc_main;
