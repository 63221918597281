function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{ref:"table",attrs:{"data":items,"loading":_vm.isLoading,"query-pagination":"","count":count,"virtual-scroll":"","backend-pagination":"","hoverable":"","hide-mobile":"","detailed":"","detail-key":"uniqueKey"},on:{"details-close":_vm.reopenDetails,"update":_vm.update},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var ref_row = ref.row;
var uniqueKey = ref_row.uniqueKey;
var rest = objectWithoutProperties( ref_row, ["uniqueKey"] );
var rowData = rest;
var index = ref.index;
return [_c('CommissionsTableDetail',{ref:"detailTable",attrs:{"unique-key":uniqueKey,"group":_vm.currentGroups[uniqueKey],"parent-group":_vm.groups[0],"initial-groups":_vm.groups,"initial-data":[rowData],"parent-filters":_vm.onlyFilters,"parent-count-columns":_vm.countColumns,"parent-total-rows":_vm.totalRows,"parent-columns":_vm.tableLabels,"parent-row-index":index,"row-data":rowData}})]}}],null,true)},[_c('TableColumn',{attrs:{"label":_vm.$t(("common.groupsList." + (_vm.isSubAccount? 'id' : _vm.group)))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var toggleDetails = ref.toggleDetails;
return [_c('GroupsSelectDropdown',{key:_vm.isLoading,attrs:{"groups":_vm.allGroups,"namespace":"common.groupsList"},on:{"change":function($event){toggleDetails(row), _vm.changeCurrentGroup($event, row.uniqueKey, true)}}},[_c('CommissionsGroups',{attrs:{"data":row[_vm.isSubAccount ? 'subAccount' : _vm.group],"group":_vm.groups[0]}})],1)]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("common.table.email"),"centered":"","header-class":"is-nowrap pr-2","width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var email = ref.row.subAccount.contact.email;
return [_vm._v(" "+_vm._s(email)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("webmaster.commissions.table.label.commonCommission"),"centered":"","width":"300px","header-class":"is-nowrap pr-2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var commission = ref_row.commission;
var currencies = ref_row.currencies;
var rest = objectWithoutProperties( ref_row, ["commission", "currencies"] );
var fields = rest;
return [_c('div',_vm._l((commission || currencies),function(item,idx){return _c('UpdateCommissionDropdown',{key:idx,attrs:{"item":Object.assign({}, {currentCommission: Array.isArray(commission) ? item : { value: null, currency: item },
                            commissions: Array.isArray(commission) ? commission : currencies.map(function (el) { return ({ value: null, currency: el }); }),
                            currencies: currencies},
                            fields)}})}),1)]}}],null,true)}),_c('TableColumn',[_c('div')])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }