import {
  IndividualRateStatePaginationOutput,
  OfferAdvertiserIndividualRate,
  PartialOfferAdvertiserIndividualRateState
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";
import {
  OfferEditAdvertisersIndividualRateService
} from "@core/services/admin/offer/edit/OfferEditAdvertisersIndividualRateService";
import { useOfferWebmastersRates } from "@/stores/admin/offer/webmastersRatesStore";
import { useOfferEdit } from "@/stores/admin/offer/offerEditStore";
import { capitalizeFirstLetter } from "@core/filters";
import { defineStore } from "pinia";
import Vue from "vue";
import { useStateUpdater } from "@/helpers/useStateUpdater";

export function useOfferAdvertisersIndividualRates (param = "external") {
  return defineStore(`admin/offer/edit/offerAdvertisersIndividualRates/${ param }`, {
    state: (): PartialOfferAdvertiserIndividualRateState => ({
      individualRates: {},
      filters: {
        hasExternalWebmaster: param !== "internal",
        loginOrIntId: null,
        webmasterId: null,
        manager: null
      },
      pagination: {
        page: 1,
        perPage: 25
      }
    }),

    getters: {
      selectWebmaster: ({ individualRates }) => function (webmasterId: string): OfferAdvertiserIndividualRate | undefined {
        return individualRates?.items?.find((user: OfferAdvertiserIndividualRate) => user.webmaster.id === webmasterId);
      }
    },

    actions: {
      UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES (individualRates: IndividualRateStatePaginationOutput<OfferAdvertiserIndividualRate[]>) {
        if (this.individualRates) {
          this.individualRates = {
            count: individualRates.count,
            items: this.individualRates?.items?.concat(individualRates.items as OfferAdvertiserIndividualRate[])
          };
        }
      },

      UPDATE_LOADING ({ index, params, value }: { index: number, params: any, value: any }): void {
        if (this.individualRates?.items) {
          for (const key in params) {
            if (params.hasOwnProperty(key)) {
              const prop = `is${ capitalizeFirstLetter(key) }Loading`;
              Vue.set(this.individualRates.items[index], prop, value);
            }
          }
        }
      },

      UPDATE_OFFER_WEBMASTERS_REWARD (payload: any): void {
        if (this.individualRates?.items) {
          const webmastersIds = this.individualRates.items.map(item => item.id);

          this.individualRates.items = this.individualRates.items.map((item, index) => {
            item.minimalAllowedPayout = payload[webmastersIds[index]] || null;
            return item;
          });
        }
      },

      _UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE ({ payload, index }: { index: number, payload: any }): void {
        if (this.individualRates?.items) {
          Vue.set(this.individualRates.items, index, { ...this.individualRates.items[index], ...payload });
        }
      },

      async GET_OFFER_ADVERTISER_INDIVIDUAL_RATES (): Promise<void> {
        try {
          const store = useOfferEdit();

          const { pagination, filters } = this;
          const limit = pagination.perPage;
          const offset = (pagination.page - 1) * limit;

          if (store.offerId) {
            const data =
                await OfferEditAdvertisersIndividualRateService.getOfferAdvertisersIndividualRates(
                  limit,
                  offset,
                  store.offerId,
                  filters
                );

            if (offset === 0) {
              this.individualRates = data;
            } else {
              this.UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES(data);
            }
          }
        } catch (e) {
          throw e;
        }
      },

      async GET_OFFER_WEBMASTERS_REWARD (): Promise<void> {
        const store = useOfferEdit();

        try {
          if (store.offerId) {
            const limit = this.pagination.perPage * this.pagination.page;

            const { data: { individualPayouts: { items } } } =
                await OfferEditAdvertisersIndividualRateService.getOfferWebmastersReward(
                  limit,
                  0,
                  store.offerId,
                  this.filters
                );
            const webmastersReward: { [key: string]: unknown } = {};
            items.forEach((item: OfferAdvertiserIndividualRate) => {
              webmastersReward[item.id] = item.minimalAllowedPayout;
            });

            this.UPDATE_OFFER_WEBMASTERS_REWARD(webmastersReward);
          }
        } catch (e) {
          throw e;
        }
      },

      async UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE ({ params, index }: { params: any, index: number }): Promise<void> {
        const store = useOfferEdit();
        const storeOfferWebmastersInternalRates = useOfferWebmastersRates("internal");
        const storeOfferWebmastersExternalRates = useOfferWebmastersRates("external");

        this.UPDATE_LOADING({ index, params, value: true });
        try {
          if (this.individualRates?.items && store.offerId) {
            const { webmaster, externalWebmasterId } = this.individualRates.items[index];
            const newRate = {
              amount: params.payout || this.individualRates.items[index].payout,
              externalWebmasterId
            };
            const item = await OfferEditAdvertisersIndividualRateService.makeIndividualPayout(store.offerId, webmaster.id, newRate);

            storeOfferWebmastersInternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
            storeOfferWebmastersExternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();

            this._UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE({
              payload: { id: item.id, params },
              index
            });
            this.UPDATE_LOADING({ index, params, value: false });
          }
        } catch (e) {
          this.UPDATE_LOADING({ index, params, value: false });
          throw e;
        }
      },

      async DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE (id: string, webmasterId: string, externalWebmasterId: string): Promise<void> {
        const store = useOfferEdit();

        const storeOfferWebmastersInternalRates = useOfferWebmastersRates("internal");
        const storeOfferWebmastersExternalRates = useOfferWebmastersRates("external");

        try {
          if (store.offerId && webmasterId) {
            await OfferEditAdvertisersIndividualRateService
              .makeIndividualPayout(store.offerId, webmasterId, { externalWebmasterId });

            // @ts-ignore
            useStateUpdater(useOfferAdvertisersIndividualRates(param))
              .LOCAL_DELETE({ items: [{ id }], target: "individualRates" });
            
            storeOfferWebmastersInternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
            storeOfferWebmastersExternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
          }
        } catch (e) {
          throw e;
        }
      }
    }
  })();
}
