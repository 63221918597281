














import crm from "@/assets/AsideMenu/crm.svg";
import dashboard from "@/assets/AsideMenu/dashboard.svg";
import logIntegrations from "@/assets/AsideMenu/log-integrations.svg";
import domains from "@/assets/AsideMenu/domains.svg";
import efficiency from "@/assets/AsideMenu/efficiency.svg";
import finance from "@/assets/AsideMenu/finance.svg";
import integrations from "@/assets/AsideMenu/integrations.svg";
import offers from "@/assets/AsideMenu/offers.svg";
import paymentSystems from "@/assets/AsideMenu/payment-systems.svg";
import statistics from "@/assets/AsideMenu/statistics.svg";
import users from "@/assets/AsideMenu/users.svg";
import audit from "@/assets/AsideMenu/audit.svg";
import support from "@/assets/AsideMenu/support.svg";
import roles from "@/assets/AsideMenu/roles.svg";
import competition from "@/assets/AsideMenu/competition.svg";
import complaints from "@/assets/AsideMenu/complaints.svg";
import capacity from "@/assets/AsideMenu/capacity.svg";
import changeManager from "@/assets/AsideMenu/changeManager.svg";
import statusUp from "@/assets/AsideMenu/status-up.svg";
import AsideMenuList from "@/components/Common/AsideMenuList.vue";
import WebmastersProfileViewModal from "@/components/Common/Admin/modal/WebmastersProfileViewModal.vue";
import { useBadges } from "@/stores/common/badgesStore";
import { storeToRefs } from "pinia";
import { defineProps, defineEmits } from "@vue/runtime-dom";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { computed, onUnmounted, ref } from "@vue/composition-api";
import { i18n } from "@core/plugins";
import { useVerticals } from "@/stores/common/auth/verticalsStore";
import storeInstance from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  isReduce: {
    type: Boolean,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const badgesStore = useBadges();
  const {
    webmasters: badgesWebmasters,
    outTransactions: badgesCosts,
    offerParameterRequests: badgesLogRequests,
    changeManagerRequests: badgesManagerRequests
  } = storeToRefs(badgesStore);
  const verticalsStore = useVerticals();
  const {
    isVisibleByAllVerticals,
    nutra
  } = storeToRefs(verticalsStore);
  const permissionsStore = usePermissions();
  const hasVisibleWebmasters = computed(() => permissionsStore.hasPermissions(["WEBMASTERS.LIST.ALL", "WEBMASTERS.LIST.OWN", "WEBMASTERS.LIST.UNBIND"]));
  const hasVisibleLog = computed(() => permissionsStore.hasPermissions(["OFFER_PARAMETER_LOG.LIST.ALL", "OFFER_PARAMETER_LOG.LIST.OWN", "OFFER_PARAMETER_LOG.LIST.GUARANTEE"]));
  const hasVisibleOutTransactions = computed(() => permissionsStore.hasPermissions(["OUT_TRANSACTIONS.LIST.ALL", "OUT_TRANSACTIONS.LIST.OWN"]));
  const hasVisibleManagersRequests = computed(() => permissionsStore.hasPermissions(["WEBMASTERS.CHANGE_MANAGER_REQUEST.LIST.ALL"]));
  const locale = computed(() => i18n.locale);
  const menuList = ref(null);
  const menu = computed(() => [{
    to: {
      name: "dashboard"
    },
    label: i18n.t("admin.menu.dashboard"),
    icon: dashboard,
    permissions: ["SUPER.PERMISSION", "DASHBOARD.DEBIT.GET", "DASHBOARD.PREPAID_EXPENSE.GET", "DASHBOARD.COMMISSION.GET", "DASHBOARD.CASH_FLOW.GET", "DASHBOARD.SUMMARY_OFFER.LIST", "DASHBOARD.SUMMARY_ADVERTISER.LIST", "DASHBOARD.SUMMARY_WEBMASTER.LIST.ALL", "DASHBOARD.SUMMARY_WEBMASTER.LIST.OWN"]
  }, {
    to: {
      name: "admin:dashboardAffiliate"
    },
    label: i18n.t("admin.menu.dashboardAffiliate"),
    icon: dashboard,
    permissions: ["DASHBOARD.AFFILIATE_MANAGER.WIDGET.LIST"],
    isHidden: !nutra.value
  }, {
    label: i18n.t("admin.menu.statistics.statistics"),
    icon: statistics,
    permissions: ["STATISTICS.VIEW", "STATISTICS.TRAFFIC.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN", "STATISTICS.WEBMASTER_REGISTRATION.VIEW", "STATISTICS.AUDIENCE.VIEW", "LEAD.LIST.ALL", "LEAD.LIST.WEBMASTERS.OWN", "TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT", "STATISTICS.LANDING.OPERATIONS.VIEW", "STATISTICS.DOMONETIZATION", "SUPER.PERMISSION"],
    children: [{
      to: {
        name: "admin:statistics:statistics"
      },
      label: i18n.t("admin.menu.statistics.statistics"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN"]
    }, {
      to: {
        name: "admin:statistics:detailing"
      },
      label: i18n.t("admin.menu.statistics.detailing"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["LEAD.LIST.ALL", "LEAD.LIST.WEBMASTERS.OWN"]
    }, {
      to: {
        name: "admin:statistics:analytic"
      },
      label: i18n.t("admin.menu.statistics.analytic"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN"]
    }, {
      to: {
        name: "admin:statistics:analyticsTargetAudience"
      },
      label: i18n.t("admin.menu.statistics.analyticsTargetAudience"),
      permissions: ["STATISTICS.AUDIENCE.VIEW"],
      isHidden: !nutra.value
    }, {
      to: {
        name: "admin:statistics:trafficAnalysis"
      },
      label: i18n.t("admin.menu.statistics.trafficAnalysis"),
      permissions: ["STATISTICS.TRAFFIC.VIEW", "TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"],
      isHidden: !nutra.value
    }, {
      to: {
        name: "admin:statistics:landingsHistory"
      },
      label: i18n.t("admin.menu.statistics.landingsHistory"),
      permissions: ["STATISTICS.LANDING.OPERATIONS.VIEW"],
      isHidden: !nutra.value
    }, {
      to: {
        name: "admin:statistics:domonetization"
      },
      label: i18n.t("admin.menu.statistics.domonetization"),
      permissions: ["STATISTICS.DOMONETIZATION"],
      isHidden: !nutra.value
    }, {
      to: {
        name: "admin:statistics:registrations"
      },
      label: i18n.t("admin.menu.statistics.registrations"),
      permissions: ["STATISTICS.WEBMASTER_REGISTRATION.VIEW"],
      isHidden: !nutra.value
    }].filter(Boolean)
  }, {
    label: i18n.t("admin.menu.users.users"),
    hasBadges: hasVisibleWebmasters.value ? !!badgesWebmasters?.value : null,
    icon: users,
    permissions: ["WEBMASTERS.LIST.ALL", "WEBMASTERS.LIST.OWN", "WEBMASTERS.LIST.UNBIND", "ADVERTISERS.LIST.ALL", "ADVERTISERS.LIST.UNBIND", "ADVERTISERS.LIST.OWN", "ADMIN.LIST"],
    children: [{
      to: {
        name: "admin:users:webmasters"
      },
      label: i18n.t("admin.menu.users.webmasters"),
      badgesCount: badgesWebmasters?.value,
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["WEBMASTERS.LIST.ALL", "WEBMASTERS.LIST.OWN", "WEBMASTERS.LIST.UNBIND"]
    }, {
      to: {
        name: "admin:users:advertisers"
      },
      label: i18n.t("admin.menu.users.advertisers"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["ADVERTISERS.LIST.ALL", "ADVERTISERS.LIST.OWN", "ADVERTISERS.LIST.UNBIND"]
    }, {
      to: {
        name: "admin:users:administrators"
      },
      label: i18n.t("admin.menu.users.administrators"),
      permissions: ["ADMIN.LIST"]
    }]
  }, {
    to: {
      name: "admin:changeManager"
    },
    label: i18n.t("admin.menu.changeManager"),
    icon: changeManager,
    hasBadges: hasVisibleManagersRequests.value ? badgesManagerRequests?.value : null,
    permissions: ["WEBMASTERS.CHANGE_MANAGER_REQUEST.LIST.ALL"]
  }, {
    to: {
      name: "admin:offers:offers"
    },
    label: i18n.t("admin.menu.offers.offers"),
    isHidden: !isVisibleByAllVerticals.value,
    icon: offers,
    permissions: ["OFFERS.ALL"]
  }, {
    to: {
      name: "admin:complaints"
    },
    label: i18n.t("admin.menu.complaints"),
    icon: complaints,
    permissions: ["TRAFFIC_COMPLAINT.LIST.ALL", "TRAFFIC_COMPLAINT.LIST.OWN", "TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"],
    isHidden: !nutra.value
  }, {
    to: {
      name: "admin:traffic"
    },
    label: i18n.t("admin.menu.traffic"),
    icon: statusUp,
    permissions: ["TRAFFIC_REQUESTS.LIST.ALL", "TRAFFIC_REQUESTS.LIST_HANDLE.OWN"],
    isHidden: !nutra.value
  }, {
    label: i18n.t("admin.menu.capacityAndBumps.capacity"),
    icon: capacity,
    hasBadges: hasVisibleLog.value ? !!badgesLogRequests?.value : null,
    permissions: ["OFFER_PARAMETER_LOG.LIST.ALL", "OFFER_PARAMETER_LOG.LIST.OWN", "OFFER_PARAMETER_LOG.LIST.GUARANTEE", "CAPACITY_STATISTIC.LIST.ALL", "CAPACITY_STATISTIC.LIST.OWN", "CAPACITY.LIST", "CAPACITY.LIST.WEBMASTERS.OWN", "CAPACITY.ACTUAL.LIST.ALL", "CAPACITY.ACTUAL.LIST.WEBMASTERS.OWN"],
    isHidden: !nutra.value,
    children: [{
      to: {
        name: "admin:capacityAndBumps:capacity"
      },
      label: i18n.t("admin.menu.capacityAndBumps.capacity"),
      permissions: ["CAPACITY.LIST", "CAPACITY.LIST.WEBMASTERS.OWN"]
    }, {
      to: {
        name: "admin:capacityAndBumps:log"
      },
      label: i18n.t("admin.menu.capacityAndBumps.log"),
      badgesCount: badgesLogRequests?.value,
      permissions: ["OFFER_PARAMETER_LOG.LIST.ALL", "OFFER_PARAMETER_LOG.LIST.OWN", "OFFER_PARAMETER_LOG.LIST.GUARANTEE"]
    }, {
      to: {
        name: "admin:capacityAndBumps:statistics"
      },
      label: i18n.t("admin.menu.capacityAndBumps.statistics"),
      permissions: ["CAPACITY_STATISTIC.LIST.ALL", "CAPACITY_STATISTIC.LIST.OWN"]
    }, {
      to: {
        name: "admin:capacityAndBumps:efficiency"
      },
      label: i18n.t("admin.menu.capacityAndBumps.efficiency"),
      permissions: ["CAPACITY_STATISTIC.LIST.ALL", "CAPACITY_STATISTIC.LIST.OWN"]
    }, {
      to: {
        name: "admin:capacityAndBumps:actualCapacity"
      },
      label: i18n.t("admin.menu.capacityAndBumps.actualCapacity"),
      permissions: ["CAPACITY.ACTUAL.LIST.ALL", "CAPACITY.ACTUAL.LIST.WEBMASTERS.OWN"]
    }]
  }, {
    label: i18n.t("admin.menu.efficiency.efficiency"),
    icon: efficiency,
    isHidden: !nutra.value,
    permissions: ["MANAGER_EFFICIENCY.VIEW.ALL", "MANAGER_EFFICIENCY.VIEW.OWN", "ADVERTISER_MANAGER_EFFICIENCY.VIEW.ALL"],
    children: [{
      to: {
        name: "admin:efficiency:affiliate"
      },
      label: i18n.t("admin.menu.efficiency.affiliate"),
      isHidden: !nutra.value,
      permissions: ["MANAGER_EFFICIENCY.VIEW.ALL", "MANAGER_EFFICIENCY.VIEW.OWN"]
    }, {
      to: {
        name: "admin:efficiency:advert"
      },
      label: i18n.t("admin.menu.efficiency.advert"),
      isHidden: !nutra.value,
      permissions: "ADVERTISER_MANAGER_EFFICIENCY.VIEW.ALL"
    }]
  }, {
    to: {
      name: "admin:integrations"
    },
    label: i18n.t("admin.menu.integrations"),
    icon: integrations,
    isHidden: !isVisibleByAllVerticals.value,
    permissions: ["INTEGRATIONS.LIST"]
  }, {
    label: i18n.t("admin.menu.integrationsLog.integrationsLog"),
    icon: logIntegrations,
    permissions: ["INTEGRATIONS.LIST_LOG"],
    children: [{
      to: {
        name: "admin:integrationsLog:log"
      },
      label: i18n.t("admin.menu.integrationsLog.log"),
      permissions: ["INTEGRATIONS.LIST_LOG"]
    }, {
      to: {
        name: "admin:integrationsLog:advertiserPostbackLogs"
      },
      label: i18n.t("admin.menu.integrationsLog.advertiserPostbackLogs"),
      permissions: ["INTEGRATIONS.LIST_LOG"]
    }]
  }, {
    label: i18n.t("admin.menu.finances.finances"),
    hasBadges: hasVisibleOutTransactions.value ? !!badgesCosts?.value : null,
    icon: finance,
    permissions: ["SUPER.PERMISSION", "IN_TRANSACTIONS.LIST", "FINANCES.CORRECTION.VIEW", "FINANCES.VIEW_WEB_ACCRUAL", "OUT_TRANSACTIONS.LIST.ALL", "OUT_TRANSACTIONS.LIST.OWN", "FINANCES.SUMMARY", "FINANCES.ADVERTISER_DUTY.LIST"],
    children: [{
      to: {
        name: "admin:finances:costs"
      },
      label: i18n.t("admin.menu.finances.costs"),
      badgesCount: badgesCosts?.value,
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["OUT_TRANSACTIONS.LIST.ALL", "OUT_TRANSACTIONS.LIST.OWN"]
    }, {
      to: {
        name: "admin:finances:incomes"
      },
      label: i18n.t("admin.menu.finances.incomes"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["IN_TRANSACTIONS.LIST"]
    }, {
      to: {
        name: "admin:finances:commissions"
      },
      label: i18n.t("admin.menu.finances.commissions"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["FINANCES.VIEW_WEB_ACCRUAL"]
    }, {
      to: {
        name: "admin:finances:financeSummary"
      },
      label: i18n.t("admin.menu.finances.financeSummary"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["FINANCES.SUMMARY"]
    }, {
      to: {
        name: "admin:finances:correction"
      },
      label: i18n.t("admin.menu.finances.correction"),
      isHidden: !isVisibleByAllVerticals.value,
      permissions: ["FINANCES.CORRECTION.VIEW"]
    }, {
      to: {
        name: "admin:finances:referral"
      },
      label: i18n.t("admin.menu.finances.referral"),
      permissions: ["FINANCES.REFERRAL_BONUS.LIST.ALL"]
    }, {
      to: {
        name: "admin:finances:advertisersDuty"
      },
      label: i18n.t("admin.menu.finances.advertisersDuty"),
      permissions: ["FINANCES.ADVERTISER_DUTY.LIST"]
    }]
  }, {
    label: i18n.t("admin.menu.competition.competition"),
    icon: competition,
    permissions: ["COMPETITION.LIST", "COMPETITION.WEBMASTERS.TOP"],
    children: [{
      to: {
        name: "admin:competition:competition"
      },
      label: i18n.t("admin.menu.competition.competition"),
      permissions: ["COMPETITION.LIST"]
    }, {
      to: {
        name: "admin:competition:rating"
      },
      label: i18n.t("admin.menu.competition.rating"),
      permissions: ["COMPETITION.WEBMASTERS.TOP"]
    }, {
      to: {
        name: "admin:competition:roulette"
      },
      label: i18n.t("admin.menu.competition.roulette"),
      permissions: ["COMPETITION.LIST"]
    }]
  }, {
    to: {
      name: "admin:audit"
    },
    label: i18n.t("admin.menu.audit"),
    icon: audit,
    permissions: ["OFFERS.OFFER_AUDIT"],
    isHidden: !isVisibleByAllVerticals.value
  }, {
    to: {
      name: "admin:payments"
    },
    label: i18n.t("admin.menu.payments"),
    icon: paymentSystems,
    permissions: ["PAYMENT_SYSTEM.MANAGE"]
  }, {
    to: {
      name: "admin:roles"
    },
    label: i18n.t("admin.menu.roles"),
    icon: roles,
    permissions: ["ROLE.CREATE", "ROLE.UPDATE", "ROLE.DELETE"]
  }, {
    to: {
      name: "admin:domains"
    },
    label: i18n.t("admin.menu.domains"),
    icon: domains,
    permissions: ["LANDING_DOMAIN.MANAGE"]
  }, {
    to: {
      name: "admin:CRM"
    },
    label: i18n.t("admin.menu.CRM"),
    icon: crm,
    permissions: ["CRM.MANAGE"]
  }, {
    label: i18n.t("admin.menu.support.support"),
    icon: support,
    children: [{
      href: "https://limonad.atlassian.net/wiki/spaces/LMADMIN/overview",
      target: "_blank",
      label: i18n.t("admin.menu.support.admin")
    }, {
      href: `${process.env.VUE_APP_DOCUMENTATION_URL}/${locale.value}`,
      target: "_blank",
      label: i18n.t("admin.menu.support.webmaster")
    }]
  }].filter(Boolean));
  setActiveSections();
  function setActiveSections() {
    const sectionsMenu = menu.value.map(el => [el, [el.children]]).flat(3).filter(Boolean) as typeof menu.value;
    const activeSections = sectionsMenu.filter(el => permissionsStore.hasPermissions(el.permissions ?? "ANY") && !el.isHidden).map(el => {
      if (el?.to?.name === "admin:offers:offers") return "admin:offers";else return el?.to?.name;
    });
    storeInstance.commit("SET_ACTIVE_SECTIONS", activeSections.filter(Boolean));
    setTimeout(() => {
      menuList.value?.setActiveTabByRouteName();
    }, 100);
  }
  onUnmounted(() => {
    storeInstance.commit("SET_ACTIVE_SECTIONS", null);
  });
  return {
    emit,
    menuList,
    menu
  };
};
__sfc_main.components = Object.assign({
  AsideMenuList,
  WebmastersProfileViewModal
}, __sfc_main.components);
export default __sfc_main;
