



























































































































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import LChat from "@/components/Common/Chat/LChat.vue";
import { useTrafficRequestChatModal } from "@/stores/admin/traffic/modal/trafficRequestChatModalStore";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, onBeforeUnmount } from "@vue/composition-api";
import { useWait } from "@/stores/common/waitStore";
import UserOutput from "@/components/Common/Output/UserOutput.vue";
import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
import { modificationValues } from "@/helpers/modificationValues";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { formatEmptyString, moment } from "@core/filters";
import Permissions from "@/components/Common/Permissions.vue";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";
import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import vuexStore from "@/store";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestChatModal();
  const {
    isActive,
    chat,
    modal,
    isReadonly
  } = storeToRefs(store);
  const storeTraffic = useTrafficRequest();
  const storeCreate = useTrafficRequestCreateModal();
  const isLoading = useWait(store, store.GET_HISTORY_CHAT);
  const isSending = useWait(store, store.SEND_MESSAGE);
  const currentAdminId = computed(() => vuexStore.state.auth.intId);
  const isCurrentAdmin = computed(() => modal.value.creator?.intId === currentAdminId.value);
  const datePeriod = computed(() => modal.value.periodStartDate ? `${moment(modal.value.periodStartDate, "DD.MM.YY")} - ${moment(modal.periodEndDate, "DD.MM.YY")}` : formatEmptyString());
  function showEditTraffic() {
    storeTraffic.showEditTraffic(modal.value);
  }
  onBeforeMount(() => {
    store.getHistoryChat();
  });
  onBeforeUnmount(() => {
    store.$reset();
  });
  return {
    modificationValues,
    TrafficRequestTypeEnum,
    formatEmptyString,
    moment,
    store,
    isActive,
    chat,
    modal,
    isReadonly,
    storeCreate,
    isLoading,
    isSending,
    isCurrentAdmin,
    datePeriod,
    showEditTraffic
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  OfferOutput,
  UserOutput,
  Permissions,
  LChat
}, __sfc_main.components);
export default __sfc_main;
