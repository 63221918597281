import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { AdvertiserOffer } from "@core/logic/common/offer/offer";
import { WithdrawType } from "@core/logic/common/common";
import { Money } from "@core/store/types/common";

export class OffersAdvertiserOffer extends AdvertiserOffer {
  name?: string;
  withdrawType?: WithdrawType;
  vertical?: VerticalsEnum;
  slug?: string;
  subGoalType: string;
  payout: Money;

  constructor (offer: any) {
    super(offer);
    
    this.name = offer.detail.name;
    this.vertical = offer.vertical;
    this.slug = offer.slug;
    this.withdrawType = offer.finance.withdrawType;
    this.subGoalType = offer.finance.subGoalType;
    this.payout = offer.finance.payout;
  }
}