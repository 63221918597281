import { CapacityResponseData } from "@core/store/types/admin/offers/capacity";
import moment from "moment";

export class SetCapacityDTO {
  public softCapacity: number;
  public capacityUpdatedAt: Date;

  constructor (item: CapacityResponseData) {
    this.softCapacity = item.softCapacity;
    this.capacityUpdatedAt = item.capacityUpdatedAt;
  }
}