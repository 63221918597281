<template>
    <div class="menu">
        <AsideMenuList
            ref="menuList"
            :menu="menu"
            :is-reduce="isReduce"
            @resetReduce="$emit('resetReduce')"
            @closeAsideMenu="$emit('closeAsideMenu')">
        </AsideMenuList>
    </div>
</template>

<script>
  import trafficCosts from "@/assets/AsideMenu/traffic-costs.svg";
  import dashboard from "@/assets/AsideMenu/dashboard.svg";
  import commissions from "@/assets/AsideMenu/commissions.svg";
  import finance from "@/assets/AsideMenu/finance.svg";
  import flow from "@/assets/AsideMenu/flow.svg";
  import offers from "@/assets/AsideMenu/offers.svg";
  import statistics from "@/assets/AsideMenu/statistics.svg";
  import competition from "@/assets/AsideMenu/competition.svg";
  import support from "@/assets/AsideMenu/support.svg";
  import subAccounts from "@/assets/AsideMenu/sub-accounts.svg";
  import domains from "@/assets/AsideMenu/domains.svg";
  import discounts from "@/assets/AsideMenu/discounts.svg";
  import referrals from "@/assets/AsideMenu/referrals.svg";
  import AsideMenuList from "@/components/Common/AsideMenuList";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { GET_CURRENT_COMPETITION, SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "AsideMenu",
    components: { AsideMenuList },

    props: {
      isReduce: {
        type: Boolean,
        default: null
      }
    },

    created () {
      //TODO: Переписать на Class все, что связанно с GET_CURRENT_COMPETITION
      if (this.isActiveCompetition) {
        this.GET_CURRENT_COMPETITION();
        this.competitionInterval = setInterval(this.setCompetitionInterval, 60000);
      }
    },

    data () {
      return {
        competitionInterval: null
      };
    },

    computed: {
      ...mapGetters(["isAgent"]),
      ...mapGetters("competition", ["isVisibleCompetition"]),

      ...mapState({
        isDomestic: ({ webmaster }) => webmaster.profile.user.detail?.isDomestic,
        subType: ({ auth }) => auth.subType,
        role: ({ auth }) => auth.role
      }),

      isActiveCompetition () {
        return this.role === "webmaster" && this.subType !== "SUB_WEBMASTER";
      },
  
      locale (){
        return this.$root.$i18n.locale;
      },

      menu () {
        return [
          {
            to: { name: "dashboard" },
            label: this.$t("webmaster.menu.dashboard"),
            icon: dashboard
          },
          {
            label: this.$t("webmaster.menu.statistics.statistics"),
            icon: statistics,
            children: [
              {
                to: { name: "webmaster:statistics:statistics" },
                label: this.$t("webmaster.menu.statistics.statistics")
              },
              {
                to: { name: "webmaster:statistics:detailing" },
                label: this.$t("webmaster.menu.statistics.detailing")
              },
              {
                to: { name: "webmaster:statistics:analytic" },
                label: this.$t("webmaster.menu.statistics.analytic"),
                isHidden: !this.$saas.features.analytic,
                isNew: true
              },
              {
                to: { name: "webmaster:statistics:postbacks" },
                label: this.$t("webmaster.menu.statistics.postbacks")
              },
              {
                to: { name: "webmaster:statistics:analyticsTargetAudience" },
                label: this.$t("webmaster.menu.statistics.analyticsTargetAudience"),
                isHidden: !this.$saas.features.analyticsTargetAudience
              },
              {
                to: { name: "webmaster:statistics:trafficAnalysis" },
                label: this.$t("webmaster.menu.statistics.trafficAnalysis"),
                isHidden: !this.$saas.features.trafficAnalysis
              },
              {
                to: { name: "webmaster:statistics:domonetization" },
                label: this.$t("webmaster.menu.statistics.domonetization"),
                isHidden: !this.$saas.features.domonetization
              }
            ]
          },
          {
            to: { name: "webmaster:offers" },
            label: this.$t("webmaster.menu.offers"),
            icon: offers
          },
          {
            to: { name: "webmaster:trafficCosts" },
            label: this.$t("webmaster.menu.trafficCosts"),
            icon: trafficCosts,
            isHidden: !(this.isAgent || this.subType === "SUB_WEBMASTER" || this.isDomestic),
            isNew: true
          },
          {
            to: { name: "webmaster:commissions" },
            label: this.$t("webmaster.menu.commissions"),
            icon: commissions,
            isNew: true,
            isHidden: !this.isAgent
          },
          {
            to: { name: "webmaster:subAccounts" },
            label: this.$t("webmaster.menu.subAccounts"),
            icon: subAccounts,
            isHidden: !this.isAgent
          },
          {
            to: { name: "webmaster:flows" },
            label: this.$t("webmaster.menu.flows"),
            icon: flow
          },
          {
            to: { name: "webmaster:finances" },
            label: this.$t("webmaster.menu.finances"),
            icon: finance
          },
          {
            to: { name: "webmaster:competitionRating" },
            label: this.$t("webmaster.menu.competitionRating"),
            icon: competition,
            isHidden: !this.isVisibleCompetition
          },
          {
            to: { name: "webmaster:referrals" },
            label: this.$t("webmaster.menu.referrals"),
            icon: referrals,
            isHidden: !this.$saas.features.referrals
          },
          {
            to: { name: "webmaster:domains" },
            label: this.$t("webmaster.menu.domains"),
            icon: domains
          },
          // {
          //   to: '/',
          //   label: 'Инструменты',
          //   icon: 'icons/navbar/tools.svg'
          // },
          // {
          //   to: { name: "webmaster:news" },
          //   label: this.$t("webmaster.menu.news"),
          //   icon: "icons/navbar/news.svg"
          // },
          {
            to: { name: "webmaster:discounts" },
            isHidden: !this.$saas.features.discounts,
            label: this.$t("webmaster.menu.discounts"),
            icon: discounts
          },
          {
            href: `${ process.env.VUE_APP_DOCUMENTATION_URL }/${ this.locale }`,
            target: "_blank",
            label: this.$t("webmaster.menu.support"),
            icon: support
          }
          
        ];
      }
    },

    methods: {
      ...mapActions("competition", [
        GET_CURRENT_COMPETITION,
        SET_EMPTY
      ]),

      setCompetitionInterval () {
        if (this.isActiveCompetition) {
          this.GET_CURRENT_COMPETITION();
        } else {
          clearInterval(this.competitionInterval);
        }
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
      clearInterval(this.competitionInterval);
    }
  };
</script>

<style scoped></style>
