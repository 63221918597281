import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { CreateCapacityRequestInput } from "@core/store/types/admin/offers/capacity";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export default function CreateCapacityRequestGenerator (input: CreateCapacityRequestInput): ASTGeneratedOutput {
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("oldCapacityValue");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "createCapacityRequest",

    fields: [
      {
        creator: [
          "login",
          "intId",
          "id"
        ]
      },
      {
        executor: [
          "name",
          "id"
        ]
      },
      {
        webmaster: [
          "intId",
          "id",
          "login",
          "isBindedToCurrentAdmin"
        ]
      },
      "newCapacityValue",
      "oldCapacityValue",
      "creatorComment",
      "status",
      "createdAt",
      "updatedAt",
      "id",
      "intId"
    ],

    variables: {
      input: {
        type: "CreateCapacityRequestInput!",
        value: input
      }
    }

  };

  return Generator.mutation(gqlObject, params);
}