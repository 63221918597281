import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { Advertisers } from "@core/logic/admin/users/users";
import { AdminOfferInterface } from "@core/store/types/admin/offer";
import { Money } from "@core/store/types/common";

export class AdminOffer extends AbstractOffer {
  public advertisers?: Array<Advertisers>;
  private currency?: string;
  public rate?: Money;

  constructor (payload?: AdminOfferInterface) {
    super(payload);
    if (payload) {
      this.advertisers = payload.advertisers;
      this.rate = payload.finance?.rate;
      this.currency = payload.finance?.currency;
    }
  }
}
