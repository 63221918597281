var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BlockBox',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"title-content-right",fn:function(){return [_vm._t("title-content-right")]},proxy:true}],null,true)},[_c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [(_vm.isNotificationShown)?_c('b-notification',{attrs:{"closable":false,"type":"is-info"}},[_vm._v(" "+_vm._s(_vm.$t("common.entity.transits.notifications.selected"))+" "+_vm._s(_vm.checked.length)+" / "+_vm._s(_vm.data.count)+" ")]):_vm._e(),_c('b-table',{staticClass:"common-table",attrs:{"checkable":_vm.checkable,"checked-rows":_vm.checkedArray,"data":items,"sticky-header":_vm.stickyHeaders,"hoverable":"","striped":""},on:{"update:checkedRows":function($event){_vm.checkedArray=$event},"update:checked-rows":function($event){_vm.checkedArray=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.data.items && _vm.data.items.length && _vm.isInfiniteLoading_)?_c('InfiniteLoading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"})]):_vm._e()]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"label":_vm.labelName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ColoredStatus',{attrs:{"show-status":_vm.showReady && row.hasOwnProperty('isReady'),"type":_vm.coloredStatus(row.isReady),"tooltip-position":"is-bottom","tooltip-type":"is-dark"}},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(row.name)+" ")]),(row.isNew)?[_c('b-tag',{staticClass:"py-1",attrs:{"type":"is-info"}},[_vm._v(" New ")])]:_vm._e(),[(row.isTikTok)?_c('LTooltip',{attrs:{"label":_vm.$t('common.entity.landings.modal.tooltip.tikTok'),"type":"is-dark","size":"is-small"}},[_c('b-tag',{staticClass:"ml-1 py-1",attrs:{"type":"is-dark"}},[_vm._v(" "+_vm._s(_vm.$t("common.entity.landings.modal.add.body.tikTok"))+" ")])],1):_vm._e()]],2)]}}],null,true)}),(_vm.offerConversion)?_c('b-table-column',{attrs:{"sortable":"","field":"cr","label":_vm.offerConversion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isLoadingConversion)?_c('InlineLoader',{attrs:{"active":""}}):[_vm._v(" "+_vm._s(row.cr ? ((row.cr) + "%") : "-")+" ")]]}}],null,true)}):_vm._e(),(_vm.date)?_c('b-table-column',{attrs:{"label":_vm.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.createdAt))+" ")]}}],null,true)}):_vm._e(),_c('b-table-column',{attrs:{"label":_vm.labelLanguage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatLanguage(row))+" ")]}}],null,true)}),(_vm.labelVisibility)?_c('b-table-column',{attrs:{"label":_vm.labelVisibility},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isPublic)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.entity.landings.table.descriptions.all"))+" ")]):(!row.webmasters.length && !row.isPublic)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.entity.landings.table.descriptions.none"))+" ")]):_c('CompactList',{attrs:{"items":row.webmasters},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var item = ref.item;
return [_c('UserOutput',{attrs:{"user":item,"int-id":""}})]}},{key:"tooltipItem",fn:function(ref){
var item = ref.item;
return [_c('UserOutput',{attrs:{"user":item,"int-id":""}})]}},{key:"singleItem",fn:function(ref){
var item = ref.item;
return [_c('UserOutput',{attrs:{"user":item,"int-id":""}})]}}],null,true)})]}}],null,true)}):_vm._e(),(_vm.labelLastFile)?_c('b-table-column',{attrs:{"label":_vm.labelLastFile},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('LTooltip',{attrs:{"active":_vm.isFailedStatus(row) && _vm.reasonForFail(row).length !== 0,"label":_vm.reasonForFail(row),"max-width":250,"position":"is-bottom","type":"is-danger"}},[_c('ColoredStatus',{attrs:{"type":_vm.coloredStatusLastFile(row.lastFile),"tooltip-position":"is-bottom","tooltip-type":"is-dark"}},[_vm._v(" "+_vm._s(_vm.hasFile(row.lastFile))+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.labelCurrentFile)?_c('b-table-column',{attrs:{"label":_vm.labelCurrentFile},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ColoredStatus',{attrs:{"type":_vm.coloredStatus(row.currentFile),"tooltip-position":"is-bottom","tooltip-type":"is-dark"}},[_vm._v(" "+_vm._s(_vm.hasFile(row.currentFile))+" ")])]}}],null,true)}):_vm._e(),(_vm.unloading)?_c('b-table-column',{attrs:{"label":_vm.$t('common.entity.landings.table.labels.unloading'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{attrs:{"loading":_vm.isLoadingFile,"icon-left":"file-download","type":"is-info is-light","disabled":!row.isExistCurrentFile},on:{"click":function($event){return _vm.emit('file-download', row)}}})]}}],null,true)}):_vm._e(),_c('b-table-column',{attrs:{"width":"1%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"columns is-flex is-variable is-1"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"bulma-button",attrs:{"disabled":!_vm.isAbleToPreview(row),"href":row.previewLink,"icon-left":"eye","icon-pack":"far","size":"is-small","tag":"a","target":"_blank","type":"is-info"}},[_vm._v(" "+_vm._s(_vm.$t("common.entity.transits.table.buttons.preview"))+" ")])],1),_vm._t("buttons",null,{"row":row})],2)]}}],null,true)})],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"hero has-text-centered"},[_c('div',{staticClass:"hero-body"},[_c('h2',{staticClass:"is-size-5 is-bold has-text-weight-medium has-text-grey"},[_vm._t("empty",function(){return [_vm._v(" "+_vm._s(_vm.$t("common.entity.landings.table.empty"))+" ")]})],2)])])]},proxy:true}],null,true)}),_vm._t("footer"),(_vm.isLoading)?_c('LLoading',{attrs:{"is-full-page":false,"active":""}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }