import { OfferConversionState } from "@core/store/types/admin/offer/lists/offerConversionList";
import { OfferConversionService } from "@core/services/common/offer/OfferConversionService";
import { formatPercent, toFixed } from "@core/filters";
import { defineStore } from "pinia";

export const useOfferConversionList = defineStore("admin/offer/offerConversionList", {
  state: (): OfferConversionState => ({
    offerConversion: []
  }),

  actions: {
    async GET_OFFER_CONVERSION (offerId: string): Promise<void> {
      const { data: { offerConversion } } = await OfferConversionService.getOfferConversion(offerId);

      // @ts-ignore
      this.offerConversion = offerConversion
        .map(({ cr, ...otherFields }) => ({ cr: toFixed(formatPercent(cr), 2) , ...otherFields }));
    }
  }
});
