import { ResponseOfferExternalWebmaster, ResponseOfferWebmaster } from "@core/store/types/admin/offer/OfferWebmasters";
import {
  OfferAdvertiserIndividualRate,
  OfferAdvertiserIndividualRateResponse
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";

export function offerWebmastersFormatter (items: ResponseOfferWebmaster[]) {
  return items.map(item => {
    if (item.individualRate) {
      return {
        ...item,
        id: item.webmaster.id,
        individualRate: {
          ...item.individualRate,
          holdTime: item.individualRate?.hold?.holdMinutes || null
        }
      };
    }
    return {
      ...item,
      id: item.webmaster.id
    };
  });
}

export function offerExternalWebmastersFormatter (items: ResponseOfferExternalWebmaster[]) {
  return items.map(item => {
    return {
      ...item,
      individualRate: {
        id: item.id,
        rate: item.rate,
        holdTime: item.hold?.holdMinutes || null
      }
    };
  });
}

export function formatIndividualPayouts (data: OfferAdvertiserIndividualRateResponse[]): OfferAdvertiserIndividualRate[] {
  // @ts-ignore
  return data?.map(item => {
    return {
      ...item,
      payout: item.payout.value,
      isPayoutLoading: false
    };
  });
}
