import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { BampsAndCapsState } from "@core/store/types/admin/offers/bampsAndCaps";

export default function offerParameterRequests (
  limit: number,
  offset: number,
  sort: BampsAndCapsState["sort"],
  order: BampsAndCapsState["order"],
  filters: BampsAndCapsState["filters"],
  isShowCommission: boolean,
  isShowOfferAdvertisers: boolean
): ASTGeneratedOutput {
  const include = [];

  if (isShowCommission) {
    include.push(
      "items/offer/minimalCommission/value",
      "items/offer/minimalCommission/currency",
      "items/...on PayoutRequest/webmasterRate"
    );
  }
  if (isShowOfferAdvertisers) {
    include.push(
      "items/offer/advertiser/login",
      "items/offer/advertiser/id",
      "items/offer/advertiser/intId"
    );
  }

  const params = { include };

  const gqlObject = {
    operation: "offerParameterRequests",

    fields: [
      {
        items: [
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "subType",
              "isBindedToCurrentAdmin",
              { webmasters: ["id", "intId", "login"] }
            ]
          },
          {
            "...on PayoutRequest": [
              "oldPayoutValue",
              "newPayoutValue",
              "goal",
              "externalWebmaster",
              "resultComment",
              "reason"
            ]
          },
          {
            "...on CapacityRequest": [
              "oldCapacityValue",
              "newCapacityValue"
            ]
          },
          {
            "...on BookingCapacityRequest": [
              "newCapacityValue"
            ]
          },
          {
            "...on GuaranteeRequest": [
              "guaranteeStatus",
              {
                renewals: [
                  "id",
                  "createdAt"
                ]
              },
              "externalWebmaster",
              "guaranteeCapacity",
              "approveConversion",
              "guaranteePercent",
              "guaranteeStartDate",
              "guaranteeEndDate",
              "guaranteeRenewalDate",
              "guaranteeDayCount",
              "executorComment",
              "isSuspended"
            ]
          },
          {
            creator: [
              "login",
              "intId",
              "id"
            ]
          },
          {
            executor: [
              "login",
              "intId",
              "id"
            ]
          },
          {
            offer: [
              "id",
              "intId",
              "slug",
              {
                detail: [
                  "name"
                ]
              },
              {
                countries: [
                  "name",
                  "id",
                  "alpha2",
                  "alpha3"
                ]
              }
            ]
          },
          "status",
          "type",
          "result",
          "intId",
          "id",
          "executorComment",
          "creatorComment",
          "createdAt",
          "executedAt",
          "updatedAt"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      sort: {
        type: "OfferParameterRequestListCriteriaSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      },
      filters: {
        type: "OfferParameterRequestListCriteriaFilters",
        value: filters
      }
    }
  };

  return Generator.query(gqlObject, params);
}