



















import WebmastersSelect from "@/components/Common/Select/WebmastersSelect.vue";
import { Offer } from "@core/logic/admin/offer/offer";
import BlockBox from "@/components/Common/BlockBox.vue";
import { useWebmastersByOffer } from "@/stores/admin/offer/webmastersByOfferStore";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
import { storeToRefs } from "pinia";
import router from "@/router";
interface Props {
  offer: Offer | null;
}
const __sfc_main = {};
__sfc_main.props = {
  offer: {
    key: "offer",
    required: false,
    type: null,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = useWebmastersByOffer();
  const {
    filters
  } = storeToRefs(store);
  const offerId = computed(() => router.currentRoute.params.id ?? props.offer?.id);
  return {
    filters,
    offerId
  };
};
__sfc_main.components = Object.assign({
  BlockBox,
  WebmastersSelect
}, __sfc_main.components);
export default __sfc_main;
