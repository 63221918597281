import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { CapacityAnalyseFilters } from "@core/store/types/admin/offers/capacity";
import {
  ExternalWebmastersAnalyseFiltersDTO
} from "../../../../../../stores/admin/capacityAndBumps/capacity/dto/ExternalWebmastersAnalyseFiltersDTO";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export default function webmastersCapacityAnalyse (
  offerId: string,
  webmasterId: string,
  filters: CapacityAnalyseFilters,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const { isShowCommissions } = useOffers();

  const params = {
    include: isShowRedemption ? ["conversionPayout"] : [],
    exclude: !isShowCommissions ? ["webmasterRate"] : []
  };

  const gqlObject = {
    operation: "capacityGraphByWebmaster",
  
    fields: [
      {
        offer: [
          "id",
          "intId",
          {
            detail: [
              "name"
            ]
          },
          {
            finance: [
              "currency"
            ]
          }
        ]
      },
      {
        webmaster: [
          "id",
          "intId",
          "login",
          "subType"
        ]
      },
      "conversionApprove",
      "conversionSpam",
      "webmasterRate",
      "countLeadsIntegrated",
      "countLeadsSpamByAdvertiser",
      "countLeadsAccepted",
      "softCapacity",
      "date",
      "hasCapacityChanged",
      "hasWebmasterRateChanged"
    ],
    
    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      },
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      },
      filters: {
        type: "CapacityGraphByWebmasterCriteriaFilters",
        value: new ExternalWebmastersAnalyseFiltersDTO(filters)
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}