import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import { Options } from "@core/store/types/common/domains/domains";
import { LandingDomainsFilters, ResponseLandingDomains } from "@core/store/types/common/lists/landingDomainsList";
import landingDomains from "@core/services/webmaster/domains/landingDomains.graphql";
import removeLandingDomain from "@core/services/webmaster/domains/removeLandingDomain.graphql";
import checkDnsDomainQuery from "@core/services/webmaster/domains/checkDnsDomain.graphql";
import addLandingDomain from "@core/services/webmaster/domains/addLandingDomain.graphql";

export class DomainsService extends Service {
  public static async getDomains (
    input: LandingDomainsFilters,
    limit = 25,
    offset = 0
  ): Promise<ResponseLandingDomains> {
    try {
      return await this.api.get("", {
        params: {
          query: landingDomains,
          variables: {
            limit,
            offset,
            input
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async addDomain (input: Options | null): Promise<AxiosPromise> {
    try {
      const { data: { addWebmasterLandingDomain } } = await this.api.get("", {
        params: {
          query: addLandingDomain,
          variables: {
            input
          }
        }
      });
      
      return addWebmasterLandingDomain;
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteDomain (domainId: string): Promise<AxiosPromise> {
    try {
      const { data: { removeWebmasterLandingDomain } } = await this.api.get("", {
        params: {
          query: removeLandingDomain,
          variables: {
            domainId
          }
        }
      });
     
      return removeWebmasterLandingDomain;
    } catch (e) {
      throw e;
    }
  }
  
  public static async updateDomains (id: string): Promise<AxiosPromise> {
    try {
      const { data: { checkDnsDomain } } = await this.api.get("", {
        params: {
          query: checkDnsDomainQuery,
          variables: {
            id
          }
        }
      });
      
      return checkDnsDomain;
    } catch (e) {
      throw e;
    }
  }
}
