import { Filters } from "@core/store/modules/common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";
import {
  OfferEditAdvertisersAvailableService
} from "@core/services/admin/common/offer/OfferEditAdvertisersAvailableService";

export class OfferAvailableAdvertisers extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput) => {
    const { data: { offerAvailableAdvertisers } } =
                await OfferEditAdvertisersAvailableService.getOfferAdvertisers(offset, limit, filters);

    return {
      data: {
        items: offerAvailableAdvertisers.items,
        count: offerAvailableAdvertisers.count
      }
      // initialData: {
      //   items: initialAdvertisers.availableAdvertisers?.items,
      //   count: initialAdvertisers.availableAdvertisers?.length
      // }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
