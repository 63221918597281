import { FinancesCorrectionService } from "@core/services/admin/finances/financesHistory/FinancesCorrectionService";
import { Filters } from "@core/store/modules/common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";

export interface ExposeItem {
  from: string;
  id: string;
  name: string;
  to: string;
  canBeRemoved: boolean;
}

export class ExpenseItems extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { initialExpenseItems, expenseItems } } =
                await FinancesCorrectionService.getExpenseItems(offset, limit, filters, initialFilters);

    return {
      data: {
        items: expenseItems?.items,
        count: expenseItems?.count
      },
      initialData: {
        items: initialExpenseItems?.items,
        count: initialExpenseItems?.length
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}