import addIndividualRates from "@core/services/admin/offer/edit/graphql/addIndividualRates.graphql";
import makeIndividualRate from "@core/services/admin/offer/edit/graphql/makeIndividualRate.graphql";
import maximalAllowedRate from "@core/services/admin/offer/edit/graphql/maximalAllowedRate.graphql";
import excludeWebmastersFromOffer from "@core/services/admin/offer/edit/graphql/excludeWebmastersFromOffer.graphql";
import includeWebmastersToOffer from "@core/services/admin/offer/edit/graphql/includeWebmastersToOffer.graphql";
import webmasterRates from "@core/services/admin/offer/edit/graphql/webmasters.graphql";
import webmastersWithIndividualRate from "@core/services/admin/offer/edit/graphql/webmastersWithIndividualRate.graphql";
import getMaximalAllowedInternalWebmastersReward from "@core/services/admin/offer/edit/graphql/getMaximalAllowedInternalWebmastersReward.graphql";
import Service from "@core/services/common/Service";
import {
  CreateIndividualRate, OfferWebmaster,
  PartialOfferWebmastersState,
  RequestAvailableWebmasters,
  RequestIndividualRate,
  ResponseIndividualRate
} from "@core/store/types/admin/offer/OfferWebmasters.d";
import { AxiosPromise, AxiosResponse } from "axios";
import { OfferExternalWebmastersState } from "@core/store/types/admin/offer/OfferExternalWebmastersState";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";
import webmasterRatesGenerator from "@core/services/admin/offer/edit/graphql/webmasterRates.generator";
import {
  offerExternalWebmastersFormatter,
  offerWebmastersFormatter
} from "@core/services/admin/offer/edit/dataMapper/offerMapper";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { Money } from "@core/store/types/common";

export class OfferEditWebmastersServices extends Service {
  public static async getOfferWebmasters (
    filters: PartialOfferWebmastersState["filters"],
    offerId: string,
    limit: number,
    offset = 0
  ): Promise<{ items: any[]; count: number }> {
    try {
      const { isShowCommissions } = useOffers();

      const { data: { offer: { webmasters } } } = await this.api.get("", {
        params: {
          query: isShowCommissions ? webmastersWithIndividualRate : webmasterRates,
          variables: {
            limit,
            offset,
            filters,
            offerId
          }
        }
      });

      return { items: offerWebmastersFormatter(webmasters.items), count: webmasters.count };
    } catch (e) {
      throw e;
    }
  }

  public static async getOfferExternalWebmasters (
    filters: OfferExternalWebmastersState["filters"],
    offerId: string,
    limit: number,
    offset = 0
  ): Promise<PaginationOutput<OfferWebmaster[]>> {
    try {
      const { data: { individualRates: data } } = await this.api.get("", { params: webmasterRatesGenerator(limit, offset, offerId, filters) });

      return { items: offerExternalWebmastersFormatter(data.items) as unknown as OfferWebmaster[], count: data.count };
    } catch (e) {
      throw e;
    }
  }

  public static async excludeWebmastersFromOffer (offerId: string, webmasters: { webmasters: string[] | null }): Promise<AxiosResponse> {
    try {
      const { data: { excludeWebmastersFromOffer: data } } = await this.api.post("", {
        query: excludeWebmastersFromOffer,
        variables: {
          offerId,
          input: { ...webmasters }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async offerIncludeMultipleWebmasters (offerId: string, webmasters: RequestAvailableWebmasters[]): Promise<any> {
    try {
      const { data: { includeWebmastersToOffer: { webmasters: data } } } = await this.api.post("", {
        query: includeWebmastersToOffer,
        variables: {
          offerId,
          input: { webmasters }
        }
      });

      return offerWebmastersFormatter(data.items);
    } catch (e) {
      throw e;
    }
  }
  
  public static async addIndividualRates (offerId: string, rates: CreateIndividualRate[]): Promise<CreateIndividualRate[]> {
    try {
      const { data: { addIndividualRates: data } } = await this.api.post("", {
        query: addIndividualRates,
        variables: {
          offerId,
          input: { rates }
        }
      });
      return offerExternalWebmastersFormatter(data) as unknown as CreateIndividualRate[];
    } catch (e) {
      throw e;
    }
  }
  
  public static async makeIndividualRate (
    offerId: string,
    webmasterId: string,
    input: RequestIndividualRate
  ): Promise<Partial<ResponseIndividualRate>> {
    try {
      const { data: { makeIndividualRate: data } } =
        await this.api.post("", {
          query: makeIndividualRate,
          variables: {
            offerId,
            webmasterId,
            input
          }
        });
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getMaximalAllowedRate (
    offerId: string | undefined,
    webmasterId: string,
    externalWebmasterId: string
  ): Promise<Money> {
    try {
      const { data: { maximalAllowedRate: data } } = await this.api.post("", {
        query: maximalAllowedRate,
        variables: {
          offerId,
          webmasterId,
          externalWebmasterId
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static getMaximalAllowedInternalWebmastersReward (
    offerId: string,
    webmastersIds: string[],
    limit: number,
    offset = 0
  ): AxiosPromise {
    try {
      return this.api.post("", {
        query: getMaximalAllowedInternalWebmastersReward,
        variables: {
          offerId,
          webmastersIds,
          limit,
          offset
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
