import { Filters } from "../../common/generators/filters";
import { OfferDetailItem, OfferItem } from "@core/store/types/admin/statistic/common/offersFilters";
import {
  FilterInput,
  BaseFiltersInput,
  OfferFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { SubAccountOffersService } from "@core/services/webmaster/list/subAccountOffersService";

export class SubAccountOffers extends Filters<OfferItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: OfferFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { subAccountOffers, initialOffers } } =
                await SubAccountOffersService.getSubAccountOffersFilters(offset, limit, filters, initialFilters);

    const dataItems = subAccountOffers?.items
      ?.map(({ detail, ...otherFields }: OfferDetailItem): OfferItem => ({ name: detail.name, ...otherFields }));
    const dataInitialItems = initialOffers?.items
      ?.map(({ detail, ...otherFields }: OfferDetailItem): OfferItem => ({ name: detail.name, ...otherFields }));

    return {
      data: {
        items: dataItems,
        count: subAccountOffers.count
      },
      initialData: {
        items: dataInitialItems,
        count: initialOffers?.length
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
