<template>
    <div class="card-profile">
        <h4 class="card-title">
            {{ $t(`common.entity.webmasterCard.fullCard.body.main.title`) }}
        </h4>
        <div class="columns is-multiline">
            <div class="column is-6">
                <b-field :label="`${ $t(`webmaster.profile.labels.email`) }:`">
                    <LInput
                        v-model="email"
                        :pattern="emailValidator"
                        :placeholder="$t(`common.auth.signUp.email`)"
                        type="email">
                    </LInput>
                </b-field>
            </div>
            <div class="column is-6">
                <LTooltip
                    :label="$t(`webmaster.profile.labels.loginMessage`)"
                    class="is-block"
                    position="is-bottom"
                    type="is-danger">
                    <b-field :label="`${ $t(`webmaster.profile.labels.login`) }:`">
                        <LInput
                            v-model="login"
                            disabled
                            expanded>
                        </LInput>
                    </b-field>
                </LTooltip>
            </div>
        </div>

        <ContactsBox
            :skype.sync="skype"
            :telegram.sync="telegram"
            :viber.sync="viber"
            :whats-app.sync="whatsApp">
        </ContactsBox>

        <div class="columns is-multiline">
            <div class="column is-6">
                <b-field :label="`${ $t('webmaster.profile.labels.timezone') }:`">
                    <b-select
                        v-model="timeZone"
                        expanded>
                        <optgroup
                            :key="continent"
                            v-for="(zones, continent) in timeZones"
                            :label="continent">
                            <option
                                :key="i"
                                v-for="(zone, i) in zones"
                                :value="zone.alias">
                                {{ zone.alias }} {{ zone.offset }}
                            </option>
                        </optgroup>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t('webmaster.profile.labels.birthday') }:`">
                    <BirthDatepicker v-model="birthday"></BirthDatepicker>
                </b-field>
            </div>
        </div>

        <h4 class="card-title">
            {{ $t(`common.entity.webmasterCard.fullCard.body.managers.team`) }}
        </h4>
        <div class="columns is-multiline">
            <div class="column is-6">
                <b-field :label="$t(`common.entity.webmasterCard.fullCard.body.team`)">
                    <Select
                        v-model="isTeam"
                        class="white"
                        :get-data-vuex="getConditionType"
                        :clearable="false"
                        :searchable="false"
                        field="label"
                        prop="value"
                        placeholder="TeamRole">
                    </Select>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="$t(`common.entity.webmasterCard.fullCard.body.nameTeam`)">
                    <LInput
                        v-model="teamName"
                        :disabled="!isTeam"
                        :searchable="false"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.nameTeam`)">
                    </LInput>
                </b-field>
            </div>
        </div>

        <h4 class="card-title">
            {{ $t(`common.entity.webmasterCard.fullCard.body.traffic`) }}
        </h4>
        <div class="columns is-multiline">
            <div class="column is-12">
                <b-field :label="$t(`common.entity.webmasterCard.fullCard.body.trafficTypes`)">
                    <Select
                        v-model="trafficTypes"
                        :get-data-vuex="getTrafficTypes"
                        class="white"
                        multiple
                        :searchable="false"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.traffic`)">
                        <template #text="{ option }">
                            {{ $t(`dictionaries.trafficTypes.${ option.name }`) }}
                        </template>
                        <template #selected-option="{ option }">
                            {{ $t(`dictionaries.trafficTypes.${ option.name }`) }}
                        </template>
                    </Select>
                </b-field>
            </div>
        </div>

        <h4 class="card-title">
            {{ $t(`common.entity.webmasterCard.fullCard.body.offers`) }}
        </h4>
        <div class="columns is-multiline">
            <div class="column is-12">
                <b-field :label="$t(`common.entity.webmasterCard.fullCard.body.offerPreferences`)">
                    <CategoriesFilter
                        v-model="categoriesPreferences"
                        multiple
                        :searchable="false"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.offerPreferences`)">
                    </CategoriesFilter>
                </b-field>
            </div>
        </div>

        <h4 class="card-title">
            {{ $t(`common.entity.webmasterCard.fullCard.body.other.title`) }}
        </h4>
        <div class="columns is-multiline">
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.domestic`) }:`">
                    <Select
                        v-model="isDomestic"
                        :get-data-vuex="getConditionType"
                        class="white"
                        field="label"
                        :clearable="false"
                        :searchable="false"
                        prop="value"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.domestic`)">
                    </Select>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.partnerNetwork`) }:`">
                    <Select
                        v-model="isPartnerNetwork"
                        class="white"
                        :get-data-vuex="getConditionType"
                        field="label"
                        :clearable="false"
                        :searchable="false"
                        prop="value"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.partnerNetwork`)">
                    </Select>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.typeOffice`) }:`">
                    <Select
                        v-model="label"
                        class="white"
                        field="label"
                        prop="value"
                        :clearable="false"
                        :searchable="false"
                        :get-data-vuex="getWebmasterLabels"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.typeOffice`)">
                    </Select>
                </b-field>
            </div>
            <div
                v-if="!isSubWebmaster"
                class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.agentOffice`) }:`">
                    <Select
                        v-model="isAgent"
                        class="white"
                        :get-data-vuex="getConditionType"
                        field="label"
                        prop="value"
                        :searchable="false"
                        :clearable="false"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.agentOffice`)">
                    </Select>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.vertical`) }:`">
                    <VerticalsSelect
                        v-model="verticals"
                        multiple
                        field="name"
                        :searchable="false"
                        hide-null
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.vertical`)">
                    </VerticalsSelect>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.residenceCountry`) }:`">
                    <CountriesSelect
                        v-model="residenceCountry"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.residenceCountry`)">
                    </CountriesSelect>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="`${ $t(`common.entity.webmasterCard.fullCard.body.other.residenceCity`) }:`">
                    <CitiesSelect
                        v-model="residenceCity"
                        :disabled="!residenceCountry"
                        :filter-options="{ countryId: residenceCountry }"
                        :placeholder="$t(`common.entity.webmasterCard.fullCard.body.other.residenceCity`)">
                    </CitiesSelect>
                </b-field>
            </div>
        </div>

        <b-field
            :label="$t(`common.entity.webmasterCard.fullCard.body.comment`)"
            class="mt-5">
            <LInput
                v-model="comment"
                :placeholder="$t(`common.buttons.enterText`)"
                type="textarea">
            </LInput>
        </b-field>
    </div>
</template>

<script>
  import CategoriesFilter from "@/components/Common/Select/CategoriesFilter.vue";
  import BirthDatepicker from "@/components/Common/Date/BirthDatepicker.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    GET_WEBMASTER_LABELS,
    GET_TRAFFIC_TYPES,
    UPDATE_OPTIONS,
    UPDATE_FORM
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { formatEmptyString } from "@core/filters";
  import { EMAIL_VALIDATOR } from "@core/validators";
  import { mapActions, mapState } from "vuex";
  import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import CitiesSelect from "@/components/Common/Select/CitiesSelect.vue";
  import ContactsBox from "@/components/Common/ContactsBox.vue";

  export default {
    name: "WebmastersProfileEditCard",
    components: {
      ContactsBox,
      CitiesSelect,
      CountriesSelect,
      VerticalsSelect,
      CategoriesFilter,
      BirthDatepicker,
      Select
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapState({
        trafficTypesList: ({ trafficTypes }) => trafficTypes.trafficTypes,
        timeZones: ({ dictionaries }) => dictionaries.timeZones
      }),

      ...mapState("admin/webmasterLabels", {
        webmasterLabels: ({ webmasterLabels }) => webmasterLabels
      }),

      ...mapFields("webmasterDataById/webmasterProfileViewModal", {
        fields: [
          "email", "login", "timeZone", "teamName", "birthday", "trafficTypes",
          "label", "categoriesPreferences", "isTeam", "isAgent", "comment", "isPartnerNetwork",
          "verticals", "isDomestic", "residenceCity", "residenceCountry",
          "skype", "telegram", "whatsApp", "viber"
        ],
        base: "form",
        action: UPDATE_FORM
      }),

      ...mapFields("webmasterDataById/webmasterProfileViewModal", {
        fields: ["canBeEdit"],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      emailValidator () {
        return EMAIL_VALIDATOR;
      },

      isSubWebmaster () {
        return this.data?.subType === "SUB_WEBMASTER";
      }
    },

    methods: {
      formatEmptyString,

      ...mapActions({
        GET_WEBMASTER_LABELS: `admin/webmasterLabels/${ GET_WEBMASTER_LABELS }`,
        GET_TRAFFIC_TYPES: `trafficTypes/${ GET_TRAFFIC_TYPES }`
      }),

      async getTrafficTypes () {
        await this.GET_TRAFFIC_TYPES();
        return {
          items: this.trafficTypesList,
          count: this.trafficTypesList?.length
        };
      },

      getWebmasterLabels () {
        this.GET_WEBMASTER_LABELS();
        return this.webmasterLabels;
      },

      getConditionType () {
        const items = [
          { label: this.$t("common.entity.webmasterCard.fullCard.body.detail.partnerNetwork.confirm"), value: true },
          { label: this.$t("common.entity.webmasterCard.fullCard.body.detail.partnerNetwork.cancel"), value: false }
        ];
        return { items, count: items.length };
      }
    },

    watch: {
      residenceCountry (value, oldValue) {
        if (oldValue && value !== oldValue) {
          this.residenceCity = null;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
