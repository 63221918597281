import { Landing, OfferLandingsFilters, OfferLandingsState } from "@core/store/types/admin/offer/OfferLandings";
import { OfferConversionList } from "@core/store/types/admin/offer/lists/offerConversionList";
import { OfferLandingsService } from "@core/services/admin/offer/OfferLandingsService";
import { useOfferConversionList } from "@/stores/admin/offer/offerConversionListStore";
import { OfferService } from "@core/services/common/offer/OfferService";
import { defineStore, StoreDefinition, storeToRefs } from "pinia";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { Pagination } from "@core/store/types/common";
import _unionBy from "lodash/unionBy";

export function useOfferLandings (pageType: OfferLandingsFilters["pageType"]) {
  return defineStore(`admin/offer/landings${ pageType }`, {
    state: (): OfferLandingsState => ({
      offerId: null,
      landings: {
        items: [],
        count: 0
      },
      pagination: {
        perPage: 25,
        page: 1
      },
      filters: {
        pageType
      }
    }),

    actions: {
      async GET_LANDINGS (offerId: string): Promise<void> {
        const { page, perPage } = this.pagination;
        const limit = perPage;
        const offset = (page - 1) * perPage;

        const { pageType, ...fields } = this.filters;
        const filters = {
          ...fields,
          offerId,
          pageType: pageType?.toUpperCase()
        };

        if (offerId) {
          const { data: { landingPages } } = await OfferService.getLandingPages({ limit, offset, filters }, "admin");

          if (page === 1) {
            this.landings = landingPages;
          } else {
            this.landings = {
              count: landingPages.count,
              items: _unionBy(landingPages.items, this.landings.items, "id")
            };
            this.UPDATE_OFFER_LANDINGS();
          }
        }
      },

      ADD_OFFER_CONVERSION ({ conversion, landings }: any) {
        const pageType = this.filters.pageType?.toUpperCase();

        return landings.items.map((el: Landing) => {
          const { cr, isNew } = conversion.find((item: OfferConversionList) =>
            item.pageType === pageType && item.pageId === el.id) ?? {};

          return { ...el, cr, isNew };
        });
      },

      UPDATE_MANUAL_LANDINGS (offerId: string): void {
        const { page, perPage } = this.pagination as OfferLandingsState;

        try {
          this.UPDATE_PAGINATION({ page: 1, perPage: perPage * page });
          this.GET_LANDINGS(offerId);
        } finally {
          this.UPDATE_PAGINATION({ page, perPage });
        }
      },

      async REMOVE_PAGE (id: string): Promise<void> {
        const data = await OfferLandingsService.removePage(id);
        useStateUpdater(useOfferLandings(pageType) as StoreDefinition).LOCAL_DELETE({ items: [data], target: "landings" });
      },

      async UPDATE_OFFER_LANDINGS () {
        const { landings } = this;
        const conversionListStore = useOfferConversionList();
        const { offerConversion } = storeToRefs(conversionListStore);

        const items = await this.ADD_OFFER_CONVERSION({ conversion: offerConversion.value, landings });

        this.landings = { count: landings.count, items };
      },

      UPDATE_PAGINATION (pagination: Pagination): void {
        this.pagination = { ...this.pagination, ...pagination };
      }
    }
  })();
}
