import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { OfferState } from "@core/store/types/admin/offer";
import { SET_EMPTY } from "@core/store/action-constants";
import { OfferEditAdvertisersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditAdvertisersAvailableList";
import { offerEditWebmastersList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersList";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { offerEditWebmastersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersAvailableList";
import { landingParametersList } from "@core/store/modules/admin/offer/lists/offerEditLandingParametersList";
import { offerTypesList } from "@core/store/modules/admin/lists/offerTypesList";
import { flowRedirectMacrosList } from "@core/store/modules/admin/lists/flowRedirectMacrosList";
import { OfferAvailableAdvertisers } from "@core/store/modules/admin/offer/lists/offerAvailableAdvertisers";

const initialState = (): OfferState => {
  return {
    offer: null
  };
};

const state: () => OfferState = initialState;

const mutations: MutationTree<OfferState> = {
  SET_EMPTY: state => Object.assign(state, initialState())
};

const actions: ActionTree<OfferState, RootState> = {
  SET_EMPTY ({ commit }) {
    commit(SET_EMPTY);
    commit("offerEditAdvertisers/SET_EMPTY");
  }
};

export const edit: Module<OfferState, RootState> = {
  namespaced: true,
  modules: {
    offerEditAdvertisersAvailableList: new OfferEditAdvertisersAvailableList(),
    offerAvailableAdvertisers: new OfferAvailableAdvertisers(),
    offerEditWebmastersAvailableList,
    offerEditWebmastersList,
    flowRedirectMacrosList,
    landingParametersList,
    withdrawTypesList,
    offerTypesList
  },
  state,
  mutations,
  actions
};
