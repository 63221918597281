import { OfferEditWebmastersListServices } from "@core/services/admin/offer/lists/OfferEditWebmastersListServices";
import {
  GET_INITIAL_WEBMASTERS,
  GET_WEBMASTERS_LIST,
  UPDATE_WEBMASTERS_LIST
} from "@core/store/action-constants";
import {
  EMPTY_WEBMASTERS_LIST, SET_EMPTY,
  UPDATE_WEBMASTERS_EXCLUDED_FILTERS,
  UPDATE_WEBMASTERS_FILTERS,
  UPDATE_WEBMASTERS_LIST_PAGINATION
} from "@core/store/mutation-constants";

import { RootState } from "@core/store/root-state";
import {
  PartialWebmastersListState, WebmastersListItem,
  WebmastersListResponse
} from "@core/store/types/admin/offer/lists/OfferEditWebmastersList.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialWebmastersListState => {
  return {
    data: null,
    filters: {
      offerId: null,
      webmasterId: null,
      loginOrIntId: null
    },
    excludedFilters: {
      id: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialWebmastersListState = initialState;

const getters: GetterTree<PartialWebmastersListState, RootState> = {};

const mutations: MutationTree<PartialWebmastersListState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [UPDATE_WEBMASTERS_LIST] (state, webmasters: WebmastersListResponse["webmasters"]) {
    if (state.pagination.page !== 1 && state?.data?.items && webmasters) {
      state.data.items = Array.from(new Set([...state.data.items, ...webmasters.items as []]));
    } else {
      state.data = webmasters;
    }
  },
  
  [UPDATE_WEBMASTERS_LIST_PAGINATION] (state) {
    const items = state.data?.items?.filter(item => item.webmasters);
    if (items && items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(items.length / state.pagination.perPage) + 1;
    }
  },
  
  [UPDATE_WEBMASTERS_FILTERS] (state, filters: PartialWebmastersListState["filters"]) {
    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_WEBMASTERS_EXCLUDED_FILTERS] (state, filters: PartialWebmastersListState["excludedFilters"]) {
    state.excludedFilters = { ...state.excludedFilters, ...filters };
  },
  
  [EMPTY_WEBMASTERS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialWebmastersListState, RootState> = {
  async [GET_WEBMASTERS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { offerId, webmasterId, ...otherFilters } = state.filters ?? {};

    const { data: { offer: { webmasters } } } =
      await OfferEditWebmastersListServices.getOfferWebmasters(
        {
          loginOrIntId: state.filters?.loginOrIntId,
          ...otherFilters
        },
        state.excludedFilters,
        limit,
        offset,
        offerId,
        webmasterId
      );
    const items = webmasters.items.map((el: { webmaster: WebmastersListItem }) => el.webmaster);
    commit(UPDATE_WEBMASTERS_LIST, { ...webmasters, items });
  },
  
  async [GET_INITIAL_WEBMASTERS] ({ commit, state }) {
    let webmasterId = state.filters?.webmasterId;
    const offerId = state.filters?.offerId;
    
    if (webmasterId) {
      webmasterId = Array.isArray(webmasterId) ? webmasterId : [webmasterId];
    }
    
    const limit = state.pagination.perPage;
    const { data: { offer, initialWebmasters } } =
      await OfferEditWebmastersListServices.getOfferWebmasters(
        {
          loginOrIntId: state.filters?.loginOrIntId
        },
        state.excludedFilters,
        limit,
        0,
        offerId,
        webmasterId
      );
    
    offer.webmasters.items = [...initialWebmasters?.webmasters?.items ?? [], ...offer.webmasters.items as []].map(el => el.webmaster);
    commit(UPDATE_WEBMASTERS_LIST, offer.webmasters);
  }
};

export const webmastersOfferList: Module<PartialWebmastersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
