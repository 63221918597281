import Service from "@core/services/common/Service";
import {
  DetailingListItem,
  PartialDetailingState,
  ResponseDetail
} from "@core/store/types/admin/statistic/detailing/detailingState";
import moment from "moment";
import leads from "@core/services/admin/statistic/detailing/graphql/leads.generator";

export class DetailingService extends Service {
  public static async getDetail (
    limit: number,
    offset: number,
    filters: PartialDetailingState["filters"],
    fields: string[],
    sort: string,
    order: string
  ): Promise<ResponseDetail> {
    try {
      const { data } = await this.api.get("", {
        params: leads(
          limit,
          offset,
          filters,
          fields,
          sort,
          order
        )
      });
      data.leads.items = this.prepareDetailData(data.leads.items);
      
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  private static prepareDetailData (items: DetailingListItem[]): any {
    return items.map((item: DetailingListItem) => {
      const {
        fields,
        calls,
        isCopied,
        firstDeposit,
        summaryDeposit,
        ...items
      } = item;
      const {
        clickId,
        domain,
        phone,
        additionalFields,
        fio,
        ip,
        ...labels
      } = fields;
      const sortCalls = calls?.sort((a: string, b: string) => moment(b).diff(moment(a)));
      return {
        ...items,
        ...labels,
        clickId,
        domain,
        phone,
        fio,
        ip,
        additionalFields,
        calls: sortCalls,
        firstDeposit: firstDeposit || { date: null, amount: null },
        summaryDeposit: summaryDeposit || { count: 0, total: 0 },
        isCopied,
        id: items.id
      };
    });
  }
}
