



































import { defineProps, defineEmits, withDefaults } from "@vue/runtime-dom";
import { ComponentPublicInstance, nextTick, ref, watchEffect } from "@vue/composition-api";
interface Props {
  groups?: string[];
  type: string;
  namespace: string;
  disabled?: boolean;
  autoOpen?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  groups: {
    key: "groups",
    required: false,
    type: Array,
    default: () => []
  },
  type: {
    key: "type",
    required: false,
    type: String,
    default: "is-info"
  },
  namespace: {
    key: "namespace",
    required: false,
    type: String,
    default: "common.enumList"
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  },
  autoOpen: {
    key: "autoOpen",
    required: false,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const currentGroup = ref<string | null>(null);
  const tooltip = ref<ComponentPublicInstance<any> | null>(null);
  function onResetCurrentGroup() {
    if (currentGroup.value) {
      currentGroup.value = null;
      emit("change", null);
    }
  }
  function selectGroup(group: string) {
    tooltip.value.$refs.tippy.tip.hide();
    currentGroup.value = group;
    emit("change", group);
  }
  function onGroupSelect() {
    if (currentGroup.value) {
      onResetCurrentGroup();
    } else if (props.groups.length === 1) {
      selectGroup(props.groups[0]);
    }
  }
  if (props.autoOpen !== undefined) {
    watchEffect(() => {
      if (props.autoOpen) {
        nextTick(() => {
          selectGroup(props.groups[0]);
        });
      }
    });
  }
  return {
    currentGroup,
    tooltip,
    selectGroup,
    onGroupSelect
  };
};
export default __sfc_main;
