



























































































































































































import OfferLogo from "@/components/Common/Output/OfferLogo.vue";
import OfferState from "@/components/Common/Offer/OfferState.vue";
import ColoredStatus from "@/components/Common/ColoredStatus.vue";
import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
import BlockBox from "@/components/Common/BlockBox.vue";
import LLoading from "@/components/Common/LLoading.vue";
import { useCallCentersOfferModal } from "@/stores/common/offer/modal/callCentersOfferModalStore";
import { WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { defaultFormatter, moment } from "@core/filters";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { formatEmptyString } from "@core/filters";
import { computed } from "@vue/composition-api";
import i18n from "@core/plugins/i18n";
import { storeToRefs } from "pinia";
import vuexStore from "@/store";
interface Props {
  offer: WebmasterOfferInterface | null;
  hasCallCenter: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  offer: {
    key: "offer",
    required: false,
    type: null,
    default: null
  },
  hasCallCenter: {
    key: "hasCallCenter",
    required: false,
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const role = computed(() => vuexStore.getters.role);
  const isPhotoUrl = computed(() => !props.offer?.photoUrl);
  const isLandingUrl = computed(() => !props.offer?.landingUrl);
  const isPartnerNetwork = computed(() => vuexStore.getters.isPartnerNetwork);
  const promoPhotoLabel = computed(() => i18n.t(`common.entity.offer.${!isPhotoUrl.value ? "photoUrl" : "noData"}`));
  const promoLandingLabel = computed(() => i18n.t(`common.entity.offer.${!isLandingUrl.value ? "landingUrl" : "noData"}`));
  const iconType = computed(() => {
    const types = {
      "PUBLIC": "globe",
      "PRIVATE": "lock",
      "HIDDEN": "user-lock"
    };
    // @ts-ignore
    return types[props.offer?.offerType ?? "PUBLIC"];
  });
  const dateCreatedAt = computed(() => props.offer?.createdAt ? moment(props.offer?.createdAt, "DD.MM.YYYY") : null);
  const isVisibleId = computed(() => {
    if (role.value === "webmaster") {
      return props.offer?.webmasterJoinedState === "JOINED";
    }
    return true;
  });
  const hasVideoOffer = computed(() => {
    const regex = /(\.mp4)/gi;
    return props.offer?.logo && regex.test(props.offer?.logo.url);
  });
  function coloredStatus(status: string): string {
    return status ? "is-success" : "is-danger";
  }
  function openCallCentersModal() {
    const store = useCallCentersOfferModal();
    const {
      isModalActive
    } = storeToRefs(store);
    isModalActive.value = true;
  }
  return {
    defaultFormatter,
    formatEmptyString,
    role,
    isPhotoUrl,
    isLandingUrl,
    isPartnerNetwork,
    promoPhotoLabel,
    promoLandingLabel,
    iconType,
    dateCreatedAt,
    isVisibleId,
    hasVideoOffer,
    coloredStatus,
    openCallCentersModal
  };
};
__sfc_main.components = Object.assign({
  BlockBox,
  OfferLogo,
  ColoredStatus,
  CanCopy,
  OfferState,
  LLoading
}, __sfc_main.components);
export default __sfc_main;
