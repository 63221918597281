import _debounce from "lodash/debounce";
import { watch } from "@vue/composition-api";

export function debounceUpdateByWatchedParams (updateParams: any, updated: any, immediate = false): void {
  if (immediate) {
    updated();
  }
  watch(
    (): string => JSON.stringify(updateParams),
    (value): void => _debounce(updated, 400, { trailing: true })(value)
  );
}