import { Filters } from "@core/store/modules/common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";
import {
  OfferEditAdvertisersAvailableService
} from "@core/services/admin/common/offer/OfferEditAdvertisersAvailableService";
import { useOfferEdit } from "../../../../../../../stores/admin/offer/offerEditStore";

export class OfferEditAdvertisersAvailableList extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput) => {
    const { id, ...otherFilters } = filters;
      
    const { data: { offer } } =
      await OfferEditAdvertisersAvailableService.getOfferAdvertisersByOffer(offset, limit, id, otherFilters);

    const store = useOfferEdit();
    const offerAdvertiser = store.offer?.advertiser;

    let items = offer.availableAdvertisers?.items;

    if (offerAdvertiser && !this.state.data?.items?.some(item => item.id === offerAdvertiser?.id)) {
      items = [...items, offerAdvertiser];
    }

    return {
      data: {
        items,
        count: offer.availableAdvertisers?.count
      }
      // initialData: {
      //   items: initialAdvertisers.availableAdvertisers?.items,
      //   count: initialAdvertisers.availableAdvertisers?.length
      // }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
