import { State as StateWebmasters } from "@core/store/types/admin/offer/webmasters";
import { OfferWebmastersService } from "@core/services/common/offer/webmasters";
import { OfferLandingsState } from "@core/store/types/common/offerLandings";
import { useOfferDetail } from "@/stores/admin/offer/offerStore";
import { defineStore, storeToRefs } from "pinia";
import store from "@/store";

export const useWebmastersByOffer = defineStore("admin/webmastersByOffer ", {
  state: (): StateWebmasters => ({
    data: null,
    filters: {
      webmasterId: []
    },
    offerId: null,
    pagination: {
      page: 1,
      perPage: 10
    }
  }),

  getters: {
    firstPeriod: (): OfferLandingsState["landings"] => store.state.analyticTargetAudience.filters.firstPeriod
  },

  actions: {
    async GET_OFFER_WEBMASTERS (): Promise<void> {
      const store = useOfferDetail();
      const { offerId } = storeToRefs(store);

      const { pagination: { page, perPage: limit }, filters, offerId: id } = this;
      const offset = (page - 1) * limit;

      const { data: { offer: { webmasters } } } = await OfferWebmastersService.getOfferWebmasters(id ?? offerId.value, filters, "admin", limit, offset);

      this.data = webmasters;
    }
  }
});
