import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { FinancesService } from "@core/services/advertiser/finances/FinancesService";
import { TransactionListState } from "@core/store/types/advertiser/finances";
import {
  SET_INCOMES,
  UPDATE_INCOMES_FILTERS
} from "@core/store/mutation-constants";
import { GET_INCOMES, SET_EMPTY } from "@core/store/action-constants";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { refillModal } from "@core/store/modules/advertiser/finances/modules/refillModal";
import { PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): TransactionListState => {
  return {
    transactions: null,
    filters: {
      datepicker: datepicker(),
      status: null,
      expenseItemId: null,
      paymentSystemId: null,
      currency: null
    }
  };
};

const state: () => TransactionListState = initialState;

const mutations: MutationTree<TransactionListState> = {
  [SET_INCOMES]: (state, transactions: TransactionListState["transactions"]): void => {
    state.transactions = transactions;
  },

  [UPDATE_INCOMES_FILTERS] (state, filter: TransactionListState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },

  [SET_EMPTY] (state, target?: keyof PartialDetailingState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<TransactionListState, RootState> = {
  async [GET_INCOMES] ({ state, commit }): Promise<void> {
    const { limit, offset } = pagination();
    const { datepicker, ...filters } = state.filters;
    
    const { advertiserTransactions } = await FinancesService.getTransactions(
      limit,
      offset,
      {
        ...datepicker,
        ...filters
      }
    );
      
    commit(SET_INCOMES, advertiserTransactions);
  },

  [UPDATE_INCOMES_FILTERS] ({ commit }, filter): void {
    commit(UPDATE_INCOMES_FILTERS, filter);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`paymentSystemsList/${ SET_EMPTY }`);
  }
};

export const refill: Module<TransactionListState, RootState> = {
  namespaced: true,
  modules: {
    paymentSystemsList,
    refillModal
  },
  state,
  actions,
  mutations
};