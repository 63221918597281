import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function webmasterRatesGenerator (
  limit: number,
  offset: number,
  offerId: string,
  filters: PartialDetailingState["filters"]
): ASTGeneratedOutput {
  const money = ["value", "currency"];
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("items/rate/value", "items/rate/currency");
    exclude.push("items/maximalAllowedRate/value", "maximalAllowedRate/rate/currency");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "individualRates",

    fields: [
      {
        items: [
          "id",
          "externalWebmasterId",
          "isLowerMinimalCommission",
          { rate: money },
          { maximalAllowedRate: money },
          {
            hold: [
              "holdMinutes"
            ]
          },
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "isBindedToCurrentAdmin"
            ]
          }
        ]
      },
      "count"
    ],

    variables: {
      filters: {
        type: "IndividualRateListCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      offerId: {
        type: "Uid!",
        value: offerId
      }
    }

  };

  return Generator.query(gqlObject, params);
}
