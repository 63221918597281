import {
  OfferLandingParameterType,
  OfferResponseCategoryInterface, OfferResponseDetailInterface,
  OfferResponseInterface
} from "@core/store/types/common/offer/offer";
import { CountryInterface, File, TrafficTypeInterface } from "@core/store/types/common";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { IsoCurrencyEnum } from "@core/store/types/common/enums/isoCurrencyEnum";
import { WithdrawTypeEnum } from "@core/store/types/common/enums/WithdrawTypeEnum";

export abstract class AbstractOffer implements OfferResponseInterface {
  public id?: string;
  public intId?: number;

  public name?: string;
  public description?: string;
  public rules?: OfferResponseDetailInterface["rules"];
  public isInHouse?: OfferResponseDetailInterface["isInHouse"];

  public withdrawType?: WithdrawTypeEnum | string;
  public totalTimeHold?: number;

  public landingPreCost?: number;
  public landingCost?: number;

  public categories?: Nullable<OfferResponseCategoryInterface[]>;
  public countries?: Nullable<CountryInterface[]>;
  public offerType?: string;
  public trafficTypes?: Nullable<TrafficTypeInterface[]>;
  public logo?: Nullable<File>;
  public previewLogo?: Nullable<File>;
  public createdAt?: Date;
  public photoUrl?: string;
  public labels?: OfferResponseInterface["labels"];
  public landingUrl?: string;
  public landingParameters?: Nullable<OfferLandingParameterType[]>;
  public vertical?: VerticalsEnum;
  public landingCurrency?: IsoCurrencyEnum;
  public trackingPageId?: string;

  protected constructor (payload?: OfferResponseInterface) {
    if (payload) {
      // @ts-ignore
      const { subGoalType, withdrawType } = payload.finance || {};

      this.id = payload.id;
      this.intId = payload.intId;

      this.labels = payload.labels;
      this.name = payload.detail?.name;
      this.description = payload.detail?.description;
      this.rules = payload.detail?.rules;
      this.isInHouse = payload.detail?.isInHouse;

      this.withdrawType = subGoalType ? `${ withdrawType }-${ subGoalType }` : withdrawType;
      this.totalTimeHold = payload.finance?.holdTime?.holdMinutes;

      this.landingPreCost = payload.landing?.preCost;
      this.landingCost = payload.landing?.cost;
      this.landingCurrency = payload.landing?.currency;
      this.trackingPageId = payload.landing?.trackingPageId;

      this.categories = payload.categories;
      this.countries = payload.countries;
      this.offerType = payload.type;
      this.trafficTypes = payload.trafficTypes;
      this.logo = payload.logo;
      this.previewLogo = payload.previewLogo;
      this.createdAt = payload.createdAt;
      this.photoUrl = payload.photoUrl;
      this.landingUrl = payload.landingUrl;
      this.landingParameters = payload.landingParameters;
      this.vertical = payload.vertical;
    }
  }
}
