<template>
    <ModalBox
        :is-active="isActive"
        :is-loading="isLoading"
        :on-cancel="closeModalConfirm"
        @submit="createNewComplaint">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.complaints.modal.title") }}
            </h5>
        </template>

        <FilterWrapper wrapper-class="is-fillwidth">
            <b-field :label="$t('common.entity.filters.offer')">
                <OffersSelect
                    v-model="offerId"
                    required
                    vertical
                    :placeholder="$t(`common.entity.filters.offer`)">
                </OffersSelect>
            </b-field>
        </FilterWrapper>

        <AccessControlFilter
            v-model="webmasterId"
            wrapper-class="is-fillwidth py-3"
            type="webmaster"
            required
            :placeholder="$t(`common.entity.filters.webmaster`)">
        </AccessControlFilter>

        <b-field :label="$t(`admin.complaints.modal.body.reason`)">
            <Select
                v-model="reason"
                :get-data-vuex="getReasons"
                :placeholder="$t(`admin.complaints.modal.body.reason`)"
                required
                class="white"
                field="label"
                prop="value">
            </Select>
        </b-field>

        <template v-if="filledUsers">
            <div class="flex mb-3">
                <span class="mr-2">
                    {{ $t("admin.complaints.modal.body.requiredApprove") }}:
                </span>
                <b-skeleton
                    v-if="isLoadingConversion"
                    size="is-medium"
                    :width="50">
                </b-skeleton>
                <template v-else>
                    {{ `${ requiredApprove || 0 }%` }}
                </template>
            </div>
            <div class="is-flex is-align-items-center">
                <CustomDatepicker
                    v-model="negativeDatepicker"
                    class="is-inline-block"
                    style="width: 100%"
                    :label="$t('admin.complaints.modal.body.negativeDatepicker')"
                    :periods="null"
                    disable-today
                    @change="GET_ANALYTIC_REQUIRED_APPROVE">
                </CustomDatepicker>

                <b-skeleton
                    v-if="isLoadingConversion"
                    size="is-medium ml-3 mt-2"
                    :width="105">
                </b-skeleton>
                <b-tag
                    v-else
                    type="is-success is-light"
                    class="ml-3 mt-2">
                    {{ formatEmptyString(fixedFormatPercent(negativeConversionApprove)) }}
                    {{ $t("admin.statistics.analytics.drawdownReason.body.labels.persentApprove") }}
                </b-tag>
            </div>
            <div class="is-flex is-align-items-center">
                <b-field
                    type="is-danger"
                    style="width: 100%;"
                    :message="hasInvalidForm ? $t('common.entity.errors.required') : null">
                    <CustomDatepicker
                        v-model="positiveDatepicker"
                        class="is-inline-block mb-0"
                        style="width: 100%"
                        :label="$t('admin.complaints.modal.body.positiveDatepicker')"
                        :periods="null"
                        disable-today
                        @change="GET_ANALYTIC_REQUIRED_APPROVE">
                    </CustomDatepicker>
                </b-field>

                <b-skeleton
                    v-if="isLoadingConversion"
                    :size="`is-medium ml-3 ${ hasInvalidForm ? 'mb-3' : 'mt-2' }`"
                    :width="105">
                </b-skeleton>
                <b-tag
                    v-else
                    type="is-success is-light"
                    class="ml-3"
                    :class="hasInvalidForm ? 'mb-3' : 'mt-2'">
                    {{ formatEmptyString(fixedFormatPercent(positiveConversionApprove)) }}
                    {{ $t("admin.statistics.analytics.drawdownReason.body.labels.persentApprove") }}
                </b-tag>
            </div>

            <b-field :label="$t(`admin.statistics.analytics.drawdownReason.body.labels.comment`)">
                <LInput
                    v-model="creatorComment"
                    maxlength="5000"
                    required
                    type="textarea">
                </LInput>
            </b-field>
        </template>

        <template #footer>
            <div class="flex is-justify-content-end">
                <b-button
                    :disabled="hasInvalidForm || !reason"
                    class="is-info is-fullwidth"
                    native-type="submit">
                    {{ $t(`common.buttons.send`) }}
                </b-button>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import {
    CREATE_TRAFFIC_COMPLAINT,
    GET_ANALYTIC_REQUIRED_APPROVE
  } from "@core/store/action-constants";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import { SET_EMPTY, UPDATE_INPUT, UPDATE_MODAL } from "@core/store/mutation-constants";
  import { ReasonComplaintsEnum } from "@core/store/types/admin/complaints/enums/ReasonComplaintsEnum";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { ReasonEnum } from "@core/store/types/admin/common/enums/ReasonEnum";
  import { fixedFormatPercent } from "@core/flowMethods";
  import { formatEmptyString } from "@core/filters";
  import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    name: "ComplaintsModal",
    components: { AccessControlFilter, OffersSelect, Select, CustomDatepicker, ModalBox },

    computed: {
      ...mapFields("admin/complaints/complaintsModal", {
        fields: ["isActive", "positiveConversionApprove", "negativeConversionApprove", "requiredApprove"],
        base: "modal",
        action: UPDATE_MODAL
      }),
        
      ...mapFields("admin/complaints/complaintsModal", {
        fields: ["offerId", "webmasterId", "reason", "positiveDatepicker", "negativeDatepicker", "creatorComment"],
        base: "input",
        action: UPDATE_INPUT
      }),

      ...mapGetters("admin/complaints/complaintsModal", ["filledUsers"]),

      isLoadingConversion () {
        return this.$wait(`admin/complaints/complaintsModal/${ GET_ANALYTIC_REQUIRED_APPROVE }`);
      },
        
      hasInvalidForm () {
        return this.reason === ReasonEnum.LOW_APPROVE &&
          this.positiveConversionApprove == null;
      },

      isLoading () {
        return this.$wait(`admin/complaints/complaintsModal/${ CREATE_TRAFFIC_COMPLAINT }`);
      }
    },

    methods: {
      ...mapActions("admin/complaints/complaintsModal", {
        CREATE_TRAFFIC_COMPLAINT,
        GET_ANALYTIC_REQUIRED_APPROVE
      }),

      ...mapMutations("admin/complaints/complaintsModal", {
        SET_EMPTY
      }),

      closeModalConfirm () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.modal.confirmationToCancel.title"),
          message: this.$t("common.entity.modal.confirmationToCancel.message"),
          confirmText: this.$t("common.entity.modal.confirmationToCancel.confirmText"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-danger is-light",
          onConfirm: () => {
            this.SET_EMPTY();
          }
        });
      },

      async createNewComplaint () {
        try {
          await actionWithToast(this.CREATE_TRAFFIC_COMPLAINT(), "admin.complaints.modal.messages");
        } finally {
          this.SET_EMPTY();
        }
      },

      getReasons () {
        const items = Object.values(ReasonComplaintsEnum).map(value => ({ value, label: this.$t(`common.entity.incidenceReason.${ value }`) }));
        return { items, count: items.length };
      },

      fixedFormatPercent,
      formatEmptyString
    },

    watch: {
      filledUsers () {
        if (this.filledUsers) {
          this.GET_ANALYTIC_REQUIRED_APPROVE();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .modal-card {
            width: 50vw;
        }

        .b-skeleton {
            width: auto;
        }
    }
</style>