import { CommissionsFiltersCommon, CommissionsState } from "@core/store/types/admin/commissions/commissions";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";
import { CommissionsService } from "@core/services/webmaster/commissions/CommissionsService";
import { Pagination } from "@core/store/types/common";
import { defineStore } from "pinia";

export function useSubAccountOffers (storeId: string) {
  return defineStore(`admin/capacityAndBumps/actualCapacity/sub/${ storeId }`, {
    state: (): CommissionsState => ({
      data: null,
      filters: {
        groups: [CommissionsGroupsEnum.OFFER],
        loginOrIntId: null,
        email: null,
        offerOfSubAccount: null
      },
      pagination: {
        page: 1,
        perPage: 20
      }
    }),

    getters: {
      onlyFilters: ({ filters: { groups, ...filters } }): CommissionsFiltersCommon => filters,
      groups: ({ filters: { groups } }): CommissionsGroupsEnum[] => Array.isArray(groups) ? groups : [groups],
      childGroup: () => (uniqueKey: string) => {
        const store = useSubAccountOffers(uniqueKey);
        return store.groups?.[0];
      }
    },

    actions: {
      async GET_SUB_ACCOUNT_OFFERS  (subAccountId: string[]) {
        const limit = this.pagination?.perPage || 20;
        const offset = ((this.pagination?.page || 1) - 1) * limit;
        const { groups, ...filters } = this.filters;

        this.data = await CommissionsService.getOfferCommissions(limit, offset, { id: subAccountId, ...filters }, this.groups);
      },

      async GET_SUB_ACCOUNT_OFFERS_WITH_PAGINATION (subAccountId: string[]) {
        this.UPDATE_PAGINATION({ page: this.pagination!.page + 1 });

        const limit = this.pagination?.perPage || 20;
        const offset = ((this.pagination?.page || 1) - 1) * limit;

        const { groups, ...filters } = this.filters;

        const data = await CommissionsService.getOfferCommissions(limit, offset, { id: subAccountId, ...filters }, this.groups);
        // @ts-ignore
        this.data = { count: data.count, items: this.data.items.concat(data.items) };
      },

      UPDATE_FILTERS (payload: Partial<CommissionsState["filters"]>) {
        this.filters = { ...this.filters, ...payload };
        this.UPDATE_PAGINATION({ page: 1 });
      },

      UPDATE_PAGINATION (payload: Partial<Pagination>) {
        this.pagination = { ...this.pagination, ...payload } as Pagination;
      }
    }
  })();
}