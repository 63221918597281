import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function getRequirements (offerId: string): ASTGeneratedOutput {
  const money = ["value", "currency"];
  let exclude = [] as string[];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude = [
      "minimalIndividualRate/value",
      "minimalIndividualRate/currency",
      "maximalIndividualRate/value",
      "maximalIndividualRate/currency",
      "minimalCommission/value",
      "minimalCommission/currency",
      "finance/rate/value",
      "finance/rate/currency"
    ];
  }

  const params = { exclude };

  const gqlObject = {
    operation: "offer",

    fields: [
      "totalSoftCapacity",
      "usedSoftCapacity",
      {
        finance: [
          { rate: money }
        ]
      },
      { minimalIndividualRate: money },
      { maximalIndividualRate: money },
      { minimalCommission: money }
    ],

    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      }
    }
  };

  return Generator.query(gqlObject, params);
}
