import { useOfferAdvertisersIndividualRates } from "@/stores/admin/offer/advertisersIndividualRatesStore";
import { OfferEditCommonService } from "@core/services/admin/offer/edit/OfferEditCommonServices";
import { OfferEditService } from "@core/services/admin/offer/edit/OfferEditService";
import { useOfferWebmastersRates } from "@/stores/admin/offer/webmastersRatesStore";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { hasPermissions } from "@core/mixins/permissions";
import { Offer } from "@core/logic/admin/offer/offer";
import { defineStore } from "pinia";
import vuexStore from "@/store";
import store from "@/store";

export interface OfferState {
  offer: Nullable<Offer>;
}

export const useOfferEdit = defineStore("admin/offer/edit", {
  state: (): OfferState => ({
    offer: null
  }),

  getters: {
    userPermissions: (): boolean => store.state.auth.userPermissions,
    hasVisibleIncludedAdvertisers (): boolean { return hasPermissions(["OFFERS.VIEW_INCLUDED_ADVERTISERS.ALL", "OFFERS.VIEW_INCLUDED_ADVERTISERS.OWN"], this.userPermissions); },
    hasVisibleIncludedWebmasters (): boolean { return hasPermissions(["OFFERS.VIEW_INCLUDED_WEBMASTERS.ALL", "OFFERS.VIEW_INCLUDED_WEBMASTERS.OWN"], this.userPermissions); },
    hasPayoutIndividualRates (): boolean { return hasPermissions(["OFFERS.MANAGE_PAYOUT.ALL", "OFFERS.MANAGE_PAYOUT.WEBMASTERS.OWN"], this.userPermissions); },
    hasEditOffer (): boolean { return hasPermissions(["OFFERS.EDIT"], this.userPermissions); },
    hasGambling: ({ offer }) => offer?.vertical === VerticalsEnum.GAMBLING,
    currencyList: () => vuexStore.state.auth?.availableCurrencies,
    offerVertical: ({ offer }) => offer?.vertical,
    offerIntId: ({ offer }) => offer?.intId,
    offerSlug: ({ offer }) => offer?.slug,
    offerId: ({ offer }) => offer?.id
  },

  actions: {
    async GET_OFFER (offerId: string): Promise<void> {
      if (offerId) {
        this.offer = await OfferEditService.getOffer(offerId, false) as unknown as Offer;
        this.UPDATE_OFFER_RATES_REQUIREMENTS();
      }
    },

    async PROXY_OFFER (): Promise<void> {
      if (this.offerId) {
        await this.UPDATE_OFFER();
      } else {
        await this.CREATE_OFFER();
      }
    },

    async CREATE_OFFER (): Promise<void> {
      const { data: { createOffer: { id, intId } } } = await OfferEditCommonService.createOffer(this.offer);
      // @ts-ignore
      this.offer = { ...this.offer, id, intId };
    },

    async CLONE_OFFER (offerId: string): Promise<void> {
      this.offer = await OfferEditService.getOffer(offerId, true) as unknown as Offer;
    },

    async UPDATE_OFFER (): Promise<void> {
      try {
        if (this.offerId && this.offer) {
          const offerWebmastersExternalRates = useOfferWebmastersRates("external");
          const offerWebmastersInternalRates = useOfferWebmastersRates("internal");

          const {
            id,
            intId,
            slug,
            advertiser,
            advertiserMinimalCommission,
            offerMinimalCommission,
            minimalIndividualRate,
            maximalIndividualRate,
            totalSoftCapacity,
            usedSoftCapacity,
            minAllowedPayout,
            maxAllowedRate,
            createdAt,
            ...offer
          } = this.offer;
          if (offer.trackingPageId === "") {
            offer.trackingPageId = null;
          }
          // @ts-ignore
          await OfferEditCommonService.updateOffer(this.offerId, offer);

          this.UPDATE_OFFER_RATES_REQUIREMENTS();
          
          if (this.hasPayoutIndividualRates) {
            this.UPDATE_MINIMAL_ALLOWED_REWARD();
            offerWebmastersInternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
            offerWebmastersExternalRates.UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
          }
        }
      } catch (e) {
        throw e;
      }
    },

    UPDATE_MINIMAL_ALLOWED_REWARD (): void {
      const storeAdvertisersExternalRates = useOfferAdvertisersIndividualRates();
      const storeAdvertisersInternalRates = useOfferAdvertisersIndividualRates("internal");

      storeAdvertisersExternalRates.GET_OFFER_WEBMASTERS_REWARD();
      storeAdvertisersInternalRates.GET_OFFER_WEBMASTERS_REWARD();
    },

    async UPDATE_OFFER_RATES_REQUIREMENTS (): Promise<void> {
      if (this.offerId) {
        const { data: { offer: { finance, ...offerRequirements } } } = await OfferEditService.getRequirements(this.offerId);
        const offerWithRequirements = {
          ...offerRequirements,
          minimalCommission: offerRequirements.minimalCommission.value,
          rate: finance.rate.value
        };
        this.offer = { ...this.offer, ...offerWithRequirements };
      }
    }
  }
});
