import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { ApproveCapacityRequestInput } from "@core/store/types/admin/offers/capacity";

export default function approvePayoutRequest (
  input: ApproveCapacityRequestInput,
  id: string,
  isShowCommission: boolean

): ASTGeneratedOutput {

  const include = [];
  if (isShowCommission) {
    include.push(
      "webmasterRate"
    );
  }
  const params = {
    include
  };

  const gqlObject = {
    operation: "approvePayoutRequest",

    fields: [
      "newPayoutValue",
      "executorComment",
      "status",
      "payoutUpdatedAt",
      "rateUpdatedAt",
      "creatorComment",
      "externalWebmaster",
      "goal",
      "reason",
      "updatedAt",
      "id",
      {
        executor: [
          "name",
          "id"
        ]
      }
    ],

    variables: {
      input: {
        type: "ApprovePayoutRequestInput!",
        value: input
      },
      id: {
        type: "Uid!",
        value: id
      }
    }
  };

  return Generator.mutation(gqlObject, params);
}