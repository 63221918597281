








































































































































































































import AnalyticTargetAudienceDiagram from "@/components/Common/Statistic/AnalyticTargetAudience/AnalyticTargetAudienceDiagram.vue";
import OfferAsideStyleComponent from "@/components/Common/Offer/OfferAsideStyleComponent.vue";
import OfferCallCentersModal from "@/components/Common/Offer/OfferCallCentersModal.vue";
import OfferWebmastersFilters from "@/components/Admin/Offer/OfferWebmastersFilters.vue";
import FlowsEditLinkModal from "@/components/Webmaster/Flows/FlowsEditLinkModal.vue";
import OfferWebmastersTable from "@/components/Common/Offer/OfferWebmastersTable.vue";
import OfferConditions from "@/components/Common/Offer/OfferConditions.vue";
import OfferLandings from "@/components/Common/Landings/OfferLandings.vue";
import OfferCommon from "@/components/Common/Offer/Offer.vue";
import Permissions from "@/components/Common/Permissions.vue";
import LLoading from "@/components/Common/LLoading.vue";
import BlockBox from "@/components/Common/BlockBox.vue";
import Card from "@/components/Common/Card.vue";
import { useOfferConversionList } from "@/stores/admin/offer/offerConversionListStore";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useWebmastersByOffer } from "@/stores/admin/offer/webmastersByOfferStore";
import { offerDisclaimerModal } from "@/stores/common/offer/OfferDisclaimerModal";
import { useOfferLandings } from "@/stores/admin/offer/offerLandingsListStore";
import { useOfferEdit } from "@/stores/admin/offer/offerEditStore";
import { computed, onUnmounted, ref } from "@vue/composition-api";
import { useOfferDetail } from "@/stores/admin/offer/offerStore";
import { useOffers } from "@/stores/admin/offers/offersStore";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { datepicker } from "@core/helpers/datepicker";
import { useWait } from "@/stores/common/waitStore";
import { storeToRefs } from "pinia";
import vuexStore from "@/store";
import router from "@/router";
interface Props {
  backLink: string | null;
}
const __sfc_main = {};
__sfc_main.props = {
  backLink: {
    key: "backLink",
    required: false,
    type: [String, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const webmastersByOffer = useWebmastersByOffer();
  const {
    data: webmastersList
  } = storeToRefs(webmastersByOffer);
  const offerDisclaimerStoreModal = offerDisclaimerModal();
  const {
    isActive
  } = storeToRefs(offerDisclaimerStoreModal);
  const offerLanding = useOfferLandings("landing");
  const {
    landings,
    pagination: landingPagination,
    filters: landingFilters
  } = storeToRefs(offerLanding);
  const offerTransit = useOfferLandings("transit");
  const {
    landings: transits,
    pagination: transitPagination,
    filters: transitFilters
  } = storeToRefs(offerTransit);
  const conversionListStore = useOfferConversionList();
  const {
    isShowCommissions
  } = useOffers();
  const offerEdit = useOfferEdit();
  const store = useOfferDetail();
  const {
    dataRedemptionGender,
    dataRedemptionAge,
    dataAgePercent,
    isCreatedOffer,
    isEditOffer,
    firstPeriod,
    dataGender,
    offerId,
    offer
  } = storeToRefs(store);
  const isReady = ref(true);
  const vuexWait = computed(() => vuexStore.getters["wait/getWait"]);
  const isLoadingAnalyticAudience = vuexWait.value("analyticTargetAudience/GET_ANALYTIC_TARGET_AUDIENCE");
  const isLoadingEditLinkModal = vuexWait.value("editLinkModal/GET_FLOW_PARAMETERS");
  const isLoadingConversion = useWait(conversionListStore, "GET_OFFER_CONVERSION");
  const isLoadingLandings = useWait(offerLanding, "GET_LANDINGS");
  const isLoadingTransits = useWait(offerTransit, "GET_LANDINGS");
  const hasWebmasters = computed(() => webmastersList?.value?.items?.length);
  const trafficTypes = computed(() => offer.value?.trafficTypes);
  const routeOfferId = computed(() => router.currentRoute.params.id);
  const routeOfferSlug = computed(() => router.currentRoute.params.slug);
  const routeOrOfferId = computed(() => routeOfferId.value ?? offerId.value);
  const hasGambling = computed(() => offer.value?.vertical === VerticalsEnum.GAMBLING);
  const landingsCount = computed(() => landings.value && landings.value.count > 10);
  const transitsCount = computed(() => transits.value && transits.value.count > 10);
  const offerEditRoute = computed(() => ({
    name: `admin:offers:${!isShowCommissions ? "landingsForm" : "edit"}`,
    params: {
      id: routeOrOfferId.value
    }
  }));
  const offerAsideStyleComponentOptions = computed(() => ({
    hasPromo: Boolean(offer.value?.landingUrl || offer.value?.photoUrl),
    trafficAmount: trafficTypes.value?.length ?? 0
  }));
  const dataCharts = computed(() => [{
    titleChart: "shareOrdersGender",
    data: dataGender.value[0]?.data
  }, {
    titleChart: "redemptionShareGender",
    data: dataRedemptionGender.value[0]?.data
  }, {
    titleChart: "shareOrdersAge",
    data: dataAgePercent.value[0]?.data
  }, {
    titleChart: "redemptionShareAge",
    data: dataRedemptionAge.value[0]?.data
  }]);
  const offerLikeGql = computed(() => {
    if (offer.value) {
      return {
        ...offer.value,
        finance: {
          rate: offer.value.rate,
          withdrawType: offer.value.withdrawType
        }
      };
    }
    return null;
  });
  initialData();
  async function initialData() {
    await getOffer();
    Promise.all([actionsWithLandings(), getAnalyticTargetAudience()]);
  }
  async function getOffer() {
    const dataSet = {
      offer: store.GET_OFFER,
      intId: store.GET_OFFER_INT_ID
    };
    await dataSet[routeOfferId.value ? "offer" : "intId"](routeOfferId.value ?? routeOfferSlug.value);
  }
  async function getAnalyticTargetAudience() {
    vuexStore.dispatch("analyticTargetAudience/UPDATE_FILTERS", {
      firstPeriod: datepicker({
        amount: 45,
        unit: "days",
        amountEnd: 15,
        unitEnd: "days"
      }),
      offerId: routeOrOfferId.value
    });
    await vuexStore.dispatch("analyticTargetAudience/GET_ANALYTIC_TARGET_AUDIENCE");
  }
  async function actionsWithLandings() {
    await Promise.all([offerLanding.GET_LANDINGS(routeOrOfferId.value), offerTransit.GET_LANDINGS(routeOrOfferId.value)]);
    landingFilters.value.isReady = transitFilters.value.isReady = isReady.value;
    await conversionListStore.GET_OFFER_CONVERSION(routeOrOfferId.value);
    offerLanding.UPDATE_OFFER_LANDINGS();
    offerTransit.UPDATE_OFFER_LANDINGS();
  }
  function cloneOffer() {
    offerEdit.CLONE_OFFER(routeOrOfferId.value);
  }
  async function infiniteHandlerTransits($state: any): Promise<void> {
    transitPagination.value.page = transitPagination.value.page + 1;
    await offerTransit.GET_LANDINGS(routeOrOfferId.value);
    $state.loaded();
  }
  async function infiniteHandlerLandings($state: any): Promise<void> {
    landingPagination.value.page = landingPagination.value.page + 1;
    await offerLanding.GET_LANDINGS(routeOrOfferId.value);
    $state.loaded();
  }
  function openEditLinkModal(options: any) {
    vuexStore.dispatch("editLinkModal/SET_OPTIONS", options);
    vuexStore.dispatch("editLinkModal/UPDATE_MODAL_ACTIVE", true);
  }
  onUnmounted(() => {
    store.$reset();
    offerTransit.$reset();
    offerLanding.$reset();
    webmastersByOffer.$reset();
    conversionListStore.$reset();
    vuexStore.dispatch("analyticTargetAudience/SET_EMPTY");
  });
  return {
    isActive,
    landings,
    landingPagination,
    transits,
    transitPagination,
    isShowCommissions,
    isCreatedOffer,
    isEditOffer,
    firstPeriod,
    dataGender,
    offer,
    isLoadingAnalyticAudience,
    isLoadingEditLinkModal,
    isLoadingConversion,
    isLoadingLandings,
    isLoadingTransits,
    hasWebmasters,
    trafficTypes,
    routeOrOfferId,
    hasGambling,
    landingsCount,
    transitsCount,
    offerEditRoute,
    offerAsideStyleComponentOptions,
    dataCharts,
    offerLikeGql,
    cloneOffer,
    infiniteHandlerTransits,
    infiniteHandlerLandings,
    openEditLinkModal
  };
};
__sfc_main.components = Object.assign({
  OfferCommon,
  Permissions,
  OfferWebmastersFilters,
  Card,
  OfferWebmastersTable,
  OfferLandings,
  AnalyticTargetAudienceDiagram,
  OfferCallCentersModal,
  FlowsEditLinkModal,
  BlockBox,
  LLoading,
  OfferAsideStyleComponent,
  OfferConditions
}, __sfc_main.components);
export default __sfc_main;
