






























































































































































































































import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
import CompactList from "@/components/Common/Lists/CompactList.vue";
import UserOutput from "@/components/Common/Output/UserOutput.vue";
import ColoredStatus from "@/components/Common/ColoredStatus.vue";
import BlockBox from "@/components/Common/BlockBox.vue";
import LLoading from "@/components/Common/LLoading.vue";
import { formatEmptyString } from "@core/filters";
import InfiniteLoading from "vue-infinite-loading";
import { defineProps, withDefaults, defineEmits } from "@vue/runtime-dom";
import { Landing } from "@core/store/types/admin/offer/OfferLandings";
import { File, LandingPage } from "@core/store/types/common";
import { computed } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";
import i18n from "@core/plugins/i18n";
import vuexStore from "@/store";
import momentjs from "moment";
interface Props {
  title: string | null;
  data: any;
  isLoading: boolean;
  isLoadingFile: boolean;
  checkable: boolean;
  checked: any;
  checkedFormatter: Function | null;
  labelName: string | null;
  labelLanguage: string | null;
  labelVisibility: string | null;
  labelLastFile: string | null;
  labelCurrentFile: string | null;
  date: string | null;
  unloading: boolean | null;
  offerConversion: string | null;
  stickyHeaders: boolean;
  pagination: any;
  showReady: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: true,
    type: [String, null]
  },
  data: {
    key: "data",
    required: false,
    type: null,
    default: () => {}
  },
  isLoading: {
    key: "isLoading",
    required: false,
    type: Boolean,
    default: false
  },
  isLoadingFile: {
    key: "isLoadingFile",
    required: false,
    type: Boolean,
    default: false
  },
  checkable: {
    key: "checkable",
    required: false,
    type: Boolean,
    default: false
  },
  checked: {
    key: "checked",
    required: false,
    type: null,
    default: () => []
  },
  checkedFormatter: {
    key: "checkedFormatter",
    required: false,
    type: [Function, null],
    default: ({
      id
    }: {
      id: string;
    }) => id
  },
  labelName: {
    key: "labelName",
    required: true,
    type: [String, null]
  },
  labelLanguage: {
    key: "labelLanguage",
    required: true,
    type: [String, null]
  },
  labelVisibility: {
    key: "labelVisibility",
    required: false,
    type: [String, null],
    default: null
  },
  labelLastFile: {
    key: "labelLastFile",
    required: false,
    type: [String, null],
    default: null
  },
  labelCurrentFile: {
    key: "labelCurrentFile",
    required: false,
    type: [String, null],
    default: null
  },
  date: {
    key: "date",
    required: false,
    type: [String, null],
    default: null
  },
  unloading: {
    key: "unloading",
    required: false,
    type: [Boolean, null],
    default: false
  },
  offerConversion: {
    key: "offerConversion",
    required: false,
    type: [String, null],
    default: null
  },
  stickyHeaders: {
    key: "stickyHeaders",
    required: false,
    type: Boolean,
    default: true
  },
  pagination: {
    key: "pagination",
    required: false,
    type: null,
    default: () => {}
  },
  showReady: {
    key: "showReady",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const checkedArray = computed({
    get: () => props.checked,
    // @ts-ignore
    set: array => emit("update:checked", array.map(props.checkedFormatter))
  });
  const isInfiniteLoading_ = computed(() => props.pagination.perPage * props.pagination.page < props.data.count);
  const isNotificationShown = computed(() => props.data.count > 0 && props.checked.length > 0);
  const isLoadingConversion = computed(() => {
    const vuexWait = computed(() => vuexStore.getters["wait/getWait"]);
    const isLoadingAdminOfferConversionList = vuexWait.value("analyticTargetAudience/GET_ANALYTIC_TARGET_AUDIENCE");
    return isLoadingAdminOfferConversionList || vuexWait.value("webmaster/offer/offerConversionList/GET_OFFER_CONVERSION") || vuexWait.value("advertiser/offer/offerConversionList/GET_OFFER_CONVERSION");
  });
  function infiniteHandler($state: any) {
    emit("infiniteHandler", $state);
  }
  function isAbleToPreview(row: LandingPage) {
    return row.previewLink && row.previewLink.length;
  }
  function coloredStatus(status: string): string {
    return status ? "is-success" : "is-danger";
  }
  function coloredStatusLastFile(lastFile: LandingPage["lastFile"]): {
    [key: string]: boolean;
  } | string {
    if (!lastFile) {
      return "is-danger";
    }
    return {
      "is-danger": lastFile.status === "failed",
      "is-warning": lastFile.status === "unprocessed",
      "is-success": lastFile.status === "succeeded"
    };
  }
  function formatLanguage(row: Landing): string {
    return formatEmptyString(row.language ? i18n.t(`common.languages.${row.language.alias}`) : null);
  }
  function isFailedStatus(row: LandingPage): boolean {
    return row.lastFile?.status === "failed";
  }
  function hasFile(file: File): TranslateResult {
    return file ? i18n.t(`common.entity.landings.table.values.status.${file.status}`) : i18n.t("common.entity.landings.table.values.noFile");
  }
  function reasonForFail(row: any): string {
    return row.lastFile?.reasonForFail.taskComment ?? i18n.t("common.errors.ERROR");
  }
  function formatDate(date: string): string {
    return momentjs(date).format("DD.MM.YYYY");
  }
  return {
    emit,
    checkedArray,
    isInfiniteLoading_,
    isNotificationShown,
    isLoadingConversion,
    infiniteHandler,
    isAbleToPreview,
    coloredStatus,
    coloredStatusLastFile,
    formatLanguage,
    isFailedStatus,
    hasFile,
    reasonForFail,
    formatDate
  };
};
__sfc_main.components = Object.assign({
  BlockBox,
  TablePlaceholder,
  ColoredStatus,
  InlineLoader,
  CompactList,
  UserOutput,
  InfiniteLoading,
  LLoading
}, __sfc_main.components);
export default __sfc_main;
