






































import OfferTrafficTypes from "@/components/Common/Offer/OfferTrafficTypes";
import OfferCommon from "@/components/Common/Offer/OfferCommon";
import { WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { TrafficTypeInterface } from "@core/store/types/common";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
import vuexStore from "@/store";
interface Props {
  offer: WebmasterOfferInterface | null;
  trafficTypes: TrafficTypeInterface[] | null;
  backLink: string | null;
  hasCallCenter: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  offer: {
    key: "offer",
    required: false,
    type: null,
    default: null
  },
  trafficTypes: {
    key: "trafficTypes",
    required: false,
    type: [Array, null],
    default: null
  },
  backLink: {
    key: "backLink",
    required: false,
    type: [String, null],
    default: null
  },
  hasCallCenter: {
    key: "hasCallCenter",
    required: false,
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const role = computed(() => vuexStore.getters.role);
  const _routerLink = computed(() => props.backLink || {
    name: `${role.value}:offers`
  });
  const hasTrafficTypes = computed(() => props.trafficTypes?.length);
  return {
    _routerLink,
    hasTrafficTypes
  };
};
__sfc_main.components = Object.assign({
  OfferCommon,
  OfferTrafficTypes
}, __sfc_main.components);
export default __sfc_main;
