<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoading"
        :on-cancel="() => closeModalConfirm()"
        @submit.stop.prevent="submit">
        <template #header>
            <slot name="title">
                <div
                    class="is-flex is-flex-direction-column">
                    <h5 class="title is-5">
                        {{ $t("admin.complaints.modal.titleFinalize") }}
                    </h5>
                    <div class="flex is-flex-wrap-wrap">
                        <div class="is-inline-block nowrap mr-2">
                            <span class="has-text-weight-bold mr-1">
                                {{ $t("admin.complaints.modal.body.complaintsId") }}:
                            </span>
                            {{ row.intId }}
                        </div>
                        <div class="is-inline-block nowrap mr-2">
                            <span class="has-text-weight-bold mr-1">
                                {{ $t("admin.complaints.modal.body.offer") }}:
                            </span>
                            <OfferOutput :offer="{...row.offer, name: row.offer.detail.name}"></OfferOutput>
                        </div>
                        <div class="is-inline-flex nowrap">
                            <span class="has-text-weight-bold mr-1">
                                {{ $t("admin.complaints.modal.body.webmaster") }}:
                            </span>
                            <UserOutput
                                :user="row.webmaster"
                                int-id
                                icon>
                            </UserOutput>
                        </div>
                    </div>
                    <div class="flex is-flex-wrap-wrap is-align-items-center">
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.positiveDatepicker") }}:
                        </span>
                        {{ convertPeriod([row.positiveStartDate, row.positiveEndDate]) }}
                        <b-tag
                            v-if="row.positiveConversion !== null"
                            class="ml-1"
                            type="is-success is-light">
                            {{ fixedFormatPercent(row.positiveConversion) }}%
                        </b-tag>
                    </div>
                    <div class="flex is-flex-wrap-wrap is-align-items-center">
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.negativeDatepicker") }}:
                        </span>
                        {{ convertPeriod([row.negativeStartDate, row.negativeEndDate]) }}
                        <b-tag
                            class="ml-1"
                            type="is-success is-light">
                            {{ fixedFormatPercent(row.negativeConversion) }}%
                        </b-tag>
                    </div>
                    <div>
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.capacity") }}:
                        </span>
                        {{ row.softCapacity }}
                    </div>
                    <div>
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.requiredApprove") }}:
                        </span>
                        {{ row.requiredApprove ? row.requiredApprove.requiredApprove + "%" : "-" }}
                    </div>
                </div>
            </slot>
        </template>
        <b-field :label="$t('admin.complaints.modal.body.status')">
            <Select
                v-model="status"
                :get-data-vuex="getStatuses"
                :placeholder="$t(`admin.complaints.modal.body.reason`)"
                required
                class="white"
                field="label"
                prop="value">
            </Select>
        </b-field>

        <b-field :label="$t('admin.complaints.modal.body.executorComment')">
            <LInput
                v-model="executorComment"
                maxlength="5000"
                :required="status !== StatusComplaintsEnum.NON_RELEVANT"
                type="textarea">
            </LInput>
        </b-field>
        <template #footer>
            <div class="flex is-justify-content-end">
                <b-button
                    :disabled="!status"
                    class="is-info is-fullwidth"
                    native-type="submit">
                    {{ $t(`common.buttons.send`) }}
                </b-button>
            </div>
        </template>
    </modalbox>
</template>

<script>
  import { StatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/StatusComplaintsEnum";
  import Select from "@/components/Common/Select/Select.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { formatEmptyString, moment } from "@core/filters";
  import { fixedFormatPercent } from "@core/flowMethods";

  export default {
    name: "ComplaintFinalizeModal",
    components: { UserOutput, OfferOutput, ModalBox, Select },

    props: {
      isActive: {
        type: Boolean,
        default: false
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      onCancel: {
        type: Function,
        default: null
      },

      row: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        status: null,
        executorComment: null
      };
    },

    computed: {
      StatusComplaintsEnum () {
        return StatusComplaintsEnum;
      },

      isModalActive: {
        get () {
          return this.isActive;
        },
        set (value) {
          if (!this.onCancel) {
            this.$emit("update:isActive", value);
          }
        }
      }
    },

    methods: {
      fixedFormatPercent,
      getStatuses () {
        const { DISPUTE_CALL_CENTER, TRAFFIC, NON_RELEVANT } = StatusComplaintsEnum;

        const items = [
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ DISPUTE_CALL_CENTER }`),
            value: DISPUTE_CALL_CENTER
          },
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ TRAFFIC }`),
            value: TRAFFIC
          },
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ NON_RELEVANT }`),
            value: NON_RELEVANT
          }
        ];

        return { items, count: items.length };
      },

      closeModalConfirm () {
        if (this.executorComment?.length) {
          this.$buefy.dialog.confirm({
            title: this.$t("common.entity.modal.confirmationToCancel.title"),
            message: this.$t("common.entity.modal.confirmationToCancel.message"),
            confirmText: this.$t("common.entity.modal.confirmationToCancel.confirmText"),
            cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
            type: "is-danger is-light",
            onConfirm: () => {
              this.isModalActive = false;
              this.status = null;
              this.executorComment = null;
            }
          });
        } else { this.isModalActive = false; }
      },

      submit () {
        this.$emit("close");
        const { status, executorComment } = this;
        this.$emit("submit", { status, executorComment });
      },

      convertPeriod ([start, end]) {
        return start ? `${ moment(start, "DD.MM.YYYY") } - ${ moment(end, "DD.MM.YYYY") }` : formatEmptyString(null);
      }
    },
    watch: {
      isActive () {
        this.status = null;
        this.executorComment = null;
      }
    }
  };
</script>

<style scoped>

</style>