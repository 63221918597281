import { UserFormInterface } from "@core/store/logic/webmaster/UserForm";
import { WebmasterProfileViewModal } from "@core/store/types/common/webmasterProfileViewModal";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

export class WebmasterForm implements UserFormInterface {
  public login?: string;
  public timeZone?: Nullable<string>;
  public skype?: string;
  public birthday?: Date | string;
  public categoriesPreferences?: string[];
  public trafficTypes?: string[];
  public isPartnerNetwork?: boolean;
  public label?: Nullable<string>;
  public agentType?: string;
  public isAgent?: boolean;
  public verticals?: string[];
  public comment?: string;
  public isTeam?: boolean;
  public isDomestic?: boolean;
  public email?: string;
  public telegram?: string;
  public whatsApp?: number;
  public viber?: number;
  public trust?: string;
  public teamName?: string;
  public residenceCity?: string;
  public residenceCountry?: string;
  public preferredVertical?: VerticalsEnum;

  constructor (user?: WebmasterProfileViewModal["data"]) {
    if (user) {
      this.timeZone = user.timeZone?.alias;
      this.telegram = user.contact?.telegram;
      this.email = user.contact?.email;
      this.categoriesPreferences = user.detail.categoriesPreferences?.map(({ id }) => id);
      this.trafficTypes = user.detail.trafficTypes?.map(({ id }) => id);
      this.login = user.login;
      this.skype = user.contact.skype ?? "";
      this.whatsApp = user.contact.whatsApp;
      this.viber = user.contact.viber;
      this.isPartnerNetwork = user.detail.isPartnerNetwork;
      this.agentType = user.agentType;
      this.label = user.detail.label;
      this.isDomestic = user.detail.isDomestic;
      this.teamName = user.detail.teamName;
      this.isTeam = user.detail.isTeam;
      this.trust = user.detail.trust?.alias;
      this.isAgent = user.subType === "AGENT";
      this.verticals = user.detail.verticals?.map(({ id }) => id);
      this.birthday = user.detail.birthday ?? null;
      this.comment = user.comment;
      this.preferredVertical = user.preferredVertical;
      this.residenceCity = user.detail.residenceCity?.id;
      this.residenceCountry = user.detail.residenceCountry?.id;
    }
  }
}
