import { ApproveCapacityRequestInput } from "@core/store/types/admin/offers/capacity";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export default function actualizePayoutRequest (
  input: ApproveCapacityRequestInput,
  id: string
): ASTGeneratedOutput {
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("webmasterRate");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "actualizePayoutRequest",

    fields: [
      { executor: ["name", "id"] },
      "executorComment",
      "updatedAt",
      "webmasterRate",
      "rateUpdatedAt",
      "payoutUpdatedAt",
      "externalWebmaster",
      "id"
    ],

    variables: {
      input: {
        type: "ActualizePayoutRequestInput!",
        value: input
      },
      id: {
        type: "Uid!",
        value: id
      }
    }

  };

  return Generator.mutation(gqlObject, params);
}