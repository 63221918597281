import { ComponentPublicInstance, computed, ref, watch } from "@vue/composition-api";
import {
  CapacityRequestEfficiencyGroups,
  CapacityRequestEfficiencyItems as Item
} from "@core/store/types/admin/capacityAndBumps/capacityRequestEfficiency";
import { StoreGeneric, storeToRefs } from "pinia";
import _camelCase from "lodash/camelCase";

export default function useDataTable<T extends StoreGeneric> (store: T, tableRef: ComponentPublicInstance<any> | null) {
  const { data, childGroup } = storeToRefs(store);

  // const computedGroups = computed(() => data.value?.items?.reduce((n: Item, { uniqueKey }: Item) => ({ ...n, [uniqueKey]: null }), {}));
  const currentGroups = ref();

  const countColumns = computed(() => tableRef.value?.$refs.lTable.columnCount);
  const totalRows = computed(() => tableRef.value?.$refs.lTable.total);
  const tableLabels = computed(() => tableRef.value?.$refs.lTable.newColumns.map(({ label }: { label: string }) => label));
  const tableFields = computed(() => tableRef.value?.$refs.lTable.newColumns.map(({ field }: { field: string }) => field ? _camelCase(field) : null));

  function reopenDetails (row: Item) {
    if (currentGroups.value[row.uniqueKey] !== childGroup.value(row.uniqueKey)) {
      setTimeout(() => tableRef.value?.$refs.lTable.openDetailRow(row), 0);
    }
  }

  function changeCurrentGroup (group: CapacityRequestEfficiencyGroups, uniqueKey: string, upperCase?: boolean) {
    currentGroups.value[uniqueKey] = upperCase ? group?.toUpperCase() : group;
  }

  const watchItems = watch(() => data.value?.items, (value) => {
    currentGroups.value = value.reduce((n: Item, { uniqueKey }: Item) => ({ ...n, [uniqueKey]: null }), {});
    watchItems();
  });

  return {
    currentGroups,
    countColumns,
    totalRows,
    tableLabels,
    tableFields,
    reopenDetails,
    changeCurrentGroup
  };
}