import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function leads (
  limit: number,
  offset: number,
  filters: PartialDetailingState["filters"],
  fields: string[],
  sort: string,
  order: string
): ASTGeneratedOutput {
  const money = ["value", "currency"];
  const exclude = [ ...fields?.filter(item => item !== "id").map((item: string) => `items/${ item }`) ];
  const { isShowCommissions } = useOffers();

  if (filters.vertical === VerticalsEnum.NUTRA) {
    exclude.push(
      "items/firstDeposit/date",
      "items/firstDeposit/amount",
      "items/summaryDeposit/count",
      "items/summaryDeposit/total"
    );
  }

  if (!isShowCommissions) {
    exclude.push(
      "items/reward/webmasterReward/value",
      "items/reward/webmasterReward/currency",
      "items/reward/advertiserWriteOff/value",
      "items/reward/advertiserWriteOff/currency"
    );
  }

  const params = { exclude };
  
  const gqlObject = {
    operation: "leads",
    
    fields: [
      {
        items: [
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "subType",
              "isBindedToCurrentAdmin"
            ]
          },
          "id",
          "status",
          "isCopied",
          {
            firstDeposit: [
              "date",
              "amount"
            ]
          },
          {
            summaryDeposit: [
              "count",
              "total"
            ]
          },
          "isDomonetizationLead",
          {
            flow: [
              "name"
            ]
          },
          {
            fields: [
              "clickId",
              "domain",
              "phone",
              "ip",
              "fio",
              "utm_campaign",
              "utm_content",
              "utm_medium",
              "utm_source",
              "utm_term",
              "phone",
              "additionalFields"
            ]
          },
          "remoteId",
          "createdAt",
          {
            offer: [
              "id",
              "slug",
              "intId",
              {
                detail: [
                  "name"
                ]
              }
            ]
          },
          {
            reward: [
              {
                advertiserWriteOff: money
              },
              {
                webmasterReward: money
              },
              "withdrawType",
              "unholdDate"
            ]
          },
          "calls",
          "callsCount",
          "comment",
          {
            country: [
              "id",
              "alpha2",
              "name"
            ]
          },
          "cancelReason",
          "spamReason"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
    
    variables: {
      filters: {
        type: "LeadListCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      sort: {
        type: "LeadListCriteriaSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }
    
  };
  
  return Generator.query(gqlObject, params);
}