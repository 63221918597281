









































































import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
import SubAccountOffers from "@/components/Webmaster/Select/SubAccountOffers.vue";
import LFilters from "@/components/Common/LFilters.vue";
import { CommissionsState } from "@core/store/types/admin/commissions/commissions";
import { useCommissions } from "@/stores/webmaster/commissions/comissionsStore";
import { useFiltersConfirm } from "@/stores/common/filtersConfirmStore";
import { computed, onUnmounted, ref } from "@vue/composition-api";
import { actionWithToast } from "@/helpers/actionWithToast";
import { DialogProgrammatic as Dialog } from "buefy";
import { useWait } from "@/stores/common/waitStore";
import { storeToRefs } from "pinia";
import i18n from "@core/plugins/i18n";
import vuexStore from "@/store";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useCommissions();
  const filtersStore = useFiltersConfirm();
  const isLoading = useWait(store, "GET_COMMISSIONS");
  const {
    filters
  } = storeToRefs(store);
  const {
    confirmFilters
  } = storeToRefs(filtersStore);
  const agentTypeProfile = computed(() => vuexStore.getters.agentType);
  const agentType = ref(agentTypeProfile.value);
  function onConfirmFilters({
    filters
  }: {
    filters: CommissionsState["filters"];
  }) {
    store.$patch({
      filters
    });
    store.GET_COMMISSIONS();
  }
  function changeAgentType(value: string) {
    const _agentTypeProfile = i18n.t(`webmaster.commissions.filters.agentType.value.${agentTypeProfile.value}`);
    const currentAgentType = i18n.t(`webmaster.commissions.filters.agentType.value.${agentType.value}`);
    Dialog.confirm({
      title: i18n.t("webmaster.commissions.confirm.title"),
      message: i18n.t("webmaster.commissions.confirm.message", {
        agentTypeProfile: _agentTypeProfile,
        currentAgentType
      }),
      confirmText: i18n.t("common.buttons.apply"),
      cancelText: i18n.t("common.buttons.cancel"),
      type: "is-info is-light",
      onConfirm: async () => {
        try {
          agentType.value = value;
          await actionWithToast(store.CHANGE_AGENT_TYPE(value), "webmaster.commissions.message.agentType");
          vuexStore.dispatch("GET_WHO_AM_I");
        } catch {
          agentType.value = agentTypeProfile.value;
        }
      },
      onCancel: () => {
        agentType.value = agentTypeProfile.value;
      }
    } as any);
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    store,
    isLoading,
    filters,
    confirmFilters,
    agentType,
    onConfirmFilters,
    changeAgentType
  };
};
__sfc_main.components = Object.assign({
  LFilters,
  SubAccountOffers,
  LabelWithAnnotation
}, __sfc_main.components);
export default __sfc_main;
