import { OfferResponseInterface } from "@core/store/types/common/offer/offer";
import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { OfferAdvertiser } from "@core/logic/admin/offer/offer";
import { Money } from "@core/store/types/common";

export class EditableOffer extends AbstractOffer {
  public minimalCommission?: Money["value"];
  public currency?: Money["currency"];
  public maxAllowedRate?: Money["value"];
  public minAllowedPayout?: Money["value"];
  public rate?: Money["value"];
  public payout?: Money["value"];
  public totalSoftCapacity?: number;
  public isApiAllowed?: boolean;
  public isExclusive?: boolean;
  public advertiserId?: string;
  public advertiser?: OfferAdvertiser;
  public isResale?: boolean;
  public isTest?: boolean;
  public advertiserMinimalCommission?: Money;
  public slug?: string;

  constructor (payload?: OfferResponseInterface) {
    super(payload);
    if (payload) {
      this.landingParameters = payload.landingParameters ?? ["name", "phone"];
      this.categories = payload.categories?.map(type => type.id) as OfferResponseInterface["categories"];
      this.trafficTypes = payload.trafficTypes?.map(type => type.id) as OfferResponseInterface["trafficTypes"];
      this.countries = payload.countries?.map(type => type.id) as OfferResponseInterface["countries"];
      this.minimalCommission = payload.minimalCommission?.value;
      this.currency = payload.finance?.currency ?? "USD";
      this.slug = payload.slug;
      this.isApiAllowed = payload.isApiAllowed ?? true;
      this.isTest = payload.labels?.includes("TEST") || false;
      this.isExclusive = payload.labels?.includes("EXCLUSIVE") || false;
      this.isResale = payload.labels?.includes("RESALE") || false;
      this.rate = payload.finance?.rate?.value;
      this.payout = payload.finance?.payout?.value;
      this.maxAllowedRate = payload.finance?.maxAllowedRate?.value;
      this.minAllowedPayout = payload.finance?.minAllowedPayout?.value;
      this.advertiserId = payload.advertiser?.id;
      this.advertiser = payload.advertiser;
      this.totalSoftCapacity = payload.totalSoftCapacity;
      this.totalTimeHold = payload.finance?.holdTime?.holdMinutes ?? 15;
      this.advertiserMinimalCommission = payload.advertiserMinimalCommission;
    }
  }
}

