






























































import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";
import { defineProps, withDefaults, defineEmits } from "@vue/runtime-dom";
import { ComponentPublicInstance, computed, onBeforeUnmount, ref, watch, WatchStopHandle } from "@vue/composition-api";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
interface Props {
  fields: {
    label: string;
    value: string;
  }[];
  typeRadio: string;
  defaultSort: string[];
  columnSort: string | null;
  icon: string;
  iconSize: string;
  iconClass: string;
}
const __sfc_main = {};
__sfc_main.props = {
  fields: {
    key: "fields",
    required: true,
    type: Array
  },
  typeRadio: {
    key: "typeRadio",
    required: false,
    type: String,
    default: "is-info"
  },
  defaultSort: {
    key: "defaultSort",
    required: false,
    type: Array,
    default: () => []
  },
  columnSort: {
    key: "columnSort",
    required: false,
    type: [String, null],
    default: null
  },
  icon: {
    key: "icon",
    required: false,
    type: String,
    default: "sort"
  },
  iconSize: {
    key: "iconSize",
    required: false,
    type: String,
    default: "is-small"
  },
  iconClass: {
    key: "iconClass",
    required: false,
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const sort = ref<string | null>(null);
  const order = ref<string | null>(null);
  const instanceSortAndOrderWatch = ref<WatchStopHandle | null>(null);
  const tooltip = ref<ComponentPublicInstance<InstanceType<typeof LTooltip>>>(null);
  const sortAndOrder = computed(() => sort.value && order.value ? `${sort.value} ${order.value}` : null);
  const isAsc = computed(() => (order.value ?? props.defaultSort[1])?.toUpperCase() !== OrderEnum.DESC);
  const hasSortIcon = computed(() => props.icon === "sort");
  const isSorted = computed(() => props.defaultSort[0] === props.columnSort);
  const customIcon = computed(() => hasSortIcon.value ? isSorted.value ? isAsc.value ? "sort-up" : "sort-down" : "sort" : props.icon);
  function updateSorting() {
    emit("update:sorting", sort.value, order.value?.toUpperCase());
  }
  function created() {
    const isSortMatch = props.fields.some(({
      value
    }) => value === props.defaultSort[0]);
    if (isSortMatch) {
      sort.value = props.defaultSort[0];
      order.value = props.defaultSort[1];
    }
  }
  function mounted() {
    /** Смотрит за сортировкой после присвоения значений */
    instanceSortAndOrderWatch.value = watch(sortAndOrder, () => {
      updateSorting();
      tooltip.value.$refs.tippy.tip.hide();
    });
  }
  function destroy() {
    instanceSortAndOrderWatch.value?.();
    sort.value = null;
    order.value = null;
  }
  onBeforeUnmount(() => {
    /** Уничтожаем вотчер перед уничтожением компонента */
    instanceSortAndOrderWatch.value?.();
  });
  return {
    OrderEnum,
    sort,
    order,
    tooltip,
    isAsc,
    customIcon,
    created,
    mounted,
    destroy
  };
};
__sfc_main.components = Object.assign({
  LTooltip
}, __sfc_main.components);
export default __sfc_main;
