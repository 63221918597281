import Service from "@core/services/common/Service";
import agentCommissions from "./graphql/agentCommissions.graphql";
import subAccountOffers from "./graphql/subAccountOffers.graphql";
import changeAgentTypeMutation from "./graphql/changeAgentType.graphql";
import setCommissionToSubAccount from "./graphql/setCommissionToSubAccount.graphql";
import {
  CommissionsFiltersCommon,
  CommissionsState, SetCommissionToSubAccountInput
} from "@core/store/types/admin/commissions/commissions";
import { commissionsDataMapper } from "@core/services/webmaster/commissions/dataSet/commissionsDataMapper";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";

export class CommissionsService extends Service {
  public static async getCommissions (
    limit: number,
    offset: number,
    filters: CommissionsFiltersCommon,
    groups: CommissionsGroupsEnum[]
  ): Promise<CommissionsState["data"]> {
    try {
      const { data: { agentCommissions: data } } = await this.api.get("", {
        params: {
          query: agentCommissions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });

      return commissionsDataMapper(data, groups[0]);
    } catch (e) {
      throw e;
    }
  }

  public static async getOfferCommissions (
    limit: number,
    offset: number,
    filters: CommissionsFiltersCommon,
    groups: CommissionsGroupsEnum[]
  ): Promise<CommissionsState["data"]> {
    const { offerOfSubAccount, ...otherFilters } = filters;
    try {
      const { data: { agentCommissions: { items } } } = await this.api.get("", {
        params: {
          query: subAccountOffers,
          variables: {
            limit,
            offset,
            filters: otherFilters,
            offerFilters: { id: offerOfSubAccount }
          }
        }
      });

      return commissionsDataMapper(items?.[0]?.offers ?? { items: [], count: 0 }, groups[0]);
    } catch (e) {
      throw e;
    }
  }

  public static async changeAgentType (type: string) {
    try {
      await this.api.get("", {
        params: {
          query: changeAgentTypeMutation,
          variables: { type }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async setCommissionToSubAccount (input: SetCommissionToSubAccountInput) {
    try {
      const { data: { setCommissionToSubAccount: data } } = await this.api.get("", {
        params: {
          query: setCommissionToSubAccount,
          variables: { input }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}