import { CapacityAnalyseFilters } from "@core/store/types/admin/offers/capacity";
import { datepickerFormat } from "@core/helpers/datepicker";

export class ExternalWebmastersAnalyseFiltersDTO {
  public dateStart: string;
  public dateEnd: string;
  public withExternalWebmasters: boolean;
  public externalWebmasterId: Nullable<string>;
    
  constructor (filters: CapacityAnalyseFilters) {
    const { dateStart, dateEnd } = datepickerFormat(filters.datepicker, "date");
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.withExternalWebmasters = filters.withExternalWebmasters;
    this.externalWebmasterId = filters.withExternalWebmasters ? filters.externalWebmasterId : null;
  }
}