import { FlowsService } from "@core/services/webmaster/flows/FlowsService";
import {
  SET_EMPTY,
  GET_FLOWS,
  GET_FLOWS_WITH_DOMAINS,
  GET_DOMAINS_BY_NAME
} from "@core/store/action-constants";
import {
  SET_FLOWS,
  UPDATE_FLOWS_DOMAINS,
  UPDATE_FLOWS_FILTERS,
  SET_DOMAINS_BY_NAME
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Flow, FlowsFilters, FlowsResponse, FlowsState } from "@core/store/types/webmaster/flows";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/webmaster/domains/domainsService";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { pagination } from "@core/helpers/pagination";
import Vue from "vue";

const initialState = (): FlowsState => {
  return {
    flows: null,
    filters: {
      offerId: null
    }
  };
};

const state: () => FlowsState = initialState;

const mutations: MutationTree<FlowsState> = {
  [SET_FLOWS] (state, flows): void {
    state.flows = flows;
  },

  [UPDATE_FLOWS_FILTERS] (state: FlowsState, filters: FlowsFilters): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_DOMAINS_BY_NAME] (state: FlowsState, { isHttps, id }): void {
    if (state.flows && state.flows.items) {
      const flowsIndex = state.flows.items.findIndex((flows: Flow) => flows.id === id);
      Vue.set(state.flows.items, flowsIndex, { ...state.flows.items[flowsIndex], isHttps });
    }
  },

  [SET_EMPTY] (state, target?: keyof FlowsState): void {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<FlowsState, RootState> = {
  async [GET_FLOWS] ({ commit, state }): Promise<void> {
    const { limit, offset } = pagination();

    const landingFlows = await FlowsService.getFlows(limit, offset, state.filters);

    commit(SET_FLOWS, landingFlows);
  },

  async [GET_FLOWS_WITH_DOMAINS] ({ dispatch }): Promise<void> {
    await dispatch(GET_FLOWS);
    dispatch(UPDATE_FLOWS_DOMAINS);
  },
  
  [UPDATE_FLOWS_DOMAINS] ({ state, dispatch }): void {
    state.flows.items?.forEach((item: Flow) => {
      const name = item.options?.webmasterDomain;
      if (name) {
        dispatch(GET_DOMAINS_BY_NAME, { name, id: item.id });
      }
    });
  },

  async [GET_DOMAINS_BY_NAME] ({ commit }, { name, id }): Promise<void> {
    const filters = { strictName: name, includeSystemDomains: true };
    const { data: { landingDomains } } = await DomainsService.getDomains(filters, 1, 0);
    const isHttps = landingDomains.items?.[0]?.isHttps;
    
    commit(SET_DOMAINS_BY_NAME, { isHttps, id });
  },

  [UPDATE_FLOWS_FILTERS] ({ commit }, filters): void {
    commit(UPDATE_FLOWS_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string) {
    commit(SET_EMPTY, target);
  }
};
export const flows: Module<FlowsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
