<template>
    <ModalBox
        :is-active="isActive"
        :is-loading="isLoading"
        @close="SET_EMPTY"
        @submit="!!comment && createNewComment()">
        <template #header>
            <div class="is-flex is-flex-direction-column">
                <h5 class="title is-5 mb-2">
                    {{ $t("admin.complaints.modal.titleChat") }}
                </h5>
                <div>
                    <div class="is-inline-block nowrap mr-2">
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.complaintsId") }}:
                        </span>
                        {{ intId }}
                    </div>
                    <div class="is-inline-block nowrap mr-2">
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.offer") }}:
                        </span>
                        <OfferOutput :offer="offer"></OfferOutput>
                    </div>
                    <div class="is-inline-flex nowrap">
                        <span class="has-text-weight-bold mr-1">
                            {{ $t("admin.complaints.modal.body.webmaster") }}:
                        </span>
                        <UserOutput
                            icon
                            :user="webmaster"
                            int-id>
                        </UserOutput>
                    </div>
                </div>
                <div>
                    <span class="has-text-weight-bold mr-1">
                        {{ $t("admin.complaints.modal.body.positiveDatepicker") }}:
                    </span>
                    {{ convertPeriod(positivePeriod) }}
                    <b-tag
                        v-if="positivePeriod[0]"
                        class="ml-1"
                        type="is-success is-light">
                        {{ positiveConversion }}%
                    </b-tag>
                </div>
                <div>
                    <span class="has-text-weight-bold mr-1">
                        {{ $t("admin.complaints.modal.body.negativeDatepicker") }}:
                    </span>
                    {{ convertPeriod(negativePeriod) }}
                    <b-tag
                        class="ml-1"
                        type="is-success is-light">
                        {{ negativeConversion }}%
                    </b-tag>
                </div>
                <div>
                    <span class="has-text-weight-bold mr-1">
                        {{ $t("admin.complaints.modal.body.capacity") }}:
                    </span>
                    {{ softCapacity }}
                </div>
                <div>
                    <span class="has-text-weight-bold mr-1">
                        {{ $t("admin.complaints.modal.body.requiredApprove") }}:
                    </span>
                    {{ requiredApprove ? requiredApprove + "%" : "-" }}
                </div>
            </div>
        </template>

        <LChat
            :view-only="status !== StatusComplaintsEnum.DISPUTE_CALL_CENTER"
            :data="complaintComments"
            :max-length="5000"
            :sending="isSending"
            @send="createNewComment">
        </LChat>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { SET_EMPTY, UPDATE_INPUT } from "@core/store/mutation-constants";
  import { ADD_TRAFFIC_COMPLAINT_COMMENT, GET_TRAFFIC_COMPLAINT_COMMENTS } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
  import { formatEmptyString, moment } from "@core/filters";
  import { fixedFormatPercent } from "@core/flowMethods";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import LChat from "@/components/Common/Chat/LChat.vue";
  import { StatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/StatusComplaintsEnum";

  export default {
    name: "ComplaintsChatModal",
    components: { UserOutput, OfferOutput, ModalBox, LChat },

    created () {
      this.GET_TRAFFIC_COMPLAINT_COMMENTS();
    },

    computed: {
      StatusComplaintsEnum () {
        return StatusComplaintsEnum;
      },
      ...mapFields("admin/complaints/complaintsChatModal", {
        fields: ["comment"],
        base: "input",
        action: UPDATE_INPUT
      }),
        
      ...mapState("admin/complaints/complaintsChatModal", {
        offer: ({ form: { offer } }) => ({ ...offer, name: offer.detail.name }),
        webmaster: ({ form }) => form.webmaster,
        isActive: ({ isActive }) => isActive,
        complaintComments: ({ complaintComments }) => complaintComments,
        intId: ({ form }) => form.intId,
        status: ({ form }) => form.status,
        positivePeriod: ({ form }) => form.positivePeriod,
        negativePeriod: ({ form }) => form.negativePeriod,
        softCapacity: ({ form }) => form.softCapacity,
        requiredApprove: ({ form }) => form.requiredApprove?.requiredApprove,
        positiveConversion: ({ form: { conversions } }) => fixedFormatPercent(conversions[0]),
        negativeConversion: ({ form: { conversions } }) => fixedFormatPercent(conversions[1])
      }),

      ...mapState("admin/profile", {
        selfId: ({ user }) => user.id
      }),

      isLoading () {
        return this.$wait(`admin/complaints/complaintsChatModal/${ GET_TRAFFIC_COMPLAINT_COMMENTS }`);
      },
      isSending () {
        return this.$wait(`admin/complaints/complaintsChatModal/${ ADD_TRAFFIC_COMPLAINT_COMMENT }`);
      }
    },
      
    methods: {
      formatEmptyString,
      ...mapActions("admin/complaints/complaintsChatModal", {
        ADD_TRAFFIC_COMPLAINT_COMMENT,
        GET_TRAFFIC_COMPLAINT_COMMENTS
      }),

      ...mapMutations("admin/complaints/complaintsChatModal", {
        SET_EMPTY
      }),

      async createNewComment (comment) {
        this.comment = comment;
        await actionWithToast(this.ADD_TRAFFIC_COMPLAINT_COMMENT(), "admin.complaints.modal.messagesChat");
        this.comment = null;
      },

      convertPeriod ([start, end]) {
        return start ? `${ moment(start, "DD.MM.YYYY") } - ${ moment(end, "DD.MM.YYYY") }` : formatEmptyString(null);
      },

      moment
    }
  };
</script>

<style scoped lang="scss">
    $color-primary: #fbf1df;

    ::v-deep {
        .modal-card {
            width: 100%;
            max-width: 800px;

            &-head {
                font-size: .85rem;
                padding-bottom: 20px;
            }

            &-foot {
                padding: 0;
            }
        }
    }
</style>