





















import CallCentersTable from "@/components/Common/Offer/CallCentersTable.vue";
import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { useCallCentersOfferModal } from "@/stores/common/offer/modal/callCentersOfferModalStore";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { useWait } from "@/stores/common/waitStore";
import { computed } from "@vue/composition-api";
import { storeToRefs } from "pinia";
import vuexStore from "@/store";
interface Props {
  routeOrOfferId: string | null;
}
const __sfc_main = {};
__sfc_main.props = {
  routeOrOfferId: {
    key: "routeOrOfferId",
    required: false,
    type: [String, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = useCallCentersOfferModal();
  const {
    isModalActive,
    callCenters
  } = storeToRefs(store);
  const isLoading = useWait(store, "GET_CALLCENTERS");
  const userTimeZone = computed(() => vuexStore.getters.timezone);
  function changeTab(activeTab: string) {
    store.GET_CALLCENTERS({
      filters: {
        offers: props.routeOrOfferId
      },
      timeZone: activeTab ? null : userTimeZone.value?.offset
    });
  }
  return {
    store,
    isModalActive,
    callCenters,
    isLoading,
    changeTab
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  CallCentersTable
}, __sfc_main.components);
export default __sfc_main;
