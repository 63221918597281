import { webmastersFilters } from "@core/store/modules/admin/offer/lists/webmastersFilters";
import { edit } from "@core/store/modules/admin/offer/modules/edit";
import { OfferState } from "@core/store/types/admin/offer";
import { RootState } from "@core/store/root-state";
import { Module } from "vuex";

export const AdminOffer: Module<OfferState, RootState> = {
  namespaced: true,
  modules: {
    edit,
    webmastersFilters
  }
};
