<template functional>
    <span :class="{ 'is-align-items-center': props.icon }">
        <template v-if="props.icon">
            <img
                v-if="props.user && props.user.subType"
                :src="$options.iconBySubType(props.user && props.user.subType)"
                alt="user"
                class="user-img mr-1">
            <template v-else>
                -
            </template>
        </template>
        <span
            v-if="props.user && props.user.login && props.user.intId"
            class="nowrap"
            style="line-height: 1">
            <template v-if="props.intId && props.login">
                {{ $options.methods.formatEmptyString(props.user.login) }}
                [{{ $options.methods.formatEmptyString(props.user.intId) }}]
            </template>
            <template v-else>
                <template v-if="!props.intId">
                    {{ $options.methods.formatEmptyString(props.user.login) }}
                </template>
                <template v-if="!props.login && !props.intId">
                    [{{ $options.methods.formatEmptyString(props.user.intId) }}]
                </template>
                <template v-if="props.intId">
                    {{ $options.methods.formatEmptyString(props.user.intId) }}
                </template>
            </template>
        </span>
        <span v-else>
            {{ $options.methods.formatEmptyString(null) }}
        </span>
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";

  export default {
    name: "UserOutput",

    props: {
      user: {
        type: Object,
        default: null
      },
      login: {
        type: Boolean,
        default: false
      },
      intId: {
        type: Boolean,
        default: false
      },
      icon: {
        type: Boolean,
        default: false
      }
    },
    iconBySubType (subType) {
      return require(`@/assets/icons/${ subType?.toLowerCase() }.svg`);
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped lang="scss">
  .user-img {
    width: 16px;
    height: 16px;
  }
</style>
