import { OfferFiltersInput, UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import offerWithMinimalCommission from "@core/services/common/lists/graphql/offerWithMinimalCommission.graphql";
import offers from "@core/services/common/lists/graphql/offers.graphql";
import Service from "@core/services/common/Service";
import storeInstance from "../../../../../store";
import { AxiosPromise } from "axios";

export class OffersService extends Service {
  public static getOffersFilters (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialOffers?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialOffersLimit = initialOffers?.id?.length || 0;
      
      return this.api.get("", {
        params: {
          query: storeInstance.state.auth.role === "admin" ? offerWithMinimalCommission : offers,
          variables: {
            limit,
            offset,
            filters,
            initialOffers,
            initialOffersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
