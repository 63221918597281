import {
  CommissionsItem,
  CommissionsState
} from "@core/store/types/admin/commissions/commissions";
import _uniqueId from "lodash/uniqueId";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";

export function commissionsDataMapper (data: NonNullable<CommissionsState["data"]>, group: CommissionsGroupsEnum) {
  return {
    count: data.count,
    items: data.items?.map(dataMapper.bind(group)),
    aggregates: data.aggregates
  };
}

function dataMapper (this: CommissionsGroupsEnum, { offer, ...item }: CommissionsItem) {
  return {
    ...item,
    commission: Array.isArray(item.commission) ? item.commission.filter(el => item.currencies.includes(el.currency)) : item.commission,
    offer: offer && {
      ...offer,
      name: offer?.detail.name
    },
    uniqueKey: _uniqueId(`${ this }_`)
  };
}