import { SubWebmastersFilters } from "@core/store/types/admin/offers/capacity";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { CapacitySortEnum } from "@core/store/types/admin/capacity/enums/CapacitySortEnum";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";

export default function subWebmasterCapacity (
  limit: number,
  offset: number,
  agentId: string,
  offerId: string,
  filters: SubWebmastersFilters,
  sort: CapacitySortEnum,
  order: OrderEnum,
  isIndividualRate: boolean,
  isShowRedemption: boolean,
  isShowCommission: boolean
): ASTGeneratedOutput {
  const include = [];

  if (isShowRedemption) {
    include.push("items/statisticCapacity/monthRedemption");
  }

  if (isShowCommission) {
    include.push(
      "items/payoutRequest/webmasterRate",
      "items/capacityRequest/newCapacityValue",
      "items/capacityRequest/oldCapacityValue"
    );
  }

  if (isIndividualRate && isShowCommission) {
    include.push(
      "items/webmasterIndividualRate/value",
      "items/webmasterIndividualRate/currency"
    );
  }

  const params = { include };

  const moneyType = ["value", "currency"];
  const userType = ["name", "id"];

  const gqlObject = {
    operation: "subWebmastersCapacity",

    fields: [
      {
        items: [
          {
            requiredApprove: [
              "requiredApprove",
              "approveUpdatedAt: updatedAt"
            ]
          },
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "subType",
              "isBindedToCurrentAdmin"
            ]
          },
          {
            capacityRequest: [
              "status",
              {
                creator: userType
              },
              {
                executor: userType
              },
              "creatorComment",
              "newCapacityValue",
              "executorComment",
              "createdAt",
              "updatedAt",
              "id"
            ]
          },
          {
            bookingCapacityRequest : [
              "newCapacityValue",
              "status",
              {
                creator: userType
              },
              {
                executor: userType
              },
              "createdAt",
              "updatedAt",
              "id"
            ]
          },
          {
            payoutRequest: [
              "createdAt",
              "updatedAt",
              {
                creator: userType
              },
              "creatorComment",
              {
                executor: userType
              },
              "executorComment",
              "externalWebmaster",
              "reason",
              "goal",
              "oldPayoutValue",
              "newPayoutValue",
              "status",
              "id"
            ]
          },
          "softCapacity",
          "bookingCapacity",
          "rateUpdatedAt",
          "capacityUpdatedAt",
          "payoutUpdatedAt",
          {
            statisticCapacity: [
              "yesterdayLeads",
              "yesterdayCapacityIndicator",
              "weekLeads",
              "weekCapacityIndicator",
              "monthLeads",
              "monthCapacityIndicator",
              "yesterdayCapacityEfficiency",
              "weekCapacityEfficiency",
              "monthCapacityEfficiency"
            ]
          },
          {
            webmasterPayout: moneyType
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      offerId: {
        type: "Uid!",
        value: offerId
      },
      agentId: {
        type: "Uid!",
        value: agentId
      },
      filters: {
        type: "SubWebmasterCapacityListCriteriaFilters",
        value: filters
      },
      sort: {
        type: "OfferWebmasterCapacitySort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }
  };

  return Generator.query(gqlObject, params);
}