import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { CapacityAnalyseFilters } from "@core/store/types/admin/offers/capacity";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";
import {
  AgentAnalyseFiltersDTO
} from "../../../../../../stores/admin/capacityAndBumps/capacity/dto/AgentAnalyseFiltersDTO";

export default function agentCapacityAnalyse (
  offerId: string,
  agentId: string,
  filters: CapacityAnalyseFilters,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const { isShowCommissions } = useOffers();

  const params = {
    include: isShowRedemption ? ["conversionPayout"] : [],
    exclude: !isShowCommissions ? ["webmasterRate"] : []
  };

  const gqlObject = {
    operation: "capacityGraphByAgent",
  
    fields: [
      {
        offer: [
          "id",
          "intId",
          {
            detail: [
              "name"
            ]
          },
          {
            finance: [
              "currency"
            ]
          }
        ]
      },
      {
        "webmaster: agent": [
          "id",
          "intId",
          "login",
          "subType"
        ]
      },
      "conversionApprove",
      "conversionSpam",
      "countLeadsIntegrated",
      "countLeadsSpamByAdvertiser",
      "countLeadsAccepted",
      "softCapacity",
      "date"
    ],
    
    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      },
      agentId: {
        type: "Uid!",
        value: agentId
      },
      filters: {
        type: "CapacityGraphByAgentCriteriaFilters",
        value: new AgentAnalyseFiltersDTO(filters)
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}