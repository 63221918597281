


































































































import CategoriesCompactList from "@/components/Common/Lists/CategoriesCompactList.vue";
import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
import ConvertedTimeOutput from "@/components/Common/Output/ConvertedTimeOutput.vue";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
import Countries from "@/components/Common/Lists/Countries.vue";
import Card from "@/components/Common/Card.vue";
import { formatCurrency, formatEmptyString } from "@core/filters";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { Offer } from "@core/logic/admin/offer/offer";
import { computed } from "@vue/composition-api";
import { isArray } from "lodash";
import store from "@/store";
interface Props {
  offer: Offer | null;
  statistics: Offer | null;
  hasWebmasterReward: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  offer: {
    key: "offer",
    required: false,
    type: null,
    default: null
  },
  statistics: {
    key: "statistics",
    required: false,
    type: null,
    default: null
  },
  hasWebmasterReward: {
    key: "hasWebmasterReward",
    required: false,
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const role = computed(() => store.getters.role);
  const iconType = computed(() => {
    return {
      "PUBLIC": "globe",
      "PRIVATE": "lock",
      "HIDDEN": "user-lock"
    }[props.offer?.offerType ?? "PUBLIC"];
  });
  function rewardWithCurrency(row: any, key: string): string | undefined {
    return formatCurrency(row?.[key]?.value, row?.[key]?.currency);
  }
  function arrayOrEmpty(items: Offer["categories"] | null): string[] {
    if (isArray(items)) {
      return items;
    }
    return [];
  }
  return {
    formatEmptyString,
    role,
    iconType,
    rewardWithCurrency,
    arrayOrEmpty
  };
};
__sfc_main.components = Object.assign({
  Card,
  Countries,
  LabelWithAnnotation,
  LTooltip,
  ConvertedTimeOutput,
  CategoriesCompactList
}, __sfc_main.components);
export default __sfc_main;
