import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";
import { AdvertisersRatesFilters } from "@core/store/types/admin/users/advertisers/Detail";

export default function advertiserRatesSummaryGenerator (
  advertiserId: string,
  filters: AdvertisersRatesFilters
): ASTGeneratedOutput {
  const money = ["value", "currency"];
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("ratesSummary/reward/value", "ratesSummary/reward/currency");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "advertiserRatesSummary",

    fields: [
      {
        ratesSummary: [
          {
            offer: [
              "intId",
              {
                detail: [
                  "name"
                ]
              }
            ]
          },
          {
            webmaster: [
              "id",
              "subType",
              "login",
              "intId"
            ]
          },
          { totalWriteOff: money },
          { individualWriteOff: money },
          { reward: money }
        ]
      }
    ],

    variables: {
      filters: {
        type: "AdvertiserRateSummaryListCriteriaFilters",
        value: filters
      },
      advertiserId: {
        type: "Uid!",
        value: advertiserId
      }
    }
  };

  return Generator.query(gqlObject, params);
}
