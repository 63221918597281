import { OfferFiltersInput, UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import subAccountOffers from "@core/services/webmaster/list/graphql/subAccountOffers.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class SubAccountOffersService extends Service {
  public static getSubAccountOffersFilters (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialOffers?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialOffersLimit = initialOffers?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: subAccountOffers,
          variables: {
            limit,
            offset,
            filters,
            initialOffers,
            initialOffersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
