




















import { TrafficTypeInterface } from "@core/store/types/common";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
interface Props {
  trafficTypes: TrafficTypeInterface[] | null;
}
const __sfc_main = {};
__sfc_main.props = {
  trafficTypes: {
    key: "trafficTypes",
    required: false,
    type: [Array, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const hasTrafficTypes = computed(() => props.trafficTypes?.length);
  return {
    hasTrafficTypes
  };
};
export default __sfc_main;
