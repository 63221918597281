







import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";
import { withDefaults, defineProps } from "@vue/runtime-dom";
import { computed, h } from "@vue/composition-api";
interface Props {
  group: string;
  data: any;
}
const __sfc_main = {};
__sfc_main.props = {
  group: {
    key: "group",
    required: false,
    type: String,
    default: CommissionsGroupsEnum.SUB_ACCOUNT
  },
  data: {
    key: "data",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const componentToGroup = computed(() => {
    switch (props.group) {
      case CommissionsGroupsEnum.OFFER:
        return {
          component: () => import("@/components/Common/Output/OfferOutput.vue"),
          data: {
            offer: props.data
          }
        };
      case CommissionsGroupsEnum.SUB_ACCOUNT:
        return {
          component: {
            render: () => h("span", props.data.intId),
            props: ["data"]
          },
          data: {
            data: props.data
          }
        };
      default:
        return {
          component: {
            render: () => h("span", props.data),
            props: ["data"]
          },
          data: {
            data: props.data
          }
        };
    }
  });
  return {
    componentToGroup
  };
};
export default __sfc_main;
