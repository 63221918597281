import availableWebmastersQuery from "@core/services/admin/common/offer/graphql/availableWebmasters.graphql";
import Service from "@core/services/common/Service";
import { PartialOfferWebmastersAvailableFilters } from "@core/store/types/admin/offer/WebmastersAvailable";
import { availableWebmastersMapper } from "@core/services/admin/common/offer/availableWebmastersMapper";

export class OfferEditWebmastersAvailableService extends Service {
  public static async getAvailableWebmasters (
    limit: number,
    offset: number,
    filters: PartialOfferWebmastersAvailableFilters,
    id: string
  ) {
    try {
      const { data: { offers: { items: [{ availableWebmasters }] } } } = await this.api.get("", {
        params: {
          query: availableWebmastersQuery,
          variables: {
            limit,
            offset,
            filters,
            id: { id }
          }
        }
      });
      return availableWebmastersMapper(availableWebmasters);
    } catch (e) {
      throw e;
    }
  }
}
