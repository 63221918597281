import Service from "@core/services/common/Service";
import {
  CorrectionHistoryState, CreateFinanceExpenseItemInput, CreateFinanceExpenseItemResponse,
  PaginationCorrection
} from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionHistoryState";
import cancelFinanceTransactionCorrection from "@core/services/admin/finances/financesHistory/graphql/cancelFinanceTransactionCorrection.graphql";
import createExpenseItem from "@core/services/admin/finances/financesHistory/graphql/createExpenseItem.graphql";
import deleteExpenseItem from "@core/services/admin/finances/financesHistory/graphql/deleteExpenseItem.graphql";
import expenseItemsAdmin from "@core/services/admin/finances/financesHistory/graphql/expenseItems.graphql";
import expenseItems from "@core/services/common/lists/graphql/expenseItems.graphql";
import { AxiosPromise } from "axios";
import FinanceTransactionsGenerator
  from "@core/services/admin/finances/financesHistory/graphql/financeTransactions.generator";
import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import storeInstance from "../../../../../../store";

export class FinancesCorrectionService extends Service {
  public static async getCorrectionHistory (
    limit: number,
    offset: number,
    { datepicker, ...filters }: CorrectionHistoryState["filters"]
  ): Promise<PaginationCorrection> {
    try {
      const { data: { financeTransactions } } = await this.api.get("", {
        params: FinanceTransactionsGenerator(limit, offset, { ...datepicker, ...filters })
      });
      return financeTransactions;
    } catch (e) {
      throw e;
    }
  }

  public static async cancelCorrection (id: string): Promise<AxiosPromise> {
    try {
      const { data: { cancelFinanceTransactionCorrection: data } } = await this.api.get("", {
        params: {
          query: cancelFinanceTransactionCorrection,
          variables: { id }
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static getExpenseItems (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialExpense?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialExpenseLimit = initialExpense?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: storeInstance.state.auth.role === "admin" ? expenseItemsAdmin : expenseItems,
          variables: {
            limit,
            offset,
            filters,
            initialExpense,
            initialExpenseLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async createExpenseItem (input: CreateFinanceExpenseItemInput): Promise<CreateFinanceExpenseItemResponse> {
    try {
      const { data: { createExpenseItem: data } } = await this.api.get("", {
        params: {
          query: createExpenseItem,
          variables: { input }
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async deleteExpenseItem (id: string): Promise<CreateFinanceExpenseItemResponse> {
    try {
      const { data: { createExpenseItem: data } } = await this.api.get("", {
        params: {
          query: deleteExpenseItem,
          variables: { id }
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
