import { defineStore } from "pinia";
import { datepicker } from "@core/helpers/datepicker";
import { StatisticGroupsEnum, StatisticOtherGroupsEnum } from "@core/store/types/admin/statistic/enums/StatisticEnums";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { useVerticals } from "@/stores/common/auth/verticalsStore";
import {
  StatisticData,
  StatisticExcludedFilters,
  StatisticItems,
  StatisticNewCards
} from "@core/store/types/common/statistic/common/statistic";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import { set } from "@vue/composition-api";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";
import { pagination } from "@core/helpers/pagination";
import moment from "moment/moment";
import { currencyAvailableToSort } from "@core/flowMethods";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";

function sortFieldGroupsStatistic (items?: StatisticItems[], ...fields: ["country", "region", "category"]) {
  fields.forEach(field => {
    items?.forEach((item: StatisticItems) => {
      const sortField = item[field];

      // Добавляет id для чекбоксов офферов
      if (item.offer) {
        set(item, "id", item.offer.id);
      }

      if (sortField?.length && Array.isArray(sortField)) {
        set(item, `${ field }Sort`, sortField[0]);
      }
    });
  });
}

function splitCompares (today: any, yesterday: any) {
  return {
    new: currencyAvailableToSort(today),
    old: currencyAvailableToSort(yesterday)
  };
}

export const useStatistic = defineStore("admin/statistic", {
  state: () => ({
    data: null as Nullable<StatisticData>,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "weeks" }),
      registeredDatepicker: {
        registeredDateStart: null,
        registeredDateEnd: null
      },
      groups: [StatisticGroupsEnum.OFFER, null],
      currency: CurrencyEnum.USD,
      withBlockedWebmaster: false,
      offerId: null,
      countryId: null,
      withdrawType: null,
      advertiserId: null,
      webmasterId: null,
      managerId: null,
      flowId: null,
      bunchId: null,
      agentId: null,
      utmSource: null,
      utmTerm: null,
      utmContent: null,
      utmCampaign: null,
      utmMedium: null,
      externalWebmasterId: null,
      regionId: null,
      isInHouse: null,
      isLaunched: null,
      isDomesticWebmaster: null,
      tierId: null,
      isPartnerNetwork: null,
      categoryId: null,
      webmasterLabel: null,
      globalSource: null,
      vertical: null
    } as NullableRecord<BaseFiltersInput>,
    excludedFilters: {
      excludedOfferId: null,
      excludedWebmasterId: null,
      excludedCountryId: null,
      excludedAdvertiserId: null,
      excludedExternalWebmasterId: null,
      excludedManagerId: null,
      excludedBunchId: null,
      excludedFlowId: null,
      excludedWebmasterLabel: null,
      excludedRegionId: null,
      excludedWithdrawType: null,
      excludedIsPartnerNetwork: null,
      excludedAgentId: null,
      excludedGlobalSource: null,
      excludedUtmSource: null,
      excludedUtmTerm: null,
      excludedUtmContent: null,
      excludedUtmCampaign: null,
      excludedUtmMedium: null,
      excludedCategoryId: null,
      excludedTierId: null
    } as NullableRecord<StatisticExcludedFilters>,
    sort: null as Nullable<string>,
    order: null as Nullable<string>,
    payoutAnalyze: false as boolean,

    cards: {
      leads: { new: null, old: null },
      cashFlow: { new: null, old: null },
      debit: { new: null, old: null },
      prepaid: { new: null, old: null },
      commission: { new: null, old: null },
      approve: { new: null, old: null }
    } as StatisticNewCards
  }),

  getters: {
    groups: ({ data }) => data?.extras?.group,
    total: ({ data }) => data?.aggregates?.total,
    average: ({ data }) => data?.aggregates?.average,
    enabledStatistic: () => {
      const store = usePermissions();
      return store.hasPermissions(["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN", "STATISTICS.GROUP_BY.WEBMASTER"]);
    },
    isNotGroupedWebmaster: () => {
      const store = usePermissions();
      return store.hasPermissions("STATISTICS.GROUP_BY.WEBMASTER");
    },
    hasVisibleApprove: () => {
      const store = usePermissions();
      return store.hasPermissions(["STATISTICS.SHOW.REDEMPTION"]);
    }
  },

  actions: {
    async GET_STATISTIC ({ filters: otherFilters, excludedFilters, groups }: { filters: BaseFiltersInput, excludedFilters: BaseFiltersInput, groups: Nullable<string[]> }) {
      const verticalsStore = useVerticals();
      const data = await StatisticsService.getAdminStatistic({ ...otherFilters, vertical: verticalsStore.currentVertical || verticalsStore.preferredVertical }, excludedFilters, groups);
      sortFieldGroupsStatistic(data.items, "country", "region", "category");
      this.data = data;
    },

    async GET_STATISTICS () {
      const verticalsStore = useVerticals();
      const { limit, offset } = pagination(100);

      const { groups, datepicker, registeredDatepicker, vertical, ...otherFilters } = this.filters;
      const [withdrawType, subGoalType ] = otherFilters.withdrawType?.split("-") || [null, null];
      const preparedExcludedFilters = prepareExcludedFilters(this.excludedFilters);
      const [excludedWithdrawType, excludedSubGoalType ] = preparedExcludedFilters.withdrawType?.split("-") || [null, null];
      const excludedFilters = { ...preparedExcludedFilters, withdrawType: excludedWithdrawType, subGoalType: excludedSubGoalType };
      const filters = { ...datepicker, ...registeredDatepicker, ...otherFilters, withdrawType, subGoalType, vertical: vertical || verticalsStore.currentVertical };

      // @ts-ignore
      const data = await StatisticsService.getAdminNewStatistic(limit, offset, filters, excludedFilters, this.sort?.toUpperCase(), this.order, groups.filter(Boolean), this.hasVisibleApprove, this.payoutAnalyze);
      sortFieldGroupsStatistic(data.items, "country", "region", "category");
      this.data = data;
    },

    async GET_DEBIT_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminDebit(now.toDate(), verticalsStore.currentVertical!),
        StatisticsService.getAdminDebit(now.subtract(1, "day").toDate(), verticalsStore.currentVertical!)
      ]);
      this.cards.debit = splitCompares(today, yesterday);
    },

    async GET_PREPAID_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminPrepaid(now.toDate(), verticalsStore.currentVertical!),
        StatisticsService.getAdminPrepaid(now.subtract(1, "day").toDate(), verticalsStore.currentVertical!)
      ]);
      this.cards.prepaid = splitCompares(today, yesterday);
    },
    
    async GET_COMMISSION_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminCommission(
          now.clone().startOf("day").toDate(),
          now.clone().toDate(),
          verticalsStore.currentVertical!
        ),
        StatisticsService.getAdminCommission(
          now.clone().startOf("day").subtract(1, "day").toDate(),
          now.clone().subtract(1, "day").toDate(),
          verticalsStore.currentVertical!
        )
      ]);
      this.cards.commission = splitCompares(today, yesterday);
    },

    async GET_APPROVE_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminApproves(
          now.clone().startOf("day").toDate(),
          now.clone().toDate(),
          verticalsStore.currentVertical!
        ),
        StatisticsService.getAdminApproves(
          now.clone().startOf("day").subtract(1, "day").toDate(),
          now.clone().subtract(1, "day").toDate(),
          verticalsStore.currentVertical!
        )
      ]);
      this.cards.approve = splitCompares(today, yesterday);
    },

    async GET_LEADS_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminLeadsCompare({
          dateStart: now.clone().startOf("day").toDate(),
          dateEnd: now.clone().toDate(),
          vertical: verticalsStore.currentVertical
        },
        [StatisticOtherGroupsEnum.CURRENCY]),
        StatisticsService.getAdminLeadsCompare({
          dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
          dateEnd: now.clone().subtract(1, "day").toDate(),
          vertical: verticalsStore.currentVertical
        },
        [StatisticOtherGroupsEnum.CURRENCY])
      ]);
      this.cards.leads = splitCompares(today, yesterday);
    },

    async GET_CASHFLOW_COMPARE () {
      const verticalsStore = useVerticals();
      const now = moment();
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminCashFlowCompare({
          dateStart: now.clone().startOf("day").toDate(),
          dateEnd: now.clone().toDate(),
          vertical: verticalsStore.currentVertical
        },
        [StatisticOtherGroupsEnum.CURRENCY]),
        StatisticsService.getAdminCashFlowCompare({
          dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
          dateEnd: now.clone().subtract(1, "day").toDate(),
          vertical: verticalsStore.currentVertical
        },
        [StatisticOtherGroupsEnum.CURRENCY])
      ]);
      this.cards.cashFlow = splitCompares(today, yesterday);
    }
  }
});