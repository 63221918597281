import webmastersOffer from "@core/services/admin/offer/lists/graphql/webmastersOffer.graphql";
import webmastersOfferWithIndividualRate from "@core/services/admin/offer/lists/graphql/webmastersOfferWithIndividualRate.graphql";
import Service from "@core/services/common/Service";
import { PartialWebmastersListState } from "@core/store/types/admin/offer/lists/OfferEditWebmastersList";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";
import { AxiosPromise } from "axios";

export class OfferEditWebmastersListServices extends Service {
  public static getOfferWebmasters (
    filters: PartialWebmastersListState["filters"],
    excludedFilters: PartialWebmastersListState["filters"],
    limit: number,
    offset: number,
    id: string,
    initialWebmasters: string[]
  ): AxiosPromise {
    const initialLimit = initialWebmasters?.length || 0;
    const hasInitial = !!initialWebmasters?.length;
    limit = Math.floor(initialLimit / limit + 1) * limit - initialLimit;
    const { isShowCommissions } = useOffers();
    try {
      return this.api.get("", {
        params: {
          query: isShowCommissions ? webmastersOfferWithIndividualRate : webmastersOffer,
          variables: {
            limit,
            offset,
            id,
            filters,
            excludedFilters,
            initialWebmasters: { id: initialWebmasters },
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
