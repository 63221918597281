





























import BlockBox from "@/components/Common/BlockBox.vue";
import { defineProps, withDefaults, defineEmits } from "@vue/runtime-dom";
import { trafficType } from "@core/store/types/common/TrafficTypesState";
import { computed } from "@vue/composition-api";
import vuexStore from "@/store";
interface Props {
  title: string;
  value: any;
  separate: boolean;
  titleClass: string;
}
const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: true,
    type: String
  },
  value: {
    key: "value",
    required: false,
    type: null,
    default: () => []
  },
  separate: {
    key: "separate",
    required: false,
    type: Boolean,
    default: false
  },
  titleClass: {
    key: "titleClass",
    required: false,
    type: String,
    default: undefined
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const vuexWait = vuexStore.getters["wait/getWait"];
  const isLoading = vuexWait("trafficTypes/GET_TRAFFIC_TYPES");
  const trafficTypes = computed(() => vuexStore.state.trafficTypes.trafficTypes);
  vuexStore.dispatch("trafficTypes/GET_TRAFFIC_TYPES");
  function changeTrafficType(type: trafficType) {
    const newTrafficTypes = isUserTrafficType(type) ? props.value.filter((id: string) => id !== type.id) : [...(props.value || []), type.id];
    emit("input", newTrafficTypes);
  }
  function isUserTrafficType(type: trafficType): boolean {
    return props.value ? props.value.includes(type.id) : false;
  }
  function updateTrafficTypes() {
    emit("update");
  }
  return {
    isLoading,
    trafficTypes,
    changeTrafficType,
    isUserTrafficType,
    updateTrafficTypes
  };
};
__sfc_main.components = Object.assign({
  BlockBox
}, __sfc_main.components);
export default __sfc_main;
