import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import editCapacityRequestQuery from "@core/services/admin/offers/graphql/editCapacityRequest.graphql";
import deleteCapacityRequestQuery from "@core/services/admin/offers/graphql/deleteCapacityRequest.graphql";
import approveCapacityRequestQuery from "@core/services/admin/offers/graphql/approveCapacityRequest.graphql";
import rejectCapacityRequestQuery from "@core/services/admin/offers/graphql/rejectCapacityRequest.graphql";
import {
  DeleteCapacityRequestResponse,
  ApproveCapacityRequestInput,
  WebmasterCapacityRequest,
  EdinCapacityRequestInput,
  RejectCapacityRequestInput, CreateCapacityRequestInput
} from "@core/store/types/admin/offers/capacity";
import CreateCapacityRequestGenerator from "@core/services/admin/offers/graphql/createCapacityRequest.generator";

export class CapacityRequestService extends Service {
  public static async createCapacityRequest (
    input: CreateCapacityRequestInput
  ): Promise<WebmasterCapacityRequest[]> {
    try {
      const { data: { createCapacityRequest } } = await this.api.post("", CreateCapacityRequestGenerator(input));
      return createCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async editCapacityRequest (
    input: EdinCapacityRequestInput,
    id: string
  ): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { editCapacityRequest } } = await this.api.post("", {
        query: editCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
      return editCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deleteCapacityRequest (
    id: string
  ): AxiosPromise<DeleteCapacityRequestResponse> {
    try {
      return this.api.post("", {
        query: deleteCapacityRequestQuery,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async approveCapacityRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { approveCapacityRequest } } = await this.api.post("", {
        query: approveCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
      return approveCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async rejectCapacityRequest (
    input: RejectCapacityRequestInput,
    id: string
  ): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { rejectCapacityRequest } } = await this.api.post("", {
        query: rejectCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
      return rejectCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
}
