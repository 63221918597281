import { CallCenterOfferModal } from "@core/store/types/common/offer/offer";
import CallCentersService from "@core/logic/common/callCenters/callCenters";
import { defineStore } from "pinia";

export const useCallCentersOfferModal = defineStore("callCentersOfferModal", {
  state: (): CallCenterOfferModal => ({
    isModalActive: false,
    callCenters: null
  }),

  actions: {
    async GET_CALLCENTERS ({ filters, timeZone }: { filters: { offers: string | null }, timeZone: string }) {
      const { data: { callCenters } } = await CallCentersService.getCallCenters(100, 0, filters, timeZone);
      this.callCenters = callCenters;
    }
  }
});
