<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :hide-null="hideNull"
        :value="value"
        :get-data-vuex="getUsers"
        :placeholder="placeholder"
        :multiple="multiple"
        :seed="seed"
        class="white"
        field="login"
        @[event]="$emit(event, $event)">
        <template #text="option">
            <UserOutput :user="option.option"></UserOutput>
        </template>
        <template #selected-option="option">
            <UserOutput :user="option.option"></UserOutput>
        </template>
    </Component>
</template>

<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import { mapGetters } from "vuex";

  export default {
    name: "AdvertisersSelect",
    mixins: [UsersSelect],

    components: { UserOutput },

    props: {
      routeParam: {
        type: String,
        default: "advertiserId"
      },
      namespaceModule: {
        type: String,
        default: "advertisersFilters"
      },
      vertical: {
        type: Boolean,
        default: false
      },
      verticalByPermissions: {
        type: Boolean,
        default: false
      },
      hideNull: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters("verticals", ["allVerticals", "gamblingOnly", "nutraOnly"]),

      currentVertical () {
        return this.$store.state.verticals.currentVertical;
      },

      seed () {
        return this.filterOptions?.systemVertical ?? (this.vertical ? this.currentVertical : "");
      },

      _verticalByPermissions () {
        if (this.allVerticals || this.nutraOnly && this.gamblingOnly) {
          return null;
        } else if (this.gamblingOnly) {
          return VerticalsEnum.GAMBLING;
        } else if (this.nutraOnly) {
          return VerticalsEnum.NUTRA;
        } else {
          return null;
        }
      }
    },

    methods: {
      async getUsers (label = "", isLoading = false) {
        const { filterOptions, vertical, value, routeParam, namespaceModule, currentVertical, verticalByPermissions, _verticalByPermissions } = this;
        await this.getUserFilters(
          namespaceModule, this.list, this.$route.query[routeParam] ?? value,
          this.routeParam, label, isLoading, {
            ...filterOptions,
            systemVertical: filterOptions?.systemVertical ?? (verticalByPermissions || vertical
              ? vertical ? currentVertical : _verticalByPermissions
              : null)
          }
        );
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>