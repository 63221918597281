import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  CapacityRequestInput,
  CapacityRequestState, EdinBookingRequestInput,
  UpdateSubsectionRequestDTO
} from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import {
  APPROVE_BOOKING_REQUEST,
  CREATE_BOOKING_REQUEST,
  DELETE_BOOKING_REQUEST,
  EDIT_BOOKING_REQUEST,
  REJECT_BOOKING_REQUEST,
  UPDATE_BOOKING_REQUEST,
  UPDATE_SUBSECTION_REQUEST
} from "@core/store/action-constants";
import {
  SET_BOOKING_REQUEST,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { BookingRequestService } from "@core/services/admin/offers/BookingRequestService";

class BookingRequest implements Module<CapacityRequestState, RootState> {
  public namespaced: boolean;
  public state: CapacityRequestState;
  
  public getters: GetterTree<CapacityRequestState, RootState> = {
    getData: (state) => state.data
  }
  
  public mutations: MutationTree<CapacityRequestState> = {
    [SET_BOOKING_REQUEST] (state: CapacityRequestState, payload): void {
      state.data = payload;
    },
    
    [UPDATE_BOOKING_REQUEST] (state: CapacityRequestState, payload): void {
      state.data = { ...state.data, ...payload };
    }
  }
  
  public actions: ActionTree<CapacityRequestState, RootState> = {
    [UPDATE_BOOKING_REQUEST] ({ commit }, payload): void {
      commit(UPDATE_BOOKING_REQUEST, payload);
    },
    
    async [CREATE_BOOKING_REQUEST] ({ state: { data }, commit, dispatch }, { moduleNamespace, ...payload }): Promise<void> {
      const { newCapacityValue } = data;
      const createBookingCapacityRequest = await BookingRequestService.createBookingCapacityRequest({
        ...payload,
        newCapacityValue
      });
      
      commit(UPDATE_BOOKING_REQUEST, createBookingCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, { moduleNamespace, ...payload });
    },
    
    async [EDIT_BOOKING_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { newCapacityValue, creatorComment, id } = data;
      const editBookingCapacityRequest = await BookingRequestService.editBookingCapacityRequest(
        {
          newCapacityValue,
          creatorComment
        } as EdinBookingRequestInput,
        id as string
      );
      
      commit(UPDATE_BOOKING_REQUEST, editBookingCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [DELETE_BOOKING_REQUEST] ({ state: { data: { id } }, commit, dispatch }, params): Promise<void> {
      await BookingRequestService.deleteBookingCapacityRequest(id as string);
      
      commit(SET_BOOKING_REQUEST, { data: null });
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [APPROVE_BOOKING_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { newCapacityValue, id } = data;
      const approveBookingCapacityRequest = await BookingRequestService.approveBookingCapacityRequest(
        { newCapacityValue } as EdinBookingRequestInput,
        id as string
      );
      
      commit(UPDATE_BOOKING_REQUEST, approveBookingCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [REJECT_BOOKING_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const rejectBookingCapacityRequest = await BookingRequestService.rejectBookingCapacityRequest(data.id as string);
      
      commit(UPDATE_BOOKING_REQUEST, rejectBookingCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    [UPDATE_SUBSECTION_REQUEST] ({ dispatch, getters }, { moduleNamespace, ...payload }): void {
      dispatch(`${ moduleNamespace }/${ UPDATE_SUBSECTION_REQUEST }`, {
        data: getters.getData,
        offerId: payload.offerId,
        webmasterId: payload.webmasterId,
        moduleName: "bookingCapacityRequest"
      } as UpdateSubsectionRequestDTO, { root: true });
    }
  }
  
  private readonly initState: () => CapacityRequestState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): CapacityRequestState => {
      return {
        data: {
          newCapacityValue: null,
          status: null,
          createdAt: null,
          updatedAt: null
        },
        id: null,
        input: {}
      };
    };
    
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    
    this.state = this.initState();
    
    return this;
  }
}

export const bookingRequest = new BookingRequest();