<script>
  import extendComponent from "@core/helpers/extendComponent";
  import { default as BaseSelect } from "./Select.vue";
  import _cloneDeep from "lodash/cloneDeep";

  const Select = _cloneDeep(BaseSelect);
  const ExtendedSelect = extendComponent(Select, {
    created () {
      if (this.value && this.value.length !== 0) {
        this.update();
      }
    },

    watch: {
      seed: {
        deep: true,
        handler () {
          if (!this.value) {
            this.offset = 0;
            this.items = [];
          } else {
            this.offset = 0;
            this.items = [];

            this.update();
          }
        }
      }
    }
  });

  export default {
    name: "LazySelect",

    extends: ExtendedSelect,

    methods: {
      async open () {
        this.isActive = true;

        if (!this.items.length) {
          await this.update();
        }

        if (this.hasNextPage) {
          await this.$nextTick();

          const load = this.$refs.load?.$el || this.$refs.load;
          this.observer.observe(load);
        }
      }
    }
  };
</script>