









import CommissionsTable from "@/components/Webmaster/Commissions/table/CommissionsTable.vue";
import CommissionsFilters from "@/components/Webmaster/Commissions/CommissionsFilters.vue";
import SectionWrapper from "@/components/Common/SectionWrapper.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  SectionWrapper,
  CommissionsFilters,
  CommissionsTable
}, __sfc_main.components);
export default __sfc_main;
