import Service from "@core/services/common/Service";
import createBookingCapacityRequestQuery from "@core/services/admin/offers/graphql/booking/createBookingCapacityRequest.graphql";
import editBookingCapacityRequestQuery from "@core/services/admin/offers/graphql/booking/editBookingCapacityRequest.graphql";
import deleteBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/deleteBookingCapacityRequest.graphql";
import approveBookingCapacityRequestQuery from "@core/services/admin/offers/graphql/booking/approveBookingCapacityRequest.graphql";
import rejectBookingCapacityRequestQuery from "@core/services/admin/offers/graphql/booking/rejectBookingCapacityRequest.graphql";
import {
  DeleteBookingCapacityRequest,
  WebmasterCapacityRequest,
  CreateBookingRequestInput,
  EdinBookingRequestInput
} from "@core/store/types/admin/offers/capacity";

export class BookingRequestService extends Service {
  public static async createBookingCapacityRequest (
    input: CreateBookingRequestInput
  ): Promise<WebmasterCapacityRequest[]> {
    try {
      const { data: { createBookingCapacityRequest } } = await this.api.post("", {
        query: createBookingCapacityRequestQuery,
        variables: {
          input
        }
      });
      return createBookingCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async editBookingCapacityRequest (
    input: EdinBookingRequestInput,
    id: string
  ): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { editBookingCapacityRequest } } = await this.api.post("", {
        query: editBookingCapacityRequestQuery,
        variables: {
          id,
          input
        }
      });
      return editBookingCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deleteBookingCapacityRequest (
    id: string
  ): Promise<DeleteBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: deleteBookingCapacityRequest,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async approveBookingCapacityRequest (
    input: EdinBookingRequestInput,
    id: string
  ): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { approveBookingCapacityRequest } } = await this.api.post("", {
        query: approveBookingCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
      return approveBookingCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async rejectBookingCapacityRequest (id: string): Promise<WebmasterCapacityRequest> {
    try {
      const { data: { rejectBookingCapacityRequest } } = await this.api.post("", {
        query: rejectBookingCapacityRequestQuery,
        variables: {
          id
        }
      });
      return rejectBookingCapacityRequest;
    }
    catch (e) {
      throw e;
    }
  }
}
