import { AdvertisersCapacityItem } from "@core/store/types/admin/offers/capacity";
import _uniqueId from "lodash/uniqueId";
import { CapacityGroupsEnum } from "@core/store/types/admin/capacity/enums/CapacityGroupsEnum";
import { PaginationOutput } from "@core/store/types/PaginationOutput";

export const capacityMapper = ({ items, ...data }: Required<PaginationOutput<AdvertisersCapacityItem[]>>, prefix: CapacityGroupsEnum) => ({
  ...data,
  items: items.map((item: AdvertisersCapacityItem) => processData(item, prefix))
});

const processData = ({ statisticCapacity, statisticCapacityByAdvertiser, statisticCapacityByOffer, requiredApprove, ...item }: AdvertisersCapacityItem, prefix: string): AdvertisersCapacityItem => ({
  ...item,
  ...statisticCapacity,
  ...statisticCapacityByAdvertiser,
  ...statisticCapacityByOffer,
  ...requiredApprove,
  uniqueKey: _uniqueId(`${ prefix }_`)
});
