import { IntegrationServices } from "@core/logic/admin/integrations/integrations";
import { CRMService } from "@core/services/admin/crm/CRMService";
import { IntegrationService } from "@core/services/admin/integrations/IntegrationsService";
import {
  GET_UNINTEGRATION_OFFERS,
  SET_INTEGRATION_FILTERS,
  CREATE_INTEGRATION,
  GET_CRM_OPTIONS,
  EDIT_INTEGRATION
} from "@core/store/action-constants";
import { CRMList } from "@core/store/modules/admin/lists/CRMList";
import {
  SET_INTEGRATION_OPTIONS,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_PARAMS,
  SET_UNINTEGRATION_OFFERS,
  UPDATE_INTEGRATION_FILTERS,
  UPDATE_INTEGRATION_OPTIONS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IntegrationsEditState } from "@core/store/types/admin/integrations";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";

const initialState = (): IntegrationsEditState => {
  return {
    filters: {
      offer: null,
      crm: null
    },
    params: {
      integrationId: null,
      isLaunched: false
    },
    options: [],
    isModalActive: false
  };
};

const state: () => IntegrationsEditState = initialState;

const mutations: MutationTree<IntegrationsEditState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_PARAMS] (state, params: IntegrationsEditState["params"]) {
    state.params = { ...state.params, ...params };
  },
  
  [UPDATE_INTEGRATION_FILTERS] (state, filter: IntegrationsEditState["filters"]) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_INTEGRATION_OPTIONS] (state, payload: IntegrationsEditState["options"]) {
    state.options = [ ...state.options, ...payload ];
  },

  [SET_INTEGRATION_OPTIONS] (state, payload: IntegrationsEditState["options"]) {
    state.options = payload;
  },
  
  [UPDATE_MODAL_ACTIVE] (state, value: boolean) {
    state.isModalActive = value;
  },
  
  [SET_INTEGRATION_FILTERS] (state, item) {
    const offer = item.offer.id;
    const crm = item.crm.alias;
    state.filters = { offer, crm };
  }
};

const actions: ActionTree<IntegrationsEditState, RootState> = {
  async [CREATE_INTEGRATION] ({ state, commit }) {
    try {
      const { filters: { offer, crm }, params: { isLaunched }, options } = state;
      const createIntegration = await IntegrationService
        .createIntegration({ offer, isLaunched, crm, options });
      commit("admin/integrations/LOCAL_ADD", { items: createIntegration, target: "integrations" }, { root: true });
    } catch (e) {
      throw e;
    }
  },
  
  async [GET_UNINTEGRATION_OFFERS] ({ commit }) {
    try {
      const { data } = await IntegrationServices.getUnallocatedOffers();
      commit(SET_UNINTEGRATION_OFFERS, data);
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [GET_CRM_OPTIONS] ({ commit }, alias) {
    try {
      const { data: { crm: { options } } } = await CRMService.getCRMOptions(
        alias
      );
      commit(SET_INTEGRATION_OPTIONS, options);
    } catch (e) {
      throw e;
    }
  },

  async [EDIT_INTEGRATION] ({ state: { options, params: { integrationId } }, commit }) {
    // @ts-ignore
    const editedCRM = await IntegrationService.editIntegration({ options }, integrationId);
    commit("admin/integrations/LOCAL_UPDATE", { items: editedCRM, target: "integrations" }, { root: true });
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [UPDATE_INTEGRATION_FILTERS] ({ commit }, filter) {
    commit(UPDATE_INTEGRATION_FILTERS, filter);
  },

  [UPDATE_INTEGRATION_OPTIONS] ({ commit }, option) {
    commit(UPDATE_INTEGRATION_OPTIONS, option);
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("offersFilters/SET_EMPTY");
    commit("CRMList/SET_EMPTY");
  }
};

const getters: GetterTree<IntegrationsEditState, RootState> = {
  getOptions ({ filters: { crm } }) {
    if (crm === "lg" || crm === "lv") {
      return ["webmasterId", "domain", "goodId"];
    } else return ["webmasterId", "domain", "projectId", "apiKey", "orderId", "productName"];
  },
  
  isCrmSelected ({ filters: { crm } }) {
    return !(crm === null || crm === "");
  }
};

export const integrationEdit: Module<IntegrationsEditState, RootState> = {
  namespaced: true,
  modules: {
    offersFilters: new OfferFilters(),
    CRMList
  },
  state,
  mutations,
  actions,
  getters
};
